<template>
    <div class="rules-wrapper">
        <div
            class="document-list-wrapper"
            id="document-list"
        >
            <div>
                <h3 class="rules-title">Document's contents</h3>
                <ol
                    class="documents-list"
                >
                    <li><a href="#section_1" smooth-scroll>General Provisions</a></li>

                    <li><a href="#section_2">Using of Service</a></li>

                    <li><a href="#section_3">Prices and exchange rates</a></li>

                    <li><a href="#section_4">Processing Orders in Violation of the Exchange rules</a></li>

                    <li><a href="#section_5">Prohibited Jurisdictions</a></li>

                    <li><a href="#section_6">Prohibited Uses</a></li>

                    <li><a href="#section_7">AML Procedure</a></li>

                    <li><a href="#section_8">Personal Data</a></li>

                    <li><a href="#section_9">Accuracy, Completeness and Timeliness of Information</a></li>

                    <li><a href="#section_10">User Comments, Feedback and Other Submissions</a></li>

                    <li><a href="#section_11">Disclaimer Of Warranties</a></li>

                    <li><a href="#section_12">Limitation Of Liability</a></li>

                    <li><a href="#section_13">Entire Agreement</a></li>

                    <li><a href="#section_14">Tax</a></li>

                    <li><a href="#section_15">Limitation Of Liability</a></li>

                    <li><a href="#section_16">Risk</a></li>

                    <li><a href="#section_17">Intellectual Property Rights</a></li>

                    <li><a href="#section_18">Restricted Activities</a></li>

                    <li><a href="#section_19">Disputes Resolution</a></li>
                </ol>
            </div>
        </div>
        <div>
            <TermsSection />
        </div>
        <button 
            class="top-button"
            @click="scrollToTop"
        >
            <div class="arrow-left"></div>
            <div class="arrow-right"></div>
            <div class="arrow-middle"></div>
        </button>
    </div>
</template>

<script>
    import TermsSection from './sections/TermsSection.vue';
    // import arrowIcon from '@/assets/-icon.svg';

    export default {
        components: {
            TermsSection,
        },
        methods: {
            scrollToTop() {
                const documentContent = document.getElementById('document-list');
                documentContent.scrollIntoView({ behavior: 'smooth' });

                // window.scrollTo({
                //     top: 0,
                //     behavior: 'smooth',
                // });
            },
        },
    };
</script>
<style>
    .rules-wrapper {
        display: flex;
        flex-direction: column;
        align-self: baseline;
        position: relative;
    }
    .document-list-wrapper {
        align-self: baseline;
        padding: 30px;
        background-color: rgba(255, 255, 255, 0.05);
    }

    .rules-title {
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: 600;
        color: #fff;
    }

    .documents-list li {
        margin-bottom: 8px;
        position: relative;
        padding-left: 35px;
        margin-bottom: 15px;
        opacity: 1;
    }

    .documents-list li::before {
        content: counter(list-item);
        color: #fff;
        position: absolute;
        font-size: 1.25em;
        font-weight: 600;
        text-align: center;
        width: 24px;
        left: 0;
    }

    .documents-list li a {
        color: #fff;
        border-bottom: 1px dashed #fff;
        line-height: 16px;
        font-size: 16px;
    }

    .top-button {
        border-radius: 50%;
        background-color: transparent;
        border: none;
        height: 45px;
        width: 20px;
        opacity: 0.7;
        position: fixed;
        bottom: 74px;
        right: 25px;
        cursor: pointer;
    }
    
    .top-arrow-img {
        position: relative;
    }

    .arrow-left {
        width: 5px;
        height: 15px;
        background-color: #fff;
        position: absolute;
        box-sizing: border-box;
        transform: rotate(45deg);
        left: 4px;
        top: 6px;
    }

    .arrow-right {
        width: 5px;
        height: 15px;
        background-color: #fff;
        position: absolute;
        box-sizing: border-box;
        transform: rotate(-45deg);
        left: 11px;
        top: 6px;
    }

    .arrow-middle {
        width: 5px;
        height: 25px;
        background-color: #fff;
        position: absolute;
        box-sizing: border-box;
        left: 7.5px;
        top: 10px;
    }

    @media (max-width: 1024px) {
        .rules-wrapper {
            width: 85%;
            margin: 0 auto;
        }
    }

    @media (max-width: 767px) {
        .documents-list li {
            margin-bottom: 5px;
        }
    }

    @media (max-width: 425px) {
        .documents-list li {
            margin-bottom: 10px;
        }
    }
</style>