<template>
	<div class="preloader">
		<div class="preloader__screen preloader__screen_top"></div>
		<div class="preloader__screen preloader__screen_bottom"></div>
		<div class="preloader__logo">
			<img src="@/assets/LOGOO.png" alt="" class="spining"/>
		</div>
	</div>
</template>

<script>
	export default {};
</script>

<style>
	.preloader {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 9990;
		overflow: hidden;
	}
	.preloader__screen {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 9991;
		width: 100%;
		height: 50%;
		transition: transform 1s;
		transform-origin: 50% 50%;
		will-change: transform;
	}
	.preloader__screen_top {
		background: #2a3587;
	}
	.preloader__screen_bottom {
		top: 50%;
		background: #2a3587;
	}
	.preloader__logo {
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 9992;
		width: 130px;
		transition: 0.5s;
		transform: translate(-50%, -50%);
		transform-origin: 50% 50%;
		will-change: transform, opacity;
	}
	.preloader__logo .preloader-logo__part_red {
		animation: glitchLogoRed 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both
			infinite;
	}
	.preloader__logo .preloader-logo__part_blue {
		animation: glitchLogoBlue 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both
			infinite;
	}
	.preloader_hide .preloader__screen_top {
		transform: translateX(-100%);
	}
	.preloader_hide .preloader__screen_bottom {
		transform: translateX(100%);
	}
	.preloader_hide .preloader__logo {
		opacity: 0;
		transform: translate(-50%, -50%) scale(1.2);
	}
	/* stylelint-disable */
	@keyframes glitchLogoRed {
		5% {
			transform: translate(4%, 2%);
		}
		7% {
			transform: translate(0, 0);
		}
		49% {
			transform: translate(0, 0);
		}
		50% {
			transform: translate(4%, 2%);
		}
		52% {
			transform: translate(0, 0);
		}
		100% {
			transform: translate(0, 0);
		}
	}
	@keyframes glitchLogoBlue {
		5% {
			transform: translate(-5%, -2%);
		}
		7% {
			transform: translate(0, 0);
		}
		49% {
			transform: translate(0, 0);
		}
		50% {
			transform: translate(-5%, -2%);
		}
		52% {
			transform: translate(0, 0);
		}
		100% {
			transform: translate(0, 0);
		}
	}
	@keyframes spin {
		0% {
			transform: rotate(360deg);
		}
		100% {
			transform: rotate(0deg);
		}
	}
	.spining {
		animation: spin 2s linear infinite;
	}
</style>