<template>
	<div class="main_wait_status mh300">
		<div class="wait_status">
			<!-- <button @click="checkStatus">Check manual</button> -->
			<img src="@/assets/loader.gif" alt="" width="60px">
			<h3 class="">{{ $t("exchange_step_3_titale") }}</h3>

			<div v-for="(k, v) in answear_stage" :key="k">
				<h4><span class="weight900">{{ k }} - {{ v }}</span></h4>
			</div>

			<!-- <p class="">
				Status change time : 13.07.2022, 14:57 <br />
				The page is updated every 60 seconds.
			</p> -->

		</div>
	</div>
</template>

<script>
import store from "@/store";
// import ws from "../../ws.js"
// import axios from '../../axios'
export default {
	name: "step-three",
	data() {
		return {
			chatSocket: null,
			answear_stage: null
		};
	},
	methods: {
		funcccc(){
			let self = this;
			let url = "ws://" + "127.0.0.1:8000/" + "ws/test/"
			this.chatSocket = new WebSocket(url)
			this.chatSocket.onopen = () => this.chatSocket.send(JSON.stringify({
				'next': '1', "id_request": self.idRequest
			}));

			this.chatSocket.onmessage = function (e) {
				const data = JSON.parse(e.data);
				self.answear_stage = data
				if (data.current_stage == 2000) {
					self.stepExchange = 4;
					self.closeConnect()
				}
			};
			this.chatSocket.onclose = function (e) {
				const data = JSON.parse(e.data);
				console.error('Chat socket closed unexpectedly', data);
				this.closeConnect()
			};
			},
			closeConnect() {
				this.chatSocket.close()
			},
	},
	computed: {
		stepExchange: {
			set: (payload) => store.commit("setStepExchange", payload),
			get: () => store.getters.getStepExchange,
		},
		start_ex: {
			set: (payload) => store.commit("setManualCancel", payload),
			get: () => store.getters.getManualCancel,
		},
		idRequest: {
			set: (payload) => store.commit("setIdRequest", payload),
			get: () => store.getters.getIdRequest,
		},
		dateCreate: {
			set: (payload) => store.commit("setDateCreate", payload),
			get: () => store.getters.getDateCreate,

		},
	},
};
</script>

<style>

</style>
