<template>
	<div class="main_page">
		<div class="central">
			<div class="StartExchange">
				<div class="blockExchange">
					<div class="header_Exchange">
						<button style="cursor: pointer" @click="manualCloseExchange">
							&#215;
						</button>

						<h3>{{ $t("answer_uah_exchnge_success") }}</h3>

						<ul>
							<li>
								<i class="fas fa-pencil-alt"></i>
								<h5>{{ $t("answer_uah_data_input") }}</h5>
							</li>

							<li>
								<i class="fas fa-credit-card"></i>
								{{ $t("answer_uah_payment") }}
							</li>

							<li class="activeLi">
								<i class="fas fa-check-double"></i>
								{{ $t("answer_uah_completion") }}
							</li>
						</ul>
					</div>
					<div class="main_completion mh300">
						<img src="@/assets/meme/waiting.gif" alt="" class="meme">
						<h2>{{ $t("answer_uah_status") }}</h2>
						<h2 v-if="id_request">ID: {{id_request}}</h2>
						<ul class="instruction_uah_ex">
							<li><b>{{ $t("answer_uah_guide") }}</b></li>
							<li>1. {{ $t("answer_uah_guide_1") }}</li>
							<li>2. {{ $t("answer_uah_guide_2") }}</li>
							<li>{{ $t("answer_uah_guide_3") }} <a href="https://t.me/exmoney_manager">https://t.me/exmoney_manager</a></li>
						</ul>
						
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import store from "@/store";
export default {
	name: "AnswearUAH",
	data() {
		return {
			id_request:null
		};
	},
	methods: {
		manualCloseExchange() {
			this.$router.push({ name: "main", path: "/" })
			this.start_ex = false;
			this.stepExchange = 1
		},
	},
	computed: {
		stepExchange: {
			set: (payload) => store.commit("setStepExchange", payload),
			get: () => store.getters.getStepExchange,
		},
	},
	mounted() {
		this.stepExchange = 3
		this.id_request = this.$route.params.id_request
	},
};
</script>

<style>
.meme{
	width: 50%;
    margin-top: 30px;
    margin-bottom: 20px;
}
.main_completion {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	width: 100%;
	margin: 0 auto;
	color: #fff;
	text-align: center;
}

.main_completion h2 {
	font-size: 24px;
	font-weight: 700;
	line-height: 36px;
}

.main_completion h3 {
	font-size: 18px;
	font-weight: 500;
	line-height: 28px;
}
.instruction_uah_ex{
	text-align: left;
    padding: 10px 90px;
    font-size: 18px;
    line-height: 24px;
	font-weight: bold;
}
.instruction_uah_ex a{
	color: #5be7c4 !important;
}
</style>
