<template>
	<div class="main_auth">
		<div class="main_form">
			<div class="content_auth" v-if="change_form">
				<div class="header_auth">
					<button @click="closeFormChangePwd">
						<img src="@/assets/times.png" alt="" /></button><br />
					<h1 style="text-align:center;">{{ $t("reset_password_title") }}</h1>
					
				</div>
				<div class="body_auth">
					<input type="password" :placeholder="$t('reset_placeholder_password')" v-model="pass1" />
					
					<input type="password" :placeholder="$t('reset_placeholder_confirm_password')" v-model="pass2" />
					
				</div>
				<br />
				<div class="footer_auth">
					<button class="active_button" @click="confirmPassword">
						{{ $t("reset_button_change") }}
					</button>
				</div>
			</div>
			<div class="content_auth" v-if="change_200">
				<div class="header_auth">
					<button @click="closeFormChangePwd">
						<img src="@/assets/times.png" alt="" /></button><br />
					<h1 style="text-align: center;margin: 40px 0px;">{{ $t("reset_success_message") }}</h1>
					
					
				</div>
				
				<br />
				<div class="footer_auth">
					<button class="active_button" @click="goLogin">
						{{ $t("reset_button_go_home") }}
					</button>
				</div>
			</div>
			<div class="content_auth" v-if="change_400">
				<div class="header_auth">
					<button @click="closeFormChangePwd">
						<img src="@/assets/times.png" alt="" /></button><br />
					<h1 style="text-align: center;margin: 30px 0px;">{{ $t("reset_password_reset_error") }}</h1>
					<p v-if="err_messages" style="text-align: center;margin: 20px 0px;color:red;">{{ $t("reset_password_reset_error_message") }} {{ err_messages }}</p>
					<div v-if="err_pwd">
						<div v-for="(i,inx ) in err_pwd" :key="inx">
							<p style="text-align: left;margin: 10px 0px; color:red !important;">{{ i }}</p>
						</div>
					</div>
				</div>
				<br />
				<div class="footer_auth">
					<button class="active_button" @click="tryReset">
						{{ $t("reset_button_try_again") }}
					</button>
				</div>
			</div>
		</div>
	</div>
	<!--
	<div class="reset_password">
        <div class="form_reset_pwd">
			<input type="text" name="" id="" v-model="pass1">
			<input type="text" name="" id="" v-model="pass2">
			<button @click="confirmPassword()">Send</button>
		</div>
	</div>
    -->

</template>

<script>
import axios from '../axios'

export default {
	data() {
		return {
			pass1: '',
			pass2: '',
			change_form: true,
			change_200: false,
			change_400: false,
			err_messages:'',
			err_pwd:[],
		}
	},
	methods: {
		closeFormChangePwd(){
			this.$router.push('/');
		},
		goLogin(){
			this.$router.push({ name: "auth", path: "/auth" }).catch(() => { });
		},
		tryReset(){
			this.change_400 = false
			this.change_form = true
		},
		confirmPassword() {
			let self = this
			try {
				axios.post('v1/password_reset/confirm/', {
					headers: {
						'Content-Type': 'application/json'
					},
					password: this.pass2,
					token: this.$route.params.ref
				})	.then(function (resp) {
						console.log(resp.data.status);
						self.change_form = false
						self.change_200 = true
					})
					.catch(function (error) {
						self.err_messages = error.data.detail 
						self.err_pwd = error.data.password
						self.change_form = false
						self.change_400 = true
					});

			} catch (e) {
				console.error(e.data);
			}
		}
	},
};
</script>

<style>
.forgot-password-link {
	text-align: end;
	margin-top: 10px;
	color: #3498db;
	text-decoration: underline;
	cursor: pointer;
}

.forgot-password-link:hover {
	color: #2980b9;
}

.main_auth {
	position: fixed !important;
	z-index: 10;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.3);
	top: 0;
	left: 0;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.main_form {
	width: 30%;
	background-color: #fff;
	background-image: url("../assets/bg_pair.png");
	background-repeat: repeat-y;
	background-position: center center;
	background-size: 100%;
}

.content_auth {
	width: 90%;
	height: 100%;
	padding: 5%;
	background: rgba(0, 0, 0, 0.15);
	backdrop-filter: blur(150px);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.header_auth {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	flex-wrap: wrap;
}

.header_auth h1,
.header_auth p {
	width: 100%;
	margin: 0;
	color: #fff;
}

.header_auth span {
	color: #2a3587;
	cursor: pointer;
}

.header_auth span:hover {
	text-decoration: underline;
}

.header_auth h1 {
	font-style: normal;
	font-weight: 900;
	font-size: 32px;
}

.header_auth button {
	font-size: 32px;
	color: #fff;
	border: none;
	background-color: inherit;
	cursor: pointer;
	line-height: 20px;
}

.body_auth {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 50%;
}

.body_auth input {
	margin-top: 25px;
}

.body_auth .check_box {
	display: flex;
	flex-direction: row;
	margin-top: 30px;
	margin-bottom: 25px;
}

.body_auth input {
	width: 100%;
	height: 40px;
}

.body_auth .check_box h4 {
	margin: 0;
	color: #fff;
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 28px;
	cursor: pointer;
}

.body_auth h6 {
	margin: 0;
}

.body_auth input {
	height: 40px;
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	color: #fff !important;
	border: none;
	background-color: transparent;
	border-radius: 0.990366px;
	border-bottom: solid 2px #fff;
}

.body_auth input::placeholder {
	color: rgb(145, 145, 145);
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
}

.body_auth input:focus {
	outline: none;
}

.body_auth .check_box input[type="checkbox"] {
	height: 24px;
	width: 24px;
	border: solid 1px #fff !important;
	background-color: inherit !important;
	cursor: pointer;
	margin: 0 20px 0px 0;
}

.footer_auth {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}

.footer_auth button {
	width: 170px;
	height: 40px;
	border: solid 1px #fff;
	background-color: inherit;
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	color: #fff;
	cursor: pointer;
}

.active_button {
	color: #2a3587 !important;
	background-color: #fff !important;
}

.active_button_send {
	margin-top: 20px;
	color: #2a3587 !important;
	background-color: #fff !important;
}

.error {
	padding: 0px 0px 0px 1px;
	color: #999;
	max-height: 0;
	transition: 0.28s;
	color: red;
}

.error-recover {
	color: red;
}

@media (min-width: 1200px) and (max-width: 1440px) {
	.main_form {
		width: 40%;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.main_form {
		width: 50%;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.main_form {
		width: 60%;
	}
}

@media (min-width: 480px) and (max-width: 767px) {
	.main_form {
		width: 80%;
	}

	.body_auth input {
		font-size: 18px;
	}

	.body_auth input::placeholder {
		font-size: 18px;
	}

	.body_auth .check_box {
		margin-top: 20px;
		margin-bottom: 20px;
	}

	.body_auth .check_box input[type="checkbox"] {
		height: 16px;
		width: 16px;
		border: solid 1px #fff !important;
		background-color: inherit !important;
		cursor: pointer;
		margin: 0 10px 0px 0;
	}

	.body_auth .check_box h4 {
		font-size: 16px;
		line-height: 16px;
		cursor: pointer;
	}

	.footer_auth button {
		width: 100px;
		height: 36px;
		font-size: 18px;
	}

	/* Registration */
	.header_auth h1 {
		font-size: 24px;
	}

	.header_auth p {
		font-size: 14px;
		margin-top: 5px;
	}
}

@media (max-width: 479px) {
	.main_form {
		width: 80%;
	}

	.body_auth input {
		font-size: 14px;
		height: 20px;
	}

	.body_auth input::placeholder {
		font-size: 14px;
	}

	.body_auth .check_box {
		margin-top: 20px;
		margin-bottom: 20px;
	}

	.body_auth .check_box input[type="checkbox"] {
		height: 16px;
		width: 16px;
		border: solid 1px #fff !important;
		background-color: inherit !important;
		cursor: pointer;
		margin: 0 10px 0px 0;
	}

	.body_auth .check_box h4 {
		font-size: 16px;
		line-height: 16px;
		cursor: pointer;
	}

	.footer_auth button {
		width: 120px;
		height: 36px;
		font-size: 18px;
	}

	/* Registration */
	.header_auth h1 {
		font-size: 18px;
	}

	.header_auth p {
		font-size: 14px;
		margin-top: 5px;
	}

	.error {
		padding: 0px 0px 0px 1px;
		max-height: 0;
		transition: 0.28s;
		color: red;
		font-size: 14px;
	}
}</style>