<template>
	<div class="main_page">
		<div class="central">
			<div class="StartExchange">
				<div class="blockExchange">
					<div class="header_Exchange">
						<button style="cursor: pointer" @click="manualCloseExchange">
							&#215;
						</button>

						<h3 v-if="ws_payment">{{ $t("form_header_Exchange") }} {{ ws_payment.from_pair }}({{ ws_payment.provider_from }}) {{ $t("form_header_to") }} {{
							ws_payment.to_pair }}({{ ws_payment.provider_to }})</h3>

						<ul>
							<li>
								<i class="fas fa-pencil-alt"></i>
								<h5>{{ $t("form_header_DateInput") }}</h5>
							</li>

							<li class="activeLi">
								<i class="fas fa-credit-card"></i>
								{{ $t("form_header_Payment") }}
							</li>

							<li>
								<i class="fas fa-check-double"></i>
								{{ $t("form_header_Completion") }}
							</li>
						</ul>
					</div>
					<div class="enteringValues mh300 pay_time" v-if="!preLoadingWS && ws_status.stage === '1' && (ws_status.message !== 'Pending' && ws_status.message !== 'Successful')">
						<h3 class="weight900 mt3 mb1">{{ $t("form_rule_1_title") }}</h3>
						<!-- ВСе текста должны генерироваться на беке -->
						<p class="mb1">
							{{ $t("form_rule_1_body_1") }}<strong style="font-weight:700">{{ ws_payment.from_pair }}({{ ws_payment.provider_from }})</strong>{{ $t("form_rule_1_body_2") }}
						</p>
						<hr class="lineW mb3" />
						<div class="id_date_main" v-if="ws_payment">
							<div class="left">
								<h4>ID: {{ ws_id_request }}</h4>
								<h4>{{ $t("form_header_Date") }}: {{ ws_payment.date_create | moment }}</h4>
							</div>
							<div class="right">
								<h4>{{ $t("form_header_RemainingTime") }}</h4>
								<h2>{{ remaining_time | secondsToHms }}</h2>
							</div>
						</div>
						<div class="block_payment_main" v-if="ws_payment">

							<div class="block_payment_main-left">
								<div class="block_payment_main_data">
									<!-- <textarea v-model="ws_payment.amount_from" class="block_payment_address"></textarea> -->
									<h5><strong>{{ ws_payment.from_pair }}</strong> {{ ws_payment.amount_from }}</h5>
									<button @click="copyURL(ws_payment.amount_from)">{{ $t("form_header_Copy") }}</button>
								</div>
								<div class="block_payment_main_data"  v-if="ws_payment.type_pay_client == 1 && ws_payment.memo_deposite">
									<!-- <textarea v-model="ws_payment.amount_from" class="block_payment_address"></textarea> -->
									<h5><strong>MEMO:</strong> {{ ws_payment.memo_deposite }}</h5>
									<button @click="copyURL(ws_payment.memo_deposite)">{{ $t("form_header_Copy") }}</button>
								</div>
								<div class="block_payment_main_data" v-if="ws_payment.type_pay_client == 1">
									<textarea v-model="ws_payment.payment_addres " class="block_payment_address"></textarea>
									<button @click="copyURL(ws_payment.payment_addres)">{{ $t("form_header_Copy") }}</button>									
								</div>
								
								
								<div class="block_payment_main_data" v-if="ws_payment.type_pay_client == 2">
									<!-- <textarea v-model="ws_payment.payment_addres " class="block_payment_address"></textarea>							 -->
									<a :href="ws_payment.payment_addres" target="_blanc">{{ ws_payment.payment_addres }}</a>
									<button @click="copyURL(ws_payment.payment_addres)">{{ $t("form_header_Copy") }}</button>
								</div>
							</div>
							<div class="block_payment_main-right">
								<qrcode-vue class="" :value="ws_payment.payment_addres" :size="size" level="H" />
							</div>
						</div>				
						<hr class="lineW mb3" />
						<h3 class="weight900 mb1">{{ $t("form_rule_2_title") }}</h3>
						<!-- ВСе текста должны генерироваться на беке -->
						<p class="mb2">
							{{ $t("form_rule_2_body") }}
						</p>
						<h3 class="weight900 mb1">{{ $t("form_rule_3_title") }}</h3>
						<!-- ВСе текста должны генерироваться на беке -->
						<p class="mb2">
							{{ $t("form_rule_3_body") }}
						</p>
						<h3 class="weight900 mb1">{{ $t("form_rule_4_title") }}</h3>
						<!-- ВСе текста должны генерироваться на беке -->
						<p class="mb2">
							{{ $t("form_rule_4_body") }}
						</p>
						<p class="mb2" style="font-weight:700">
							{{ $t("form_rule_5_body") }}
						</p>
					</div>
					<div class="enteringValues pay_time" v-if="(!preLoadingWS && ws_status.stage != '1') || (!preLoadingWS && ws_status.stage == '1' && (ws_status.message == 'Pending' || ws_status.message == 'Successful'))">
						<hr class="lineW mt3" />
						<div class="" v-if="ws_payment">
							<div class="id_date">
								<h4>ID: {{ ws_id_request }}</h4>
								<h4>{{ $t("form_header_Date") }}: {{ ws_payment.date_create | moment }}</h4>
							</div>
						</div>
						<hr class="lineW mb3" />
					</div>
					<div class="main_wait_status mh300" v-if="(!preLoadingWS && ws_status.stage != '1') || (!preLoadingWS && ws_status.stage == '1' && (ws_status.message == 'Pending' || ws_status.message == 'Successful'))">
						<div class="wait_status" v-if="ws_status">
							<h3 class="">{{ $t("form_body_STATUS") }}</h3>
							<!-- <button @click="checkStatus">Check manual</button> -->
							<!-- <img src="@/assets/loader.gif" alt="" width="60px"> -->
							<svg class="ip ip_small" viewBox="0 0 256 128" width="64px" height="32px"
								xmlns="http://www.w3.org/2000/svg">
								<defs>
									<linearGradient id="grad1" x1="0" y1="0" x2="1" y2="0">
										<stop offset="0%" stop-color="#16a2d3" />
										<stop offset="33%" stop-color="#3b60e1" />
										<stop offset="67%" stop-color="#5be7c4" />
										<stop offset="100%" stop-color="#2a3587" />
									</linearGradient>
									<linearGradient id="grad2" x1="1" y1="0" x2="0" y2="0">
										<stop offset="0%" stop-color="#2a3587" />
										<stop offset="33%" stop-color="#3b60e1" />
										<stop offset="67%" stop-color="#5be7c4" />
										<stop offset="100%" stop-color="#16a2d3" />
									</linearGradient>
								</defs>
								<g fill="none" stroke-linecap="round" stroke-width="16">
									<g class="ip__track" stroke="#ddd">
										<path d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56" />
										<path d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64" />
									</g>
									<g stroke-dasharray="180 656">
										<path class="ip__worm1" stroke="url(#grad1)" stroke-dashoffset="0"
											d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56" />
										<path class="ip__worm2" stroke="url(#grad2)" stroke-dashoffset="358"
											d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64" />
									</g>
								</g>
							</svg>

							<div class="block_status">
								<div class="block_status_item">
									<h5 class="brd_right brd_bottom">{{ $t("form_body_STEP") }}</h5>
									<h5 class="brd_bottom">{{ ws_status.stage }} / 7</h5>
								</div>
								<div class="block_status_item">
									<h5 class="brd_right">{{ $t("form_body_MESSAGE") }}</h5>
									<h5 v-if="ws_status.stage==='0'"> {{ $t("txt_stage_0") }}</h5>
									<h5 v-if="ws_status.stage==='1'"> {{ $t("txt_stage_1") }}</h5>
									<h5 v-if="ws_status.stage==='2'"> {{ $t("txt_stage_2") }}</h5>
									<h5 v-if="ws_status.stage==='3'"> {{ $t("txt_stage_3") }}</h5>
									<h5 v-if="ws_status.stage==='4'"> {{ $t("txt_stage_4") }}</h5>
									<h5 v-if="ws_status.stage==='5'"> {{ $t("txt_stage_5") }}</h5>
									<h5 v-if="ws_status.stage==='6'"> {{ $t("txt_stage_6") }}</h5>
									<!-- <h5>{{ ws_status.message }}</h5> -->
								</div>
							</div>
							<!-- {{ ws_status.message }} -->
<!--							<p v-if="ws_status.message">Тестове повідомлення: {{ws_status.message}}</p> <br>-->
<!--							<textarea v-model="ws_status.parse_resp" class="text_parse_resp"></textarea>-->

							<p class="">
								{{ $t("form_footer_updated") }}
							</p>

						</div>
					</div>

					<div v-if="preLoadingWS" class="preloaderWS mh600">
						<h2>Формується запит, зачекайте!</h2>
						<svg class="ip" viewBox="0 0 256 128" width="256px" height="128px"
							xmlns="http://www.w3.org/2000/svg">
							<defs>
								<linearGradient id="grad1" x1="0" y1="0" x2="1" y2="0">
									<stop offset="0%" stop-color="#16a2d3" />
									<stop offset="33%" stop-color="#3b60e1" />
									<stop offset="67%" stop-color="#5be7c4" />
									<stop offset="100%" stop-color="#2a3587" />
								</linearGradient>
								<linearGradient id="grad2" x1="1" y1="0" x2="0" y2="0">
									<stop offset="0%" stop-color="#2a3587" />
									<stop offset="33%" stop-color="#3b60e1" />
									<stop offset="67%" stop-color="#5be7c4" />
									<stop offset="100%" stop-color="#16a2d3" />
								</linearGradient>
							</defs>
							<g fill="none" stroke-linecap="round" stroke-width="16">
								<g class="ip__track" stroke="#ddd">
									<path d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56" />
									<path d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64" />
								</g>
								<g stroke-dasharray="180 656">
									<path class="ip__worm1" stroke="url(#grad1)" stroke-dashoffset="0"
										d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56" />
									<path class="ip__worm2" stroke="url(#grad2)" stroke-dashoffset="358"
										d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64" />
								</g>
							</g>
						</svg>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import store from "@/store";
import QrcodeVue from 'qrcode.vue'
// import axios from '../../axios'
// import ws from "../../ws.js"
import moment from 'moment'

export default {
	name: "step-two",
	components: {
		QrcodeVue,
	},
	data() {
		return {
			value: '00...00',
			size: 125,
			type_pay_client: 99, // 99 NOT, 0 CRYPTO, 1 FIAT
			error_getAddress: true,
			error_getAddress_message: '',
			// Info by pair
			selectedPAIR: {
				full_pair: null,
				from_pair: null,
				to_pair: null,
			},
			idRequest: null,
			dateCreate: null,
			// ___________________________________
			ws_id_request: null,
			ws_payment: null,
			ws_status: null,
			remaining_time: null,
			preLoadingWS: false
		};
	},
	created: function () {
		// idRequest будет всегда, так как он в УРЛ
		// this.idRequest = this.$route.params.id_request
		// С помощью его делаем запрос на бек, что бы достать pair/dateCreate/АДРЕСС
		// _________________________
		// START CONNECTION WEBSOCKET
		this.startConnectionProd()
	},

	mounted() {
		// this.getAddress()
		this.stepExchange = 2
	},
	filters: {
		moment: function (date) {
			// 10:12:32 12.12.2023
			return moment(date).format('HH:MM:SS DD.MM.YYYY');
			// YYYY-MM-DD H:M:S
		},
		truncate: function (addrs) {
			if (window.screen.width <= 479) {
				let reqdString = ''
				let len = addrs.length
				let first = addrs.slice(0, 8)
				let second = addrs.slice(len - 8, len)
				reqdString = first + "..." + second
				return reqdString;
			} else {
				return addrs
			}

		},
		secondsToHms: function (d) {
			// Info by: https://stackoverflow.com/questions/37096367/how-to-convert-seconds-to-minutes-and-hours-in-javascript
			d = Number(d);
			var h = Math.floor(d / 3600);
			var m = Math.floor(d % 3600 / 60);
			var s = Math.floor(d % 3600 % 60);

			var hDisplay = h > 0 ? h + (h == 1 ? ":" : "") : "";
			var mDisplay = m > 0 ? m + (m == 1 ? ":" : ":") : "";
			// var sDisplay = s > 0 ? s + (s == 1 ? ":" : "") : "";
			var sDisplay = s < 10 ? "0"+s : s;
			return hDisplay + mDisplay + sDisplay;
		}
	},
	methods: {
		closeConnect() {
			this.chatSocket.close()
		},
		startConnectionProd() {
			let self = this;
			const WS = process.env.VUE_APP_WS
			let url_ws = WS + "order-status/" + self.$route.params.id_request
			// ws://127.0.0.1:8000/ws/
			// let url_ws = "ws://127.0.0.1:8000/ws/order-status/" + self.$route.params.id_request

			this.chatSocket = new WebSocket(url_ws)
			// Тут бля включив собы и пздц, коли отримаеш даны - отключаеш
			this.preLoadingWS = true
			this.chatSocket.onopen = () => this.chatSocket.send(JSON.stringify({
				'commands': 'start'
			}));

			this.chatSocket.onmessage = function (e) {

				const data = JSON.parse(e.data);
				self.error_getAddress = false
				if (data.isEnd === true) {
					self.$router.push(
						{
							name: "step-4",
							path: `/completed/${encodeURIComponent(data.id_request)}`,
							params: {
								'id_request': data.id_request,
							}
						})
					self.preLoadingWS = false
					self.stepExchange = 4;
					self.closeConnect()
				} else if(data.isTimeOut === true){
					self.$router.push(
						{
							name: "time-out",
							path: `/сancelled/${encodeURIComponent(data.id_request)}`,
							params: {
								'id_request': data.id_request,
								'isTimeOut': data.isTimeOut,
							}
						})
					self.preLoadingWS = false
					self.stepExchange = 4;
					self.closeConnect()
				} else {
					self.ws_id_request = data.id_request
					self.ws_payment = data.payment
					self.ws_status = data.status
					self.remaining_time = data.remaining_time
					self.value = data.payment.payment_addres
					// Отключить лоадер
					self.preLoadingWS = false
				}

			};
			this.chatSocket.onclose = () => {
				// можна передати onclose = (e) => {
				// І додатково опрацювати щось в ньому, за потреби.
				// console.log("e>>>>", e);
				this.closeConnect();
			};

		},
		copyURL(value) {
			navigator.clipboard.writeText(value);
		},
		// ________________________________________________________________________________
		nextPaid() {
			this.stepExchange = 3;
		},
		manualCloseExchange() {
			this.start_ex = false;
			this.stepExchange = 1
			this.$router.push(
				{
					name: "main",
					path: "/",
				})
		},
	},
	computed: {
		stepExchange: {
			set: (payload) => store.commit("setStepExchange", payload),
			get: () => store.getters.getStepExchange,
		},
		start_ex: {
			set: (payload) => store.commit("setManualCancel", payload),
			get: () => store.getters.getManualCancel,
		},

	},
};
</script>

<style>
.block_payment_main {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 40px;
}
.block_payment_main_data{
	display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
	margin-bottom: 25px;
}
.block_payment_main_data button {
	cursor: pointer;
	background: #ffffff;
	border: 1px solid #ffffff;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	color: #2a3587;
	padding: 0 20px;
	height: 40px;
	margin: 0 auto;
  }
  
  .block_payment_main_data button:hover {
	background: #2a3587;
	border: 1px solid #ffffff;
	color: #fff;
  }
.block_payment_main-left{
	width: 70%;
	display: flex;
    flex-direction: column;
    justify-content: space-between;

}
.block_payment_main-right{
	width: 30%;
	display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}
.block_payment_main_data h5{
	margin: 0;
    height: auto;
    min-height: 40px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 40px;
    padding: 0 2% 0 2%;
    color: #fff !important;
    border: none;
    background-color: rgba(127, 179, 238, 0.5);
    border-radius: 0.990366px;
    word-wrap: break-word;
	width: 250px;
}
.block_payment_main_data a{
	margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    width: 100%;
    line-height: 20px;
    padding: 5px 5px;
    color: #fff !important;
    border: none;
    background-color: rgba(127, 179, 238, 0.5);
    border-radius: 0.990366px;
	width: 250px;
	overflow-wrap: break-word;
}
.block_payment_address{
	background-size: 30px;
    width: 250px;
    height: 50px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    padding: 10px;
    color: #fff!important;
    border: none;
    background-color: rgba(127,179,238,.5);
    border-radius: 0.990366px;
    resize: none;
}
.text_parse_resp {
	width: 100%;
	height: 400px;
}

/* Preloader */
.preloaderWS {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.preloaderWS h2 {
	color: #fff;
	font-size: 36px;
	line-height: 42px;
	margin-bottom: 40px;
}

.ip {
	width: 23em;
	height: 10em;
}

.ip__track {
	stroke: hsl(var(--hue), 90%, 90%);
	transition: stroke var(--trans-dur);
}

.ip__worm1,
.ip__worm2 {
	animation: worm1 2s linear infinite;
}

.ip__worm2 {
	animation-name: worm2;
}

/* Dark theme */
@media (prefers-color-scheme: dark) {
	:root {
		--bg: hsl(var(--hue), 90%, 5%);
		--fg: hsl(var(--hue), 90%, 95%);
	}

	.ip__track {
		stroke: hsl(var(--hue), 90%, 15%);
	}
}

/* Animation */
@keyframes worm1 {
	from {
		stroke-dashoffset: 0;
	}

	50% {
		animation-timing-function: steps(1);
		stroke-dashoffset: -358;
	}

	50.01% {
		animation-timing-function: linear;
		stroke-dashoffset: 358;
	}

	to {
		stroke-dashoffset: 0;
	}
}

@keyframes worm2 {
	from {
		stroke-dashoffset: 358;
	}

	50% {
		stroke-dashoffset: 0;
	}

	to {
		stroke-dashoffset: -358;
	}
}

.brd_right {
	border-right: solid 1px #fff;
}

.brd_bottom {
	border-bottom: solid 1px #fff;
}

/* Enb Preloader */
.pay_time h3 {}

.pay_time p {
	font-size: 14px;
	line-height: 18px;
}

.btn_step2 {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	width: 100%;
}

.main_wait_status {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 85%;
	margin: 0 auto;
	color: #fff;
}

.block_status {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin: 20px 0;
}

.block_status_item {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 80%;
}

.block_status_item h5 {
	text-align: center;
	width: 50%;
	font-size: 24px;
	line-height: 48px;
	padding: 15px 0;
}

.wait_status {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

.wait_status h3 {
	line-height: 48px;
	font-size: 36px;
	font-weight: 700;
	margin-bottom: 10px;
}

.wait_status p {
	line-height: 24px;
	font-size: 14px;
}

.lineW {
	width: 100%;
	border: solid 1px #fff;
}

.address {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
}

.address_link {
	width: 70%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
}

.amount_from {
	width: 70%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
}

.address_qr {
	width: 30%;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: flex-start;
}

.address_link h5,
.amount_from h5 {
	margin: 0;
	height: auto;
	min-height: 40px;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 40px;
	padding: 0 2% 0 2%;
	color: #fff !important;
	border: none;
	background-color: rgba(127, 179, 238, 0.5);
	border-radius: 0.990366px;
	word-wrap: break-word;
}

.address_link a {
	margin: 0;
	height: 40px;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	width: 100%;
	line-height: 40px;
	padding: 0 2% 0 2%;
	color: #fff !important;
	border: none;
	background-color: rgba(127, 179, 238, 0.5);
	border-radius: 0.990366px;
}

.address_link button,
.amount_from button {
	border: none;
	background-color: transparent;
	color: #0e9cff;
	cursor: pointer;
	width: 20%;
	font-size: 20px;
	padding: 0;
}

.address_link button:hover,
.amount_from button:hover {
	border: none;
	background-color: transparent;
	color: #40afff;
	text-decoration: underline;
}

.qr_code_pay_addres {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.qr_code_pay_addres img {
	width: 30%;
}

/* id_date_main  */
.id_date_main {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 40px;
	height: 60px;
}

.id_date {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	font-weight: 700;
	margin: 20px 0px;
}

.id_date_main .left {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	font-weight: 700;
}

.id_date_main .right {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	font-weight: 700;
	align-items: center;
}

.id_date_main .right h2 {
	font-size: 36px;
	line-height: 48px;
}

@media (min-width: 1200px) and (max-width: 1440px) {
	.blockExchange {
		width: 86%;
		padding: 20px 20px;
	}

	.address_link h5 {
		font-size: 16px;
	}
	.ip {
		width: 18em;
		height: 9em;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.block_payment_main_data h5,.block_payment_main_data a{
		width: 200px;
	}
	.ip {
		width: 18em;
		height: 9em;
	}
	.block_payment_address{
		width: 200px;
		font-size: 16px;
		line-height: 20px;
	}
	.blockExchange {
		width: 86%;
		padding: 20px 20px;
	}

	.id_date {
		font-size: 14px;
	}

	.address {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		align-items: center;
	}

	.address_link {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-bottom: 30px;
	}

	.address_qr {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
	}

	.wait_status {
		margin-top: 0;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.block_status_item h5 {
		text-align: center;
		width: 50%;
		font-size: 22px;
		line-height: 36px;
		padding: 15px 0;
	}
	.block_status_item{
		width: 100%;
	}
	.ip {
		width: 14em;
		height: 7em;
	}
	.blockExchange {
		width: 90% !important;
		padding: 20px 20px;
	}
	.id_date_main .right h2 {
		font-size: 32px;
		line-height: 42px;
	}
	.id_date {
		font-size: 16px;
	}

	.address {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		align-items: center;
	}

	.address_link {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-bottom: 30px;
	}

	.address_qr {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
	}

	.wait_status {
		margin-top: 0 !important;
	}
}

@media (min-width: 480px) and (max-width: 767px) {
	.ip {
		width: 10em;
		height: 5em;
	}
	.block_status_item h5 {
		text-align: center;
		width: 50%;
		font-size: 18px;
		line-height: 26px;
		padding: 15px 0;
	}
	.block_status_item{
		width: 100%;
	}
	.blockExchange {
		width: 92% !important;
		padding: 20px 20px;
	}
	.id_date_main .right h2 {
		font-size: 32px;
		line-height: 42px;
	}
	.id_date {
		font-size: 16px;
	}
	.block_payment_main_data{
		flex-direction: column;
	}
	.block_payment_main_data h5, .block_payment_main_data a{
		width: 100%;
	}
	.block_payment_main-left{
		width: 55%;
	}
	.block_payment_main_data button{
		margin-top: 6px;
	}
	.block_payment_main-right{
		width: 35%;
	}
	.block_payment_address{
		width: 100%;
		font-size: 16px;
		line-height: 18px;
	}
	.address {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		align-items: center;
	}

	.address_link {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-bottom: 30px;
	}

	.address_qr {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
	}

	.wait_status {
		margin-top: 0 !important;
	}

	.id_date {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-bottom: 30px;
		font-weight: 700;
		height: 50px;
		align-items: flex-start;
	}

	.address_link h5 {
		font-size: 16px;
	}
}

@media (max-width: 479px) {
	.ip {
		width: 8em;
		height: 4em;
	}
	.block_status_item h5 {
		text-align: center;
		width: 50%;
		font-size: 16px;
		line-height: 24px;
		padding: 15px 0;
	}
	.block_status_item{
		width: 100%;
	}
	.id_date_main .right h2 {
		font-size: 28px;
		line-height: 36px;
		margin-left: 20px;
	}
	.id_date {
		font-size: 16px;
	}
	.block_payment_main{
		width: 96%;
		margin: 0 auto 20px auto;
	}
	.block_payment_main_data{
		flex-direction: column;
	}
	.block_payment_main_data h5, .block_payment_main_data a{
		width: 100%;
	}
	.block_payment_main-left{
		width: 100%;
	}
	.block_payment_main_data button{
		margin-top: 6px;
	}
	.block_payment_main-right{
		display: none;
	}
	.id_date_main .left h4{
		margin-bottom: 10px;
	}
	.id_date_main {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-bottom: 40px;
		height: auto;
		align-items: flex-start;
	}
	.id_date_main .right{
		flex-direction: row;
	}
	.block_payment_address{
		width: 100%;
		font-size: 16px;
		line-height: 18px;
	}
	.blockExchange {
		width: 100% !important;
		padding: 20px 20px;
	}

	.id_date {
		font-size: 14px;
	}

	.address {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		align-items: center;
	}

	.address_link {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-bottom: 30px;
	}

	.address_qr {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
	}

	.wait_status {
		margin-top: 0 !important;
	}

	.id_date {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-bottom: 30px;
		font-weight: 700;
		height: 50px;
		align-items: flex-start;
	}

	.address_link h5 {
		font-size: 14px;
	}
}</style>
