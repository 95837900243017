<template>
	<div class="main_page">
		<div class="central">
			<!-- <h1>Test server</h1> -->
			<h1 class="big_txt">{{ $t("main_page_header") }}</h1>
			<br />
			<div class="quote">
			</div>

			<div class="Pair" v-if="start_ex === false">
				<div class="PairBlock">
					<ul class="list_crypto" v-if="!isListToLoadingLeft">
						<li>
							<h2 class="type_glasses">{{ $t("main_page_send_section_header") }}</h2>
						</li>
						<li class="search_token">
							<input v-model="searchString" class="base-input" type="text"
								:placeholder="$t('main_page_input_placeholder')">
						</li>
						<li class="item_crypto" :class="{ active__item_crypto: selectedItemSEND == item.from_pair, }"
							v-for="(item, index) in filteredList" :key="index">
							<div class="item_crypto_info"
								@click="selectedSEND(item.from_pair); get_pair_TO(item.from_pair)">
								<div class="item_block_left">
									<!-- DYNAMIC IMG https://stackoverflow.com/questions/40491506/vue-js-dynamic-images-not-working -->
									<!-- clear_from -->
									<img :src="getImgUrl(item.clear_from)" v-bind:alt="item.clear_from" />
									<!-- <img :src="require(`@/assets/currency_icons/png/cash.png`)" alt="" /> -->

									<div class="item_block_left_name">
										<!-- <h5>
											<strong>{{ item.name }}</strong>
										</h5> -->
										<h5>{{ item.from_pair }}</h5>
									</div>
								</div>
								<div class="item_block_right">
									<h5>24h %</h5>
									<!-- <h5 v-if="objWithLastPrice != null && item.from_pair === forFullPair">
										<strong>{{ objWithLastPrice.last_price }} {{ oneMoreForFullPair }}</strong>
									</h5> -->
								</div>
							</div>
							<div class="get_value" v-if="selectedItemSEND == item.from_pair">
								<line-chart :height="height" :library="library" loading="Loading..." empty="No data"
									prefix="" color="#fff" :colors="[
										'#fff',
										'#fff',
										'#fff',
										'#fff',
										'#fff',
										'#fff',
										'#fff',
									]" :min="minP" :curve="false" :max="maxP"
									:data="history.map((h) => [h.date, parseFloat(h.openPrice).toFixed(2),])" />
							</div>
						</li>
					</ul>
					<ul class="list_crypto" v-else>
						<li>
							<h2 class="type_glasses">{{ $t("main_page_send_section_header") }}111</h2>
						</li>
						<li class="item_crypto" :class="{
							active__item_crypto:
								selectedItemRECEIVE == item.to_pair,
						}" v-for="(item, index) in loadingList" :key="index">
							<div class="item_crypto_info"
								@click="selectedRECEIVE(item.to_pair); get_tikers(item.full_pair)">
								<div class="item_block_left">
									<!-- DYNAMIC IMG https://stackoverflow.com/questions/40491506/vue-js-dynamic-images-not-working -->
									<img :src="require(`@/assets/currency_icons/png/cash.png`)" alt="" />
									<div class="loadingio-spinner-ellipsis-6t7ofp9aao8">
										<div class="ldio-ctj710s2mlk">
											<div></div>
											<div></div>
											<div></div>
											<div></div>
											<div></div>
										</div>
									</div>
								</div>
								<div class="item_block_right">
									<div class="loadingio-spinner-ellipsis-6t7ofp9aao8">
										<div class="ldio-ctj710s2mlk">
											<div></div>
											<div></div>
											<div></div>
											<div></div>
											<div></div>
										</div>
									</div>
								</div>
							</div>
						</li>

					</ul>
				</div>

				<div class="PairBlock">
					<ul class="list_crypto" v-if="!isListToLoading">
						<li>
							<h2 class="type_glasses">{{ $t("main_page_receive_section_header") }}</h2>
						</li>
						<li class="search_token">
							<input v-model="searchStringRight" class="base-input" type="text"
								:placeholder="$t('main_page_input_placeholder')">
						</li>
						<li class="item_crypto" :class="{ active__item_crypto: selectedItemRECEIVE == item.to_pair, }"
							v-for="(item, index) in filteredListRight" :key="index">
							<div class="item_crypto_info"
								@click="selectedRECEIVE(item.to_pair); get_tikers(item.full_pair)">
								<div class="item_block_left">
									<!-- DYNAMIC IMG https://stackoverflow.com/questions/40491506/vue-js-dynamic-images-not-working -->
									<!-- <img :src="require(`@/assets/icons-crypto/${item.clear_to}.png`)" alt="" /> -->
									<!-- <img :src="require(`@/assets/currency_icons/png/cash.png`)" alt="" /> -->
									<img :src="getImgUrl2(item.clear_to)" v-bind:alt="item.clear_to" />
									<div class="item_block_left_name">
										<!-- <h5>
											<strong>{{ item.to_pair }}</strong>
										</h5> -->
										<h5>{{ item.to_pair }}</h5>
									</div>
								</div>
								<div class="item_block_right">
									<h5>
										<strong>1 = {{ item.current_price | truncate_price }}</strong>
									</h5>
								</div>
							</div>
						</li>

					</ul>
					<ul class="list_crypto" v-else>
						<li>
							<h2 class="type_glasses">{{ $t("main_page_receive_section_header") }}</h2>
						</li>
						<li class="item_crypto" :class="{
							active__item_crypto:
								selectedItemRECEIVE == item.to_pair,
						}" v-for="(item, index) in loadingList" :key="index">
							<div class="item_crypto_info"
								@click="selectedRECEIVE(item.to_pair); get_tikers(item.full_pair)">
								<div class="item_block_left">
									<!-- DYNAMIC IMG https://stackoverflow.com/questions/40491506/vue-js-dynamic-images-not-working -->
									<img :src="require(`@/assets/currency_icons/png/cash.png`)" alt="" />
									<div class="loadingio-spinner-ellipsis-6t7ofp9aao8">
										<div class="ldio-ctj710s2mlk">
											<div></div>
											<div></div>
											<div></div>
											<div></div>
											<div></div>
										</div>
									</div>
								</div>
								<div class="item_block_right">
									<div class="loadingio-spinner-ellipsis-6t7ofp9aao8">
										<div class="ldio-ctj710s2mlk">
											<div></div>
											<div></div>
											<div></div>
											<div></div>
											<div></div>
										</div>
									</div>
								</div>
							</div>
						</li>

					</ul>

				</div>
			</div>

			<div class="NextStart" v-if="start_ex === false">
				<button @click="startExchange" :disabled="shoeBTNexchange">{{ $t("main_page_modal_kyc_exchange")
					}}</button>
			</div>
			<div class="main-alert-modal" v-if="showKycModal">
				<div class="alert-modal">
					<div class="modal-kyc">
						<!-- <div class="header">
							<span>&times;</span>
						</div> -->
						<div class="body">
							<h2>{{ $t("main_page_modal_kyc_alert") }}</h2>
							<p class="title-explain">{{ $t("main_page_modal_kyc_to_proceed_condition") }}</p>
							<!-- Завершіть проходження KYC (Know Your Customer) -->
							<p>- {{ $t("main_page_modal_kyc_to_proceed_condition_1") }}</p>
							<p>- {{ $t("main_page_modal_kyc_to_proceed_condition_2") }}</p>
							<p>- {{ $t("main_page_modal_kyc_to_proceed_condition_3") }}</p>
						</div>
						<div class="btn-modal">
							<button @click="showKycModal = !showKycModal">{{ $t("main_page_modal_kyc_button_cancel")
								}}</button>
							<!-- Увійти -->
							<button @click="kycOk">{{ $t("main_page_modal_kyc_button_cancel") }}</button>
						</div>
					</div>
				</div>
			</div>
			<div class="main-alert-modal" v-if="showAlertFiatModal">
				<div class="alert-modal">
					<div class="modal-kyc">
						<!-- <div class="header">
							<span>&times;</span>
						</div> -->
						<div class="body">
							<h2>{{ $t("main_page_modal_fiat_alert") }}</h2>
							<p class="title-explain">{{ $t("main_page_modal_fiat_to_proceed_condition") }}</p>
							<!-- / Увійти -->
							<p>- {{ $t("main_page_modal_fiat_to_proceed_condition") }}</p>
							<!-- Завершіть проходження KYC (Know Your Customer) -->
							<p>- {{ $t("main_page_modal_fiat_to_proceed_condition_1") }}</p>
						</div>
						<div class="btn-modal">
							<button @click="showAlertFiatModal = !showAlertFiatModal">{{
								$t("main_page_modal_fiat_button_cancel") }}</button>
							<!-- Увійти -->
							<button @click="goAuth">{{ $t("main_page_modal_fiat_button_login") }}</button>
						</div>
					</div>
				</div>
			</div>
			<!-- showAlertErrorWhenCheckFiat -->
			<div class="main-alert-modal" v-if="showAlertErrorWhenCheckFiat">
				<div class="alert-modal">
					<div class="modal-kyc">
						<!-- <div class="header">
							<span>&times;</span>
						</div> -->
						<div class="body">
							<h2>{{ $t("main_page_modal_error") }}</h2>
							<br>
							<h2>{{ $t("main_page_modal_error_try_later") }}</h2>
						</div>
						<div class="btn-modal">
							<button @click="showAlertErrorWhenCheckFiat = !showAlertErrorWhenCheckFiat">{{
								$t("main_page_modal_error_close") }}</button>
						</div>
					</div>
				</div>
			</div>
			<!-- showPreloaderExchange -->
			<div class="main-alert-modal" v-if="showPreloaderExchange">
				<div class="alert-modal preloaderExchange">
					<svg class="ip" viewBox="0 0 256 128" width="256px" height="128px"
						xmlns="http://www.w3.org/2000/svg">
						<defs>
							<linearGradient id="grad1" x1="0" y1="0" x2="1" y2="0">
								<stop offset="0%" stop-color="#16a2d3" />
								<stop offset="33%" stop-color="#3b60e1" />
								<stop offset="67%" stop-color="#5be7c4" />
								<stop offset="100%" stop-color="#2a3587" />
							</linearGradient>
							<linearGradient id="grad2" x1="1" y1="0" x2="0" y2="0">
								<stop offset="0%" stop-color="#2a3587" />
								<stop offset="33%" stop-color="#3b60e1" />
								<stop offset="67%" stop-color="#5be7c4" />
								<stop offset="100%" stop-color="#16a2d3" />
							</linearGradient>
						</defs>
						<g fill="none" stroke-linecap="round" stroke-width="16">
							<g class="ip__track" stroke="#ddd">
								<path d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56" />
								<path d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64" />
							</g>
							<g stroke-dasharray="180 656">
								<path class="ip__worm1" stroke="url(#grad1)" stroke-dashoffset="0"
									d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56" />
								<path class="ip__worm2" stroke="url(#grad2)" stroke-dashoffset="358"
									d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64" />
							</g>
						</g>
					</svg>
				</div>
			</div>

			<div class="StartExchange" v-if="start_ex === true">
				<div class="blockExchange">
					<div class="header_Exchange">
						<button style="cursor: pointer" @click="manualCloseExchange">
							&#215;
						</button>

						<h3 class="mb2">{{ $t("main_page_modal_startEx_exchange") }} {{ selectFROM }} {{
							$t("main_page_modal_startEx_to") }} {{ selectTO }}</h3>
						<p></p>
						<ul>
							<li :class="{ activeLi: stepExchange === 1 }">
								<i class="fas fa-pencil-alt"></i>
								<h5>
									{{ $t("main_page_modal_startEx_data") }}
								</h5>
							</li>

							<li :class="{ activeLi: stepExchange === 2 }">
								<i class="fas fa-credit-card"></i>
								{{ $t("main_page_modal_startEx_payment") }}
							</li>

							<li :class="{ activeLi: stepExchange === 3 }">
								<i class="fas fa-check"></i>
								{{ $t("main_page_modal_startEx_status") }}
							</li>

							<li :class="{ activeLi: stepExchange === 4 }">
								<i class="fas fa-check-double"></i>
								{{ $t("main_page_modal_startEx_completion") }}
							</li>
						</ul>
					</div>
					<stepOne v-if="stepExchange === 1" />
					<stepTwo v-if="stepExchange === 2" />
					<stepThree v-if="stepExchange === 3" />
					<stepFour v-if="stepExchange === 4" />
				</div>
			</div>
			<div class="StartExchange" v-if="start_ex === true">
				<div class="blockExchange">
					<div class="header_Exchange">
						<button style="cursor: pointer" @click="manualCloseExchange">
							&#215;
						</button>

						<h3 class="mb2">
							{{ $t("main_page_modal_startEx_true_exchange") }} {{ selectFROM }} {{
								$t("main_page_modal_startEx_true_to") }} {{ selectTO }}
						</h3>
						<p></p>
						<ul>
							<li :class="{ activeLi: stepExchange === 1 }">
								<i class="fas fa-pencil-alt"></i>
								<h5>{{ $t("main_page_modal_startEx_true_data") }}</h5>
							</li>

							<li :class="{ activeLi: stepExchange === 2 }">
								<i class="fas fa-credit-card"></i>
								{{ $t("main_page_modal_startEx_true_payment") }}
							</li>
						</ul>
					</div>
					<FormUAH v-if="stepExchange === 1" />
					<AnswearUAH v-if="stepExchange === 2" />
				</div>
			</div>


		</div>
		<!-- <div class="rights_bgc_img"></div> -->
	</div>
</template>

<script>
import store from "@/store";
import axios from '../axios'
// @ is an alias to /src
// import icon_BTC from "@/assets/i_Crypto/BTC.png";
// import icon_ETH from "@/assets/i_Crypto/ETH.png";
// import icon_LTC from "@/assets/i_Crypto/LTC.png";
// import icon_PYPL from "@/assets/i_Crypto/PYPL.png";

import json from "@/response.json";
// exchange steps
import stepOne from "./exchange/stepOne.vue";
import stepTwo from "./exchange/stepTwo.vue";
import stepThree from "./exchange/stepThree.vue";
import stepFour from "./exchange/stepFour.vue";
import FormUAH from "./ExchangeUAH/FormUAH.vue";
import AnswearUAH from "./ExchangeUAH/AnswearUAH.vue";
import router from "@/router";

export default {
	name: "Home",
	components: { stepOne, stepTwo, stepThree, stepFour, FormUAH, AnswearUAH },
	data() {
		return {
			showPreloaderExchange: false,
			searchString: '',
			searchStringRight: '',
			showKycModal: false,
			showAlertFiatModal: false,
			showAlertErrorWhenCheckFiat: false,
			isKyc: false,
			ws_data: {},
			maJson: json.data,
			// Charts
			height: "150px",
			library: {
				scales: {
					yAxes: [
						{
							ticks: { fontColor: "#fff", fontSize: 10 },
							scaleLabel: { fontColor: "#fff", fontSize: 10 },
						},
					],
					xAxes: [
						{
							ticks: { fontColor: "#fff", fontSize: 10 },
						},
					],
				},
			},
			history: [
				{
					openPrice: "17000",
					time: 165564220000,
					circulatingSupply: "19071412.0000000000000000",
					date: "2022-06-19T14:00:00.000Z",
				},
				{
					openPrice: "18000",
					// time: 1655650800000,
					// circulatingSupply: "19071475.0000000000000000",
					date: "2022-06-19T15:00:00.000Z",
				},
				{
					openPrice: "17500",
					// time: 1655654400000,
					// circulatingSupply: "19071475.0000000000000000",
					date: "2022-06-19T16:00:00.000Z",
				},
				{
					openPrice: "18000",
					// time: 1655658000000,
					// circulatingSupply: "19071631.0000000000000000",
					date: "2022-06-19T17:00:00.000Z",
				},
				{
					openPrice: "17000",
					// time: 1655661600000,
					// circulatingSupply: "19071631.0000000000000000",
					date: "2022-06-19T18:00:00.000Z",
				},
			],
			minPrice: null,
			maxPrice: null,
			// ____
			selectedItemSEND: null,
			selectedItemRECEIVE: null,
			loadingList: [
				{
					"full_pair": "_______",
					"from_pair": "___",
					"to_pair": "___",
					"current_price": "00.00"
				},
				{
					"full_pair": "_______",
					"from_pair": "___",
					"to_pair": "___",
					"current_price": "00.00"
				},
				{
					"full_pair": "_______",
					"from_pair": "___",
					"to_pair": "___",
					"current_price": "00.00"
				},
				{
					"full_pair": "_______",
					"from_pair": "___",
					"to_pair": "___",
					"current_price": "00.00"
				},
			],

			// _____________ COIN LIST
			readyCoinList: [],
			selectFROM: "BTC(BTC)",
			selectTO: null,
			forFullPair: "",
			oneMoreForFullPair: "",
			objWithLastPrice: null,
			// ______________________
			listFrom: [],
			listTo: [],
			isListToLoading: false,
			isListToLoadingLeft: false,
			shoeBTNexchange: true,
			// FOR HEADER Of Form
			selectedPAIR: {
				full_pair: null,
				from_pair: null,
				to_pair: null,
			},

		};
	},
	computed: {
		filteredList: function () {
			const searchString = this.searchString.toUpperCase();
			return this.listFrom.filter(item => item.from_pair.includes(searchString));
		},
		filteredListRight: function () {
			const searchStringRight = this.searchStringRight.toUpperCase();
			return this.listTo.filter(item => item.to_pair.includes(searchStringRight));
		},
		minP() {
			return Math.min(
				...this.history.map((h) =>
					parseFloat(h.openPrice).toFixed(2)
				)
			);
		},

		maxP() {
			return Math.max(
				...this.history.map((h) =>
					parseFloat(h.openPrice).toFixed(2)
				)
			);
		},
		// STORE
		stepExchange: {
			set: (payload) => store.commit("setStepExchange", payload),
			get: () => store.getters.getStepExchange,
		},
		start_ex: {
			set: (payload) => store.commit("setManualCancel", payload),
			get: () => store.getters.getManualCancel,
		},
		// selectedPAIR: {
		// 	set: (payload) => store.commit("setSelectedPAIR", payload),
		// 	get: () => store.getters.getSelectedPAIR,
		// },
	},
	filters: {
		truncate_price: function (price) {
			if (price > 1) {
				return Number((price).toFixed(2))
			} else {
				const numberString = price.toString();
				let retNum = null
				// Знаходимо позицію коми
				const dotIndex = numberString.indexOf('.');
				// Якщо кома знайдена, шукаємо нулі після коми до наступного числа
				if (dotIndex !== -1) {
					let zeroCount = 0;
					for (let i = dotIndex + 1; i < numberString.length; i++) {
						if (numberString[i] === '0') {
							zeroCount++;
						} else {
							break;
						}
					}
					retNum = zeroCount
				}
				return parseFloat(price.toFixed(retNum + 3));
			}
		}
	},
	mounted() {
		this.get_pair_FROM()
		let selcted = this.selectFROM
		this.get_pair_TO(selcted)
		this.selectedSEND(selcted)
		// this.checkKyc()
	},
	methods: {
		getImgUrl(pet) {
			// var images = require.context('../assets/icons-crypto/', false, /\.png$/);
			// if (images) {
			// 	const img = images('./' + pet + ".png");
			// 	return img;
			// } else {
			// 	return images("./CASH.png");
			// }
			var images = require.context('../assets/icons-crypto/', false, /\.png$/);
			try {
				return images('./' + pet + '.png');
			} catch (error) {
				console.log("Please, add icon for:",pet);
				
				return images('./CASH.png'); // Дефолтное изображение
			}
		},
		getImgUrl2(pet) {
			// var images = require.context('../assets/icons-crypto/', false, /\.png$/)
			// if (images) {
			// 	return images('./' + pet + ".png")
			// } else {
			// 	return images("./CASH.png")
			// }
			var images = require.context('../assets/icons-crypto/', false, /\.png$/);
			try {
				return images('./' + pet + '.png');
			} catch (error) {
				console.log("Please, add icon for:",pet);
				
				return images('./CASH.png'); // Дефолтное изображение
			}
		},
		selectedSEND(pairFROM) {
			// console.log(pairFROM);
			if (this.selectedItemSEND == pairFROM) {
				this.selectedItemSEND = null;
				this.selectFROM = null;
			} else {
				this.selectedItemSEND = pairFROM;
				this.selectFROM = pairFROM;
			}

			if (this.selectFROM != null && this.selectTO != null) {
				this.shoeBTNexchange = false
			} else {
				this.shoeBTNexchange = true
			}
		},

		selectedRECEIVE(pairTO) {
			this.selectTO = pairTO

			if (this.selectedItemRECEIVE == pairTO) {
				this.selectedItemRECEIVE = null;
				this.selectTO = null;
			} else {
				this.selectedItemRECEIVE = pairTO;
			}
			if (this.selectFROM != null && this.selectTO != null) {
				this.shoeBTNexchange = false
			} else {
				this.shoeBTNexchange = true
			}
		},
		kycCancel() {
			this.showKycModal = !this.showKycModal
		},
		kycOk() {
			router.push('/dashboard');
		},
		goAuth() {
			router.push('/auth');
		},
		checkLogin() {
			return localStorage.getItem('username') !== null;
		},

		startExchange() {
			this.showPreloaderExchange = true
			// Перевіряємо пару чи являєтьс явона ФІАТом >>> 
			if (this.check_fiat_pair()) {
				// Проверка сразу на логин,КУЦ и верив карты
				this.CheckAllowFiatFaunds() // Внутри функции редирект на обмен,при успехе: this.allowTrade()
			} else {
				// Дозволено усім,це не ФІАТ
				if (this.check_ExchangeTG()) {
					// From for TG Exchange
					this.allowTradeTelegram()
					this.showPreloaderExchange = false
				} else {
					// Standart
					this.allowTrade()
					this.showPreloaderExchange = false
				}

			}
		},
		allowTrade() {
			if (this.selectFROM != null && this.selectTO != null) {
				this.start_ex = true;
				let pair = this.selectFROM + "_" + this.selectTO
				this.stepExchange = 1
				this.$router.push({ name: "step-1", path: `/exchange/${pair}`, params: { 'pair': pair } })
			}
		},
		allowTradeTelegram() {
			if (this.selectFROM != null && this.selectTO != null) {
				this.start_ex = true;
				let pair = this.selectFROM + "_" + this.selectTO
				this.stepExchange = 1
				this.$router.push({ name: "FormUAH", path: `/uah-exchange/${pair}`, params: { 'pair': pair } })
			}
		},
		check_fiat_pair() {
			let selected_pair = this.selectFROM + "_" + this.selectTO
			for (const pair of this.listTo) {
				if (pair.full_pair === selected_pair) {
					return pair.is_fiat;
				}
			}
		},
		check_ExchangeTG() {
			let selected_pair = this.selectFROM + "_" + this.selectTO
			for (const pair of this.listTo) {
				if (pair.full_pair === selected_pair) {
					return pair.exhange_tg;
				}
			}
		},
		localStorageKYC() {
			return localStorage.getItem('isKYC') === 'true';
		},
		CheckAllowFiatFaunds() {
			axios.post('/auth/allow_fiat_funds/'
			).then(data => {
				if (data.data['user_login'] === true) {
					if (data.data['aprove_KYC'] === true && data.data['aprove_CARD'] === true) {
						this.allowTrade()
						this.showPreloaderExchange = false
					} else {
						this.showKycModal = true;
						this.showPreloaderExchange = false
					}
				} else {
					// console.log("User not login");
					this.showAlertFiatModal = true
					this.showPreloaderExchange = false
				}

			}).catch(error => {
				console.error(error);
				this.showAlertErrorWhenCheckFiat = true
				this.showPreloaderExchange = false
			});


		},
		manualCloseExchange() {
			// Обнулять
			this.start_ex = false;
			this.stepExchange = 0;
			this.$router.push({ name: 'Main', })
		},

		get_pair_FROM() {
			// let num = 0;
			this.isListToLoadingLeft = true
			// console.log("get_pair_FROM: ", num, this.isListToLoadingLeft);
			let self = this;
			axios.get("v1/coinlist/", {
				params: {
					type_pair: 0
				}
			})
				.then(function (resp) {
					self.listFrom = resp.data
					self.isListToLoadingLeft = false
					// console.log("get_pair_FROM: ", num, self.isListToLoadingLeft);

				})
		},
		get_pair_TO(from_pair) {
			this.isListToLoading = true
			let self = this;
			axios.post("v1/lastprice/", {
				headers: {
					'Content-Type': 'application/json'
				},
				data: {
					"from_pair": from_pair,
				}
			})
				.then(function (resp) {
					self.listTo = resp.data
					self.isListToLoading = false
				})
		},
		get_tikers(full_pair) {
			let self = this;
			let prwt = full_pair.split("_")
			self.forFullPair = prwt[0]
			self.oneMoreForFullPair = prwt[1]
			axios.get("v1/coinlist/", {
				params: {
					type_pair: 2,
					full_pair: full_pair
				}
			})
				.then(function (resp) {
					self.objWithLastPrice = resp.data;
					self.history = resp.data.graphAnswer;
				})
				.catch(function (error) {
					console.log(error);
				})
		},
	},
};
</script>
<style>
.preloaderExchange {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.main-alert-modal {
	position: fixed !important;
	z-index: 10;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.3);
	top: 0;
	left: 0;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.alert-modal {
	width: 50%;
}

.modal-kyc {
	width: 90%;
	height: 90%;
	padding: 5%;
	background: rgba(0, 0, 0, 0.15);
	-webkit-backdrop-filter: blur(150px) !important;
	backdrop-filter: blur(150px);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	color: #fff;
}

.modal-kyc .header {
	text-align: right;
	font-weight: 800;
	color: #fff;
	font-size: 20px;
}

.modal-kyc .body {
	margin-bottom: 60px;
}

.modal-kyc .body h2 {
	text-align: center;
	font-size: 36px;
	line-height: 40px;
	font-weight: 600;
	margin: 20px 0px;
}

.title-explain {
	font-size: 20px !important;
	font-weight: 600;
	margin-bottom: 10px;
}

.modal-kyc .body p {
	text-align: left;
	font-size: 18px;
	line-height: 28px;
}

.modal-kyc .btn-modal {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.modal-kyc .btn-modal button {
	width: 170px;
	height: 40px;
	border: solid 1px #fff;
	background-color: inherit;
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	color: #fff;
	cursor: pointer;
}

.main_page {
	width: 85%;
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background-image: url("../assets/BG_RIGHT.png");
	background-repeat: repeat-y;
	background-position: right;
	background-size: 15%;
}

.central {
	width: 84%;
	margin-bottom: 150px;
}

/* .rights_bgc_img {
		width: 20%;
		background-image: url("../assets/BG_RIGHT.png");
		background-size: 80%;
		background-repeat: repeat-y;
		background-position: right;
	} */
.central h1 {
	font-style: normal;
	font-weight: 900;
	font-size: 72px;
	line-height: 83px;
	text-align: left;
	color: #fff;
	margin: 0;
}

canvas {
	color: #fff !important;
}

.quote {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	margin-bottom: 20px;
}

.quote p {
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 135%;
	text-align: right;
	width: 560px;
	color: #fff;
}

/* Pair
	PairBlock */
.Pair {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	/* margin-top: 100px; */
}

.Pair img {
	width: 48px;
	height: 48px;
}

.ChangePair {
	cursor: pointer;
	color: #fff;
	border: solid 1px #fff;
	width: 28px;
	height: 28px;
	font-size: 28px;
	line-height: 26px;
	text-align: center;
}

.ChangePair:hover {
	color: #5be7c4;
	border: solid 1px #5be7c4;
}

.PairBlock {
	width: 45%;
	height: 481px;
	z-index: 0;
	background-image: url("../assets/bg_pair.png");
	background-repeat: repeat-y;
	background-position: center center;
	background-size: 100%;
	overflow-y: scroll;
}

.list_crypto {
	display: flex;
	flex-direction: column;
	list-style-type: none;
	margin: 0;
	padding: 0 20px;
	background-color: rgba(0, 0, 0, 0.2);
	backdrop-filter: blur(40px);
	min-height: 481px;
}

.search_token input {
	width: 98%;
	margin-top: 10px;
}

.type_glasses {
	color: #fff;
	font-style: normal;
	font-weight: 700;
	font-size: 28px;
	text-transform: uppercase;
	line-height: 28px;
	margin-top: 30px;
}

.item_crypto {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	background: rgba(0, 0, 0, 0.2);
	color: #fff;
	padding: 10px 15px;
	margin-top: 30px;
}

.active__item_crypto {
	border: 2px solid #5be7c4;
}

li.item_crypto:nth-last-child(1) {
	margin-bottom: 30px;
}

.item_crypto:hover {
	transform: scale(1.05);
}

.item_crypto_info {
	cursor: pointer;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.item_block_left {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.item_block_left_name {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
}

.item_block_right {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;
}

.item_block_left_name h5,
.item_block_right h5 {
	margin: 0;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
}

.item_block_left_name h5 {
	margin-left: 10px;
}

.NextStart {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 50px;
}

.NextStart button {
	border: solid 1px #fff;
	color: #2a3587;
	background: #fff;
	font-size: 36px;
	padding: 10px 50px;
	cursor: pointer;
}

.NextStart button:hover {
	color: #fff;
	background: #2a3587;
}

.NextStart button:disabled {
	opacity: 0.5;
	cursor: default;
}

.get_value {
	margin-top: 15px;
}

/* EXCHANGES */
.StartExchange {
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;
	background-image: url("../assets/bg_pair.png");
	background-repeat: repeat-y;
	background-position: center center;
	background-size: 80%;
	margin: 0 auto;
}

.blockExchange {
	background-color: rgba(0, 0, 0, 0.2);
	backdrop-filter: blur(100px);
	width: 80%;

	padding: 20px 40px;
}

/* 
	header_Exchange
	enteringValues
	*/
.header_Exchange {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.separator_ul {
	font-size: 30px;
}

.header_Exchange button {
	font-size: 60px;
	border: none;
	color: #fff;
	background: none;
	text-align: end;
}

.header_Exchange h3 {
	font-style: normal;
	font-weight: 700;
	font-size: 36px;
	line-height: 41px;
	text-align: center;
	color: #ffffff;
	margin: 0px 0 25px 0;
}

.header_Exchange ul {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	list-style-type: none;
	color: #fff;
}

.header_Exchange ul li {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 21px;
	padding: 0 15px 10px 15px;
	border-bottom: 2px solid;
}

.header_Exchange ul li i {
	margin-right: 5px;
}

.header_Exchange .activeLi {
	color: #0e9cff;
}

/* iNTO step MAIN BLOCK */
.enteringValues {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	width: 85%;
	margin: 0 auto;
	color: #fff;
}

@keyframes ldio-ctj710s2mlk {
	0% {
		transform: translate(9px, 60px) scale(0);
	}

	25% {
		transform: translate(9px, 60px) scale(0);
	}

	50% {
		transform: translate(9px, 60px) scale(1);
	}

	75% {
		transform: translate(30px, 60px) scale(1);
	}

	100% {
		transform: translate(60px, 60px) scale(1);
	}
}

@keyframes ldio-ctj710s2mlk-r {
	0% {
		transform: translate(60px, 60px) scale(1);
	}

	100% {
		transform: translate(60px, 60px) scale(0);
	}
}

@keyframes ldio-ctj710s2mlk-c {
	0% {
		background: #5be7c4
	}

	25% {
		background: #3b60e1
	}

	50% {
		background: #16a2d3
	}

	75% {
		background: #4fc0e8
	}

	100% {
		background: #5be7c4
	}
}

.ldio-ctj710s2mlk div {
	position: absolute;
	width: 5px;
	height: 5px;
	border-radius: 50%;
	transform: translate(20px, 20px) scale(1);
	background: #5be7c4;
	animation: ldio-ctj710s2mlk 2.6315789473684212s infinite cubic-bezier(0, 0.5, 0.5, 1);
}

.ldio-ctj710s2mlk div:nth-child(1) {
	background: #4fc0e8;
	transform: translate(20px, 20px) scale(1);
	animation: ldio-ctj710s2mlk-r 0.6578947368421053s infinite cubic-bezier(0, 0.5, 0.5, 1), ldio-ctj710s2mlk-c 2.6315789473684212s infinite step-start;
}

.ldio-ctj710s2mlk div:nth-child(2) {
	animation-delay: -0.6578947368421053s;
	background: #5be7c4;
}

.ldio-ctj710s2mlk div:nth-child(3) {
	animation-delay: -1.3157894736842106s;
	background: #4fc0e8;
}

.ldio-ctj710s2mlk div:nth-child(4) {
	animation-delay: -1.9736842105263157s;
	background: #16a2d3;
}

.ldio-ctj710s2mlk div:nth-child(5) {
	animation-delay: -2.6315789473684212s;
	background: #3b60e1;
}

.loadingio-spinner-ellipsis-6t7ofp9aao8 {
	width: 70px;
	height: 20px;
	display: inline-block;
	overflow: hidden;
	background: none;
}

.ldio-ctj710s2mlk {
	width: 100%;
	height: 100%;
	position: relative;
	transform: translateZ(0) scale(1);
	backface-visibility: hidden;
	transform-origin: 0 0;
	top: -50px;
	/* see note above */
}

.ldio-ctj710s2mlk div {
	box-sizing: content-box;
}


@media (min-width: 1200px) and (max-width: 1440px) {
	.Pair img {
		width: 30px;
		height: 30px;
	}

	.item_block_left_name h5 {
		margin-left: 5px;
	}

	.item_crypto {
		padding: 10px 5px;
		margin-top: 20px;
	}

	.item_block_left_name h5,
	.item_block_right h5 {
		font-size: 16px;
	}

	.type_glasses {
		font-size: 24px;
		margin-top: 20px;
	}

	.list_crypto {
		padding: 0 15px;
	}

	.central h1 {
		font-size: 60px;
		line-height: 72px;
	}

	.quote p {
		font-size: 20px;
		line-height: 125%;
		text-align: right;
		width: 560px;
	}

}

@media (min-width: 992px) and (max-width: 1199px) {
	.Pair img {
		width: 30px;
		height: 30px;
	}

	.item_block_left_name h5 {
		margin-left: 5px;
	}

	.item_crypto {
		padding: 10px 5px;
		margin-top: 20px;
	}

	.item_block_left_name h5,
	.item_block_right h5 {
		font-size: 16px;
	}

	.type_glasses {
		font-size: 24px;
		margin-top: 20px;
	}

	.list_crypto {
		padding: 0 15px;
	}

	/* HEADER OF FORM */
	.header_Exchange h3 {
		font-size: 28px;
		line-height: 36px;
		margin: 0px 0 15px 0;
	}

	.header_Exchange ul li {
		font-size: 16px;
	}

	.enteringValues {
		width: 90%;
	}

	.attention i {
		font-size: 42px;
		margin-right: 24px;
	}

	.attention p {
		font-size: 16px;
		line-height: 24px;
	}

	.central h1 {
		font-size: 48px;
		line-height: 48px;
	}

	.quote p {
		font-size: 18px;
		line-height: 125%;
		text-align: right;
		width: 500px;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.alert-modal {
		width: 70%;
	}

	.main_page {
		padding-left: 2%;
		width: 98%;
		height: 100%;
		background-image: none;
	}

	.Pair img {
		width: 30px;
		height: 30px;
	}

	.item_block_left_name h5 {
		margin-left: 5px;
	}

	.item_crypto {
		padding: 10px 5px;
		margin-top: 20px;
	}

	.item_block_left_name h5,
	.item_block_right h5 {
		font-size: 16px;
	}

	.type_glasses {
		font-size: 24px;
		margin-top: 20px;
	}

	.list_crypto {
		padding: 0 15px;
	}

	.central {
		width: 98%;
		margin-bottom: 100px;
	}

	.NextStart button {
		font-size: 28px;
		padding: 10px 40px;
	}

	/* HEADER OF FORM */
	.header_Exchange h3 {
		font-size: 28px;
		line-height: 36px;
		margin: 0px 0 15px 0;
	}

	.header_Exchange ul li {
		font-size: 16px;
	}

	.enteringValues {
		width: 92%;
	}

	.attention i {
		font-size: 36px;
		margin-right: 18px;
	}

	.attention p {
		font-size: 14px;
		line-height: 18px;
	}

	.central h1 {
		font-size: 40px;
		line-height: 48px;
	}

	.quote p {
		font-size: 18px;
		line-height: 125%;
		text-align: right;
		width: 560px;
	}
}

@media (min-width: 480px) and (max-width: 767px) {
	.alert-modal {
		width: 90%;
	}

	.modal-kyc .body h2 {
		font-size: 32px;
		line-height: 32px;
	}

	.modal-kyc .body p {
		line-height: 24px;
	}

	.title-explain {
		font-size: 16px !important;
	}

	.modal-kyc .btn-modal button {
		width: 140px;
		font-size: 18px;
	}

	.Pair {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
	}

	.PairBlock {
		width: 75%;
		margin-bottom: 50px;
	}

	.main_page {
		padding-left: 2%;
		width: 98%;
		height: 100%;
		background-image: none;
	}

	.Pair img {
		width: 30px;
		height: 30px;
	}

	.item_block_left_name h5 {
		margin-left: 5px;
	}

	.item_crypto {
		padding: 10px 5px;
		margin-top: 20px;
	}

	.item_block_left_name h5,
	.item_block_right h5 {
		font-size: 15px;
		font-weight: bold;
	}

	.type_glasses {
		font-size: 24px;
		margin-top: 20px;
	}

	.list_crypto {
		padding: 0 15px;
	}

	.central {
		width: 98%;
		margin-bottom: 50px;
	}

	.NextStart {
		margin-top: 0;
	}

	.NextStart button {
		font-size: 24px;
		padding: 10px 30px;
	}

	/* BIG PART OF FORM */
	.blockExchange {
		width: 96%;
		padding: 10px 10px;
	}

	.StartExchange {
		background-size: 96%;
	}

	.header_Exchange h3 {
		font-size: 18px;
		line-height: 28px;
		margin: 0px 0 15px 0;
	}

	.header_Exchange ul li {
		font-size: 14px;
	}

	.enteringValues {
		width: 92%;
	}

	.attention i {
		font-size: 36px;
		margin-right: 18px;
	}

	.attention p {
		font-size: 14px;
		line-height: 18px;
	}

	.header_Exchange button {
		font-size: 50px;
	}

	.header_Exchange ul {
		justify-content: space-between;
	}

	.typeFromTo h5 {
		margin: 5px 0px;
		font-size: 18px;
		line-height: 18px;
	}

	.central h1 {
		font-size: 32px;
		line-height: 36px;
	}

	.quote p {
		font-size: 14px;
		line-height: 125%;
		text-align: right;
		width: 400px;
	}
}

@media (max-width: 479px) {
	.alert-modal {
		width: 90%;
	}

	.modal-kyc .body {
		margin-bottom: 30px;
	}

	.modal-kyc .body h2 {
		font-size: 18px;
		line-height: 20px;
		margin: 10px 0;
	}

	.modal-kyc .body p {
		line-height: 18px;
		font-size: 14px;
	}

	.title-explain {
		font-size: 14px !important;
	}

	.modal-kyc .btn-modal button {
		width: 100px;
		font-size: 14px;
	}

	.Pair {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
	}

	.PairBlock {
		width: 90%;
		margin-bottom: 50px;
	}

	.main_page {
		padding-left: 2%;
		width: 98%;
		height: 100%;
		background-image: none;
	}

	.Pair img {
		width: 20px;
		height: 20px;
	}

	.item_block_left_name h5 {
		margin-left: 5px;
	}

	.item_crypto {
		padding: 10px 5px;
		margin-top: 10px;
	}

	.item_block_left_name h5,
	.item_block_right h5 {
		font-size: 12px;
		font-weight: bold;
	}

	.type_glasses {
		font-size: 18px;
		margin-top: 10px;
	}

	.list_crypto {
		padding: 0 10px;
	}

	.central {
		width: 98%;
		margin-bottom: 50px;
	}

	.NextStart {
		margin-top: 0;
	}

	.NextStart button {
		font-size: 24px;
		padding: 10px 30px;
	}

	/* font-weight: bold; */
	.item_block_right h5 {
		font-weight: bold;
	}

	/* HEADER OF FORM */
	.blockExchange {
		width: 98%;
		padding: 10px 5px;
	}

	.StartExchange {
		background-size: 96%;
	}

	.header_Exchange h3 {
		font-size: 16px;
		line-height: 24px;
		margin: 0px 0 15px 0;
	}

	.header_Exchange ul li {
		display: flex;
		flex-direction: row;
		align-items: center;
		font-style: normal;
		font-weight: 500;
		line-height: 21px;
		font-size: 14px;
		padding: 10px 10px 5px 10px;
		border-bottom: 2px solid;
		width: 40%;
		justify-content: center;
	}

	.enteringValues {
		width: 100%;
	}

	.attention i {
		font-size: 28px;
		margin-right: 10px;
	}

	.attention p {
		font-size: 10px;
		line-height: 14px;
	}

	.header_Exchange button {
		font-size: 40px;
	}

	.header_Exchange ul {
		display: flex;
		flex-direction: column;
		/* justify-content: space-evenly; */
		list-style-type: none;
		color: #fff;
		align-items: center;
	}

	.typeFromTo h5 {
		margin: 5px 0px;
		font-size: 14px;
		line-height: 18px;
	}

	.min_max h6 {
		margin: 5px 0;
		font-size: 14px;
		line-height: 18px;
	}

	.typeFromTo input {
		font-size: 12px;
	}

	.rules {
		font-size: 12px;
		line-height: 18px;
	}

	.rules p {
		font-size: 12px;
		line-height: 14px;
	}

	.rules ul li {
		font-size: 12px;
		line-height: 14px;
		margin-bottom: 5px;
	}

	.central h1 {
		font-size: 24px;
		line-height: 28px;
	}

	.quote p {
		font-size: 14px;
		line-height: 125%;
		text-align: right;
		width: 230px;
	}
}
</style>
