<template>
	<div id="app">
		<Preloader />
		<!-- Проверка ИПОВ что бы не отвалился АПИ -->
		<!-- <div class="check_ip">
			<h1 class="fff">Your IP: {{current_ip}}</h1>
			<h1 v-if="current_ip == '188.116.27.3'" class="green">SUCSSES CONECTION</h1>
			<h1 v-else class="red">ENABLE VPN!</h1>
		</div> -->
		<Header />
		<Auth v-if="openFormAuth === true" />
		<div class="content">
			<SideBar />
			<router-view />
		</div>
		<Footer />
    <ContactForm/>
	</div>
</template>
<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Preloader from "@/components/Preloader.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import Auth from "@/components/Auth.vue";

// ____
import store from "@/store";
import jwt from "@/auth/index.js";
import axios from './axios'
import ContactForm from "@/components/ContactForm.vue";

export default {
	name: "Home",
	components: {
    ContactForm,
		Header,
		Footer,
		SideBar,
		Auth,
		Preloader,
		
	},
	data() {
		return {
			current_ip: null,
		}
	},
	computed: {
		openFormAuth: {
			set: (payload) => store.commit("setOpenFormAuth", payload),
			get: () => store.getters.getOpenFormAuth,
		},
		isAuth: {
			set: (payload) => store.commit("setIsAuth", payload),
			get: () => store.getters.getIsAuth,
		},
	},
	methods: {
		// Проверка ИПОВ что бы не отвалился АПИ 
		chekIP() {
			// fetch('https://api.ipify.org?format=json')
			fetch('https://api.ipdata.co?api-key=7548454af55694aed0e381b09c2b636d174c110a0cb950541c1ac231')
				.then(response => response.json())
				.then(data => this.current_ip = data.ip);
		},
		healthChecker() {
			// let self = this;
			axios.get("/v1/health-checker/", this.selectedPAIR)
				.catch(error => {
					this.$router.push({path: `/error-service/${error.status}`, query: { 'message': error.data.message } })
				})
		},
		mainHealthChecker() {
			// let self = this;
			axios.get("/v1/ht/?format=json", this.selectedPAIR)
				.catch(error => {
					let message_err = ""
					let arr = []
					for(let item in error.data){
						if(error.data[item] != "working"){
							// message_err = error.data[item]
							arr.push(error.data[item])
						}
						
					}
					message_err = arr.join(", ")
					let message_done = message_err.slice(0, 200);
					this.$router.push({path: `/error-service/${error.status}`, query: { 'error': message_done } })
				})
		},
		preloaderCall() {
			document.onreadystatechange = () => {
				if (document.readyState == "complete") {
					// For Preloader close use class "preloader_hide"
					let loaderNode = document.querySelector(".preloader");
					loaderNode.classList.add("preloader_hide");
					setTimeout(() => {
						loaderNode.style.display = "none";
					}, 1000);
				} else {
					let loaderNode = document.querySelector(".preloader");
					loaderNode.classList.remove("preloader_hide");
				}
			};
		}
	},
	mounted() {
		this.mainHealthChecker()
		// this.healthChecker()
		setInterval(()=>{
			this.mainHealthChecker()
			// this.healthChecker()
		}, 300000)
		
		// this.chekIP()
		this.preloaderCall()
		const token = localStorage.getItem("accessToken");
		if (token) {
			this.isAuth = true;
		}
	},
	async created() {
		jwt.init();
	},
};
</script>
<style>
@import './assets/reset.min.css';

/** Проверка ИПОВ что бы не отвалился АПИ **/
.check_ip {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 15px 0px;
	width: 450px;
	margin: 0 auto;
	border: solid 5px #fff;
	border-top: none;
}

.check_ip .fff {
	color: #fff;
}

.check_ip h1 {
	font-size: 24px;
	font-weight: bold;
	line-height: 32px;
}

.check_ip .green {
	color: #0df30d;
}

.check_ip .red {
	color: red;
}

body {
	margin: 0;
}

#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: #3441a6;
	min-height: 100vh;
}

.content {
	display: flex;
	flex-direction: row;
	max-width: 1440px;
	min-height: 69vh;
	margin: 0 auto;
}

/* width */
::-webkit-scrollbar {
	width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 1px #5be7c4;
	border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #5be7c4;
	border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #5be7c4;
}

/* Efect for input */
/* input[type="text"] {
		font: 15px/24px "Lato", Arial, sans-serif;
		color: #333;
		width: 100%;
		box-sizing: border-box;
		letter-spacing: 1px;
	}
	.effect-9 {
		border: 1px solid #ccc;
		padding: 7px 14px 9px;
		transition: 0.4s;
	}
	.effect-9 ~ .focus-border:before,
	.effect-9 ~ .focus-border:after {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		width: 0;
		height: 2px;
		background-color: #4caf50;
		transition: 0.2s;
		transition-delay: 0.2s;
	}

	.effect-9 ~ .focus-border:after {
		top: auto;
		bottom: 0;
		right: auto;
		left: 0;
		transition-delay: 0.6s;
	}

	.effect-9 ~ .focus-border i:before,
	.effect-9 ~ .focus-border i:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 2px;
		height: 0;
		background-color: #4caf50;
		transition: 0.2s;
	}

	.effect-9 ~ .focus-border i:after {
		left: auto;
		right: 0;
		top: auto;
		bottom: 0;
		transition-delay: 0.4s;
	}

	.effect-9:focus ~ .focus-border:before,
	.effect-9:focus ~ .focus-border:after {
		width: 100%;
		transition: 0.2s;
		transition-delay: 0.6s;
	}

	.effect-9:focus ~ .focus-border:after {
		transition-delay: 0.2s;
	}

	.effect-9:focus ~ .focus-border i:before,
	.effect-9:focus ~ .focus-border i:after {
		height: 100%;
		transition: 0.2s;
	}

	.effect-9:focus ~ .focus-border i:after {
		transition-delay: 0.4s;
	} */
.mb1 {
	margin-bottom: 10px;
}

.mb2 {
	margin-bottom: 20px;
}

.mb3 {
	margin-bottom: 30px;
}

.mb4 {
	margin-bottom: 40px;
}

.mb5 {
	margin-bottom: 50px;
}

.mb6 {
  margin-bottom: 40px;
}

.mt1 {
	margin-top: 10px;
}

.mt2 {
	margin-top: 20px;
}

.mt3 {
	margin-top: 30px;
}

.mt4 {
	margin-top: 40px;
}

.mt5 {
	margin-top: 50px;
}

.mh300 {
	min-height: 300px;
}

.mh600 {
	min-height: 600px;
}

.w100 {
	width: 100%;
}

.w50 {
	width: 50%;
}

.w40 {
	width: 40%;
}

.weight900 {
	font-weight: 900;
}

.txt-l {
	text-align: left;
}

.txt-c {
	text-align: center;
}

.txt-r {
	text-align: right;
}

.txt-j {
	text-align: justify;
}

.btn-blue {
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	color: #2a3587;
	background: #fff;
	border: none;
	width: 100%;
	padding: 10px 0;
	cursor: pointer;
	border: solid 1px #fff;
}

.btn-blue:hover {
	font-size: 20px;
	color: #fff;
	background: #2a3587;
}

.base-input {
	background-size: 30px;
	/* background-position: 98% center; */
	height: 40px;
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 23px;
	padding: 0 0 0 2%;
	color: #fff !important;
	border: none;
	background-color: rgba(127, 179, 238, 0.5);
	box-shadow: inset -1.98073px -1.98073px 1.98073px rgba(255, 255, 255, 0.5),
		inset 1.98073px 1.98073px 1.98073px rgba(52, 65, 166, 0.5);
	border-radius: 0.990366px;
}

.base-input::placeholder {
	color: #fff;
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 23px;
}

.base-input:focus {
	outline: none;
}

@media (min-width: 1200px) and (max-width: 1440px) {}

@media (min-width: 992px) and (max-width: 1199px) {}

@media (min-width: 768px) and (max-width: 991px) {}

@media (min-width: 480px) and (max-width: 767px) {}

@media (max-width: 479px) {}
</style>
