<template>
    <div class="terms-section">
        <p class="disclaimer">
            Please read these terms of service (the "Terms") carefully before using our Service. By accessing or using any part of the site, you agree to be bound by these Terms. If you don’t agree with these Terms or any of its Clauses, you shall immediately cease to use any Exmoney services.
        </p>
        <SectionTile 
            v-for="item in termsList"
            :key="item.sectionNumber"
            :item="item"
        />

    </div>
</template>
<script>
import SectionTile from './sectionTile/SectionTile.vue';
import { itemsList } from './data/data.js';

export default {
    components: {
        SectionTile,
    },
    data() {
        return {
            termsList: itemsList,
        };
    },
};
</script>
<style>
    .terms-section {
        width: 95%;
        display: flex;
        flex-direction: column;
        color: #fff;
        margin: 40px 0 60px 0;
    }

    .disclaimer {
        font-size: 16px;
        line-height: 22px;
        color: #fff;
    }

    @media (max-width: 1024px) {
        .terms-section {
            width: 100%;
        }
    }

</style>