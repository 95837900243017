<template>
    <div class="tile">
        <h3
            class="section_number"
            :id="'section_' + item.sectionNumber"
        >
            Section {{ item.sectionNumber }}
        </h3>
        <h1>
            {{ $t(`${'terms_title_' + item.sectionNumber}`) }}
        </h1>
        <div>
            <ol class="terms-list">
                <li
                    v-for="rule in item.items"
                    :key="rule.number"
                >
                    <span class="rule-number">{{ rule.title }}</span>
                    <p class="rule-item">
                        {{ $t(`${'terms_' + rule.number}`) }}
                    </p>
                    <ol
                        v-if="!!rule.children"
                    >
                        <li
                            v-for="subRule in rule.children"
                            :key="subRule.number"
                            class="terms-list__sub-rule"
                        >
                            <span class="sub-rule_number">{{ subRule.title }}</span>
                            <p>
                                {{ $t(`${'terms_' + subRule.number}`) }}
                            </p>
                        </li> 
                    </ol>
                    <p v-if="rule.secondPart">
                        {{ $t(`${'terms_' + rule.secondPart.number}`) }}
                    </p>
                </li>
            </ol>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            item: {
                type: Object,
                required: true,
            },
        },
    };
</script>
<style>
    .tile {
        margin-top: 40px;
    }

    .terms-section p {
        font-size: 16px;
        line-height: 22px;
    }

    .section_number {
        text-transform: uppercase;
        font-size: 18px;
    }

    .terms-section h1 {
        font-size: 32px;
        line-height: 32px;
        margin-top: 8px;
        border-bottom: 1px solid #fff;
    }

    .terms-list {
        list-style: none;
        margin-top: 16px;
        padding-left: 45px;
    }

    .terms-list li {
        margin-bottom: 14px;
        position: relative;
    }

    .rule-number {
        position: absolute;
        font-weight: 900;
        left: -45px;
        top: 5px;
    }

    .rule-item {
        margin-bottom: 10px;
    }

    .terms-list__sub-rule {
        padding-left: 55px;
        position: relative;
    }

    .sub-rule_number {
        position: absolute;
        font-weight: 900;
        left: 0;
        top: 5px;
    }
</style>