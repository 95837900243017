<template>
	<div class="main_auth">
		<div class="main_form">
			<div class="content_auth" v-if="loginForm === true">
				<div class="header_auth">
					<button @click="closeFormAuth">
						<img src="@/assets/times.png" alt="" />
					</button>
				</div>
				<div class="body_auth">
					<input type="Email" placeholder="Email" v-model="login" />
					<div class="error" v-if="!$v.login.required">{{ $t("auth_error_email_required") }}</div>
					<input type="password" placeholder="Password" v-model="password" v-on:keyup.enter="loginJWT"/>
					<div class="error" v-if="!$v.password.required">{{ $t("auth_error_password_required") }}</div>
					<div class="error" v-if="!$v.password.minLength">{{ $t("auth_error_password_min") }}</div>
					<input v-if="$store.state.isTFA" type="text" placeholder="000 000" v-model="otp" />
					<a href="#" @click.prevent="openForgotPasswordForm" class="forgot-password-link">{{ $t("auth_password_forgot") }}</a>
					<div class="check_box">
						<!-- <input type="checkbox" name="" id="" v-model="rememberMe" />
						<h4 @click="rememberMe = !rememberMe">Remember me</h4> -->
					</div>
				</div>
				<div class="footer_auth">
					<button class="active_button" :disabled="!validateForm" @click="loginJWT">
						{{ $t("auth_button_login") }}
					</button>
					<button @click="openRegisterForm">{{ $t("auth_button_register") }}</button>
				</div>
			</div>
			<div class="content_auth" v-if="registerForm === true" v-click-outside="closeFormAuth">
				<div class="header_auth">
					<button @click="closeFormAuth">
						<img src="@/assets/times.png" alt="" />
					</button>
					<br />
					<h1>{{ $t("reg_title") }}</h1>
					<p>
						{{ $t("reg_create_account_subtitle") }}
						<span @click="closeRegisterForm">{{ $t("reg_login_link") }}</span>
					</p>
				</div>
				<div class="body_auth">
					<input type="Email" :placeholder="$t('reg_first_name')" name="FirstName" v-model="registerData.first_name" />
					<div class="error" v-if="!$v.registerData.first_name.required">
						{{ $t("reg_error_first_name_required") }}
					</div>
					<div class="error" v-if="!$v.registerData.first_name.alpha">
						{{ $t("reg_error_first_name_alpha") }}
					</div>
					<input type="Email" :placeholder="$t('reg_last_name')" name="LastName" v-model="registerData.last_name" />
					<div class="error" v-if="!$v.registerData.last_name.required">
						{{ $t("reg_error_last_name_required") }}
					</div>
					<div class="error" v-if="!$v.registerData.last_name.alpha">
						{{ $t("reg_error_last_name_alpha") }}
					</div>
					<input type="Phone" :placeholder="$t('reg_phone')" name="Phone" v-model="registerData.phone" />
					<div class="error" v-if="!$v.registerData.phone.required">
						{{ $t("reg_error_phone_required") }}
					</div>
					<div class="error" v-if="!$v.registerData.phone.numeric">
						{{ $t("reg_error_phone_numeric") }}
					</div>
					<input type="Email" :placeholder="$t('reg_email')" v-model="registerData.email" />
					<div class="error" v-if="!$v.registerData.email.required">
						{{ $t("reg_login_link") }}
					</div>
					<div class="error" v-if="!$v.registerData.email.email">
						{{ $t("reg_login_link") }}
					</div>
					<input type="password" :placeholder="$t('reg_password')" v-model="registerData.password" />
					<div class="error" v-if="!$v.registerData.password.required">
						{{ $t("reg_login_link") }}
					</div>
					<div class="error" v-if="!$v.registerData.password.minLength">
						{{ $t("reg_login_link") }}
					</div>
					<input type="password" :placeholder="$t('reg_confirm_password')" v-model="registerData.conf_password" />
					<div class="error" v-if="!$v.registerData.conf_password.required">
						{{ $t("reg_login_link") }}
					</div>
					<div class="error"
						v-if="!$v.registerData.conf_password.sameAs && $v.registerData.conf_password.required">
						{{ $t("reg_login_link") }}
					</div>
					<input type="Email" :placeholder="$t('reg_promocode')" name="Promocode" v-model="registerData.promocode" />

				</div>
				<br />
				<div class="footer_auth">
					<button class="active_button" @click="registerJWT">
						{{ $t("reg_login_link") }}
					</button>
				</div>
			</div>

			<div class="content_auth" v-if="forgotPasswordForm === true">
				<div class="header_auth">
					<button @click="closeFormAuth">
						<img src="@/assets/times.png" alt="" />
					</button>
				</div>
				<div class="body_auth">
					<input type="Email" placeholder="Email" v-model="email_to_send" v-on:keyup.enter="changePasswordUser"/>
					<div class="error" v-if="!$v.email_to_send.required">{{ $t("reg_login_link") }}</div>
								<div class="error" v-if="!$v.email_to_send.email">{{ $t("reg_login_link") }}</div><br>
							</div>
					<div v-if="err_email_changed">
						<p class="error-recover">{{ err_email_changed }}</p>
					</div>
					<div class="footer_auth">
						<button class="active_button_send" :disabled="!validateRecoverPassword || sendingToServer" @click="changePasswordUser">
							{{ $t("reg_login_link") }}
						</button>
				</div>
			</div>
			<div class="content_auth" v-if="forgotPasswordFormStatus === true">
				<div class="header_auth">
					<button @click="closeFormAuth">
						<img src="@/assets/times.png" alt="" />
					</button><br>
					<h1 style="text-align: center;margin: 40px 0px;">{{ $t("forgot_confirm_message") }}</h1>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import store from "@/store";
import Vue from "vue";
import vClickOutside from "v-click-outside";
import axios from '../axios';
import { validationMixin } from 'vuelidate';
// import { email, required, numeric, alpha } from '@vuelidate/validators';
import { required, alpha, numeric, email, sameAs, minLength } from 'vuelidate/lib/validators'



Vue.use(vClickOutside);
export default {
	data() {
		return {
			sendingToServer:false,
			login: "",
			email: "",
			email_to_send: "",
			recoverError: "",
			password: "",
			otp: "",
			rememberMe: false,
			loginForm: true,
			registerForm: false,
			forgotPasswordForm: false,
			forgotPasswordFormStatus:false,
			err_email_changed:'',
			vcoConfig: {
				handler: this.handler,
				middleware: this.middleware,
				events: ["dblclick", "click"],
				// Note: The default value is true, but in case you want to activate / deactivate
				//       this directive dynamically use this attribute.
				isActive: true,
				// Note: The default value is true. See "Detecting Iframe Clicks" section
				//       to understand why this behavior is behind a flag.
				detectIFrame: true,
				// Note: The default value is false. Sets the capture option for EventTarget addEventListener method.
				//       Could be useful if some event's handler calls stopPropagation method preventing event bubbling.
				capture: false,
			},
		};
	},
	validations: {
    login: {
      required,
    },
    password: {
      required,
      minLength: minLength(8)
    },
    email_to_send:{
      required,
      email
    },
		registerData: {
			first_name: { required, alpha },
			last_name: { required, alpha },
			phone: { required, numeric },
			email: { required, email },
			password: {
				required,
				minLength: minLength(8)
			},
			conf_password: {
				required,
				sameAs: sameAs('password')
			},
		},
	},
	mixins: [validationMixin,],
	computed: {
		registerData: {
			set: (payload) => store.commit("setRegisterData", payload),
			get: () => store.getters.getRegisterData,
		},
		openFormAuth: {
			set: (payload) => store.commit("setOpenFormAuth", payload),
			get: () => store.getters.getOpenFormAuth,
		},
		validateForm() {
			return this.login != "" && this.password != "";
		},
		validateRecoverPassword() {
			return this.email_to_send != "";
		},
	},
	methods: {
		changePasswordUser(){
			this.sendingToServer = true
			let self = this;
			axios.post('v1/password_reset/', {
				headers: {
					'Content-Type': 'application/json'
				},
				email: this.email_to_send
			}).then(()=> {
				self.forgotPasswordForm = false
				self.forgotPasswordFormStatus = true
			}).catch(function (error) {
				self.err_email_changed = error.data.email[0]
			}).finally(()=>{
				self.sendingToServer = false
			})

			
		},
		openRegisterForm() {
			this.registerForm = true;
			this.loginForm = false;
		},
		closeRegisterForm() {
			this.registerForm = false;
			this.loginForm = true;
		},
		closeFormAuth() {
			this.$router.push('/');
			this.openFormAuth = false;
		},
		handler(event) {
			console.log(
				"2000 Clicked outside (Using config), middleware returned true :)",
				event
			);
		},
		// Note: The middleware will be executed if the event was fired outside the element.
		//       It should have only sync functionality and it should return a boolean to
		//       define if the handler should be fire or not
		middleware(event) {
			return event.target.className !== "modal";
		},
		openForgotPasswordForm() {
		this.loginForm = false
		this.forgotPasswordForm = true
		},
		recoverPassword() {
			try {
				const response = axios.post('/auth/recover_password/', {
					headers: {
						'Content-Type': 'application/json'
					},
				data: {
					email_to_send: this.email_to_send
				}
			});
			response.then(data => {
			if (data.data['success'] === false) {
				this.recoverError = data.data['info']
			} else {
				this.loginForm = true
				this.forgotPasswordForm = false
			}
			}).catch(function (error) {
			console.error(error);
						});

				} catch (e) {
					alert('Error')
				}
		},
		loginJWT() {
			//if (!this.checkLogin()) return;
			// Loading
			// this.$vs.loading();
			const payload = {
				userDetails: {
					email: this.login,
					password: this.password,
					otp: this.otp,
				},
			};

			try {
				axios.post('/auth/set_logging_history/', {
					headers: {
						'Content-Type': 'application/json'
					},
					data: {
						username: this.login
					}
				})
					.catch(function (error) {
						console.error(error);
					});

			} catch (e) {
				alert('Error',e)
			}

			this.$store
				.dispatch("loginJWT", payload)
				.catch((err) => {
					console.error("err",err.data.detail);
					this.$vs.loading.close();
					this.$vs.notify({
						title: "Error",
						text: err.data.detail,
						iconPack: "feather",
						icon: "icon-alert-circle",
						color: "danger",
					});
				});
		},
		registerJWT() {
			//if (!this.checkLogin()) return;
			// Loading
			// this.$vs.loading();

			const payload = {
				email: this.registerData.email,
				password: this.registerData.password,
				// c_password: this.r_passwordRepeat, // убрать и проверять JS на ===
				phone: this.registerData.phone,
				first_name: this.registerData.first_name,
				last_name: this.registerData.last_name,
				promocode: this.registerData.promocode,
			};

			this.$store
				.dispatch("registerJWT", payload)
				.catch((error) => {
					console.err("[ERROR] in registerJWT [AUTH.VUE]", error);
					this.$vs.loading.close();
					this.$vs.notify({
						title: "Error",
						text: "Error Registration",
						iconPack: "feather",
						icon: "icon-alert-circle",
						color: "danger",
					});
				});


		},
	},
};
</script>

<style>
.forgot-password-link {
  text-align: end;
  margin-top: 10px;
  color: #3498db;
  text-decoration: underline;
  cursor: pointer;
}

.forgot-password-link:hover {
  color: #2980b9;
}

.main_auth {
	position: fixed !important;
	z-index: 10;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.3);
	top: 0;
	left: 0;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.main_form {
	width: 30%;
	background-color: #fff;
	background-image: url("../assets/bg_pair.png");
	background-repeat: repeat-y;
	background-position: center center;
	background-size: 100%;
}

.content_auth {
	width: 90%;
	height: 90%;
	padding: 5%;
	background: rgba(0, 0, 0, 0.15);
	backdrop-filter: blur(150px);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

/*  header_auth
        body_auth
        footer_auth 
    */
.header_auth {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	flex-wrap: wrap;
}

.header_auth h1,
.header_auth p {
	width: 100%;
	margin: 0;
	color: #fff;
}

.header_auth span {
	color: #2a3587;
	cursor: pointer;
}

.header_auth span:hover {
	text-decoration: underline;
}

.header_auth h1 {
	font-style: normal;
	font-weight: 900;
	font-size: 32px;
}

.header_auth button {
	font-size: 32px;
	color: #fff;
	border: none;
	background-color: inherit;
	cursor: pointer;
	line-height: 20px;
}

.body_auth {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 50%;
}

.body_auth input {
	margin-top: 25px;
}

.body_auth .check_box {
	display: flex;
	flex-direction: row;
	margin-top: 30px;
	margin-bottom: 25px;
}

.body_auth input {
	width: 100%;
	height: 40px;
}

.body_auth .check_box h4 {
	margin: 0;
	color: #fff;
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 28px;
	cursor: pointer;
}

.body_auth h6 {
	margin: 0;
}

.body_auth input {
	height: 40px;
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	color: #fff !important;
	border: none;
	background-color: transparent;
	border-radius: 0.990366px;
	border-bottom: solid 2px #fff;
}

.body_auth input::placeholder {
	color: rgb(145, 145, 145);
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
}

.body_auth input:focus {
	outline: none;
}

.body_auth .check_box input[type="checkbox"] {
	height: 24px;
	width: 24px;
	border: solid 1px #fff !important;
	background-color: inherit !important;
	cursor: pointer;
	margin: 0 20px 0px 0;
}

.footer_auth {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}

.footer_auth button {
	width: 170px;
	height: 40px;
	border: solid 1px #fff;
	background-color: inherit;
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	color: #fff;
	cursor: pointer;
}

.active_button {
	color: #2a3587 !important;
	background-color: #fff !important;
}

.active_button_send {
  margin-top: 20px;
	color: #2a3587 !important;
	background-color: #fff !important;
}

.error {
	padding: 0px 0px 0px 1px;
	color: #999;
	max-height: 0;
	transition: 0.28s;
	color: red;
}

.error-recover {
  color: red;
}

@media (min-width: 1200px) and (max-width: 1440px) {
	.main_form {
		width: 40%;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.main_form {
		width: 50%;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.main_form {
		width: 60%;
	}
}

@media (min-width: 480px) and (max-width: 767px) {
	.main_form {
		width: 80%;
	}

	.body_auth input {
		font-size: 18px;
	}

	.body_auth input::placeholder {
		font-size: 18px;
	}

	.body_auth .check_box {
		margin-top: 20px;
		margin-bottom: 20px;
	}

	.body_auth .check_box input[type="checkbox"] {
		height: 16px;
		width: 16px;
		border: solid 1px #fff !important;
		background-color: inherit !important;
		cursor: pointer;
		margin: 0 10px 0px 0;
	}

	.body_auth .check_box h4 {
		font-size: 16px;
		line-height: 16px;
		cursor: pointer;
	}

	.footer_auth button {
		width: 100px;
		height: 36px;
		font-size: 18px;
	}

	/* Registration */
	.header_auth h1 {
		font-size: 24px;
	}

	.header_auth p {
		font-size: 14px;
		margin-top: 5px;
	}
}

@media (max-width: 479px) {
	.main_form {
		width: 80%;
	}

	.body_auth input {
		font-size: 14px;
		height: 20px;
	}

	.body_auth input::placeholder {
		font-size: 14px;
	}

	.body_auth .check_box {
		margin-top: 20px;
		margin-bottom: 20px;
	}

	.body_auth .check_box input[type="checkbox"] {
		height: 16px;
		width: 16px;
		border: solid 1px #fff !important;
		background-color: inherit !important;
		cursor: pointer;
		margin: 0 10px 0px 0;
	}

	.body_auth .check_box h4 {
		font-size: 16px;
		line-height: 16px;
		cursor: pointer;
	}

	.footer_auth button {
		width: 120px;
		height: 36px;
		font-size: 18px;
	}

	/* Registration */
	.header_auth h1 {
		font-size: 18px;
	}

	.header_auth p {
		font-size: 14px;
		margin-top: 5px;
	}

	.error {
		padding: 0px 0px 0px 1px;
		max-height: 0;
		transition: 0.28s;
		color: red;
		font-size: 14px;
	}
}
</style>
