<template>
  <div class="main_page">
    <div class="central">
      <div class="StartExchange">
        <div class="blockExchange">
          <div class="header_Exchange">
            <div class="close_button_of_form">
              <button style="cursor: pointer" @click="manualCloseExchange">
                &#215;
              </button>
            </div>

            <h3>{{ $t("exchange_step_1_titale") }} {{ selectedPAIR.from_pair }} {{ $t("exchange_step_1_title_to") }} {{ selectedPAIR.to_pair }}</h3>

            <ul>
              <li :class="{ activeLi: stepExchange === 1 }">
                <i class="fas fa-pencil-alt"></i>
                <h5>{{ $t("exchange_step_1_data_input") }}</h5>
              </li>

              <li :class="{ activeLi: stepExchange === 2 }">
                <i class="fas fa-credit-card"></i>
                {{ $t("exchange_step_1_payment") }}
              </li>
              <li :class="{ activeLi: stepExchange === 3 }">
                <i class="fas fa-check-double"></i>
                {{ $t("exchange_step_1_completion") }}
              </li>
            </ul>
          </div>
          <div class="mh300 enteringValues mb5" v-if="major_error == false">
            <div class="attention">
              <i class="fas fa-exclamation"></i>
              <p>
                <span class="weight900">{{ $t("exchange_step_1_attention") }} </span> <br />
                <br />
                {{ $t("exchange_step_1_attention_1") }}
                <span class="weight900">{{ $t("exchange_step_1_attention_2") }}</span> {{ $t("exchange_step_1_attention_3") }} <span class="weight900">{{ $t("exchange_step_1_attention_4") }}</span> {{ $t("exchange_step_1_attention_5") }}
                <span class="weight900">{{ $t("exchange_step_1_attention_6") }}</span> {{ $t("exchange_step_1_attention_7") }} <br /><br />
                • <span class="weight900">{{ $t("exchange_step_1_attention_8") }}</span>
                <br /><br />
                {{ $t("exchange_step_1_attention_9") }}
                <span class="weight900">{{ $t("exchange_step_1_attention_10") }}</span>
              </p>

            </div>
            <p>{{ $t("exchange_step_1_attention_11") }} : {{ currentPrice }}</p>
            <div class="typeFromTo icons mb2" v-if="full_pair.from_pair">
              <h5>{{ $t("exchange_step_1_attention_12") }}</h5>
              <div class="it_input">
                <!-- <img :src="require(`@/assets/currency_icons/png/${full_pair.from_pair}.png`)" alt="" /> -->
                <img :src="require(`@/assets/currency_icons/png/cash.png`)" alt="" />
                <input placeholder="0.004" name="" id="" v-model="$v.amount_FROM.$model" @keyup="calcInput_1(amount_FROM)"
                  @input="$v.amount_FROM.$touch()" :ref="'ref_min_from' + full_pair.minFROM"/>
                <p class="error-message" v-if="amountFromValidationError">{{ amountFromValidationError }}</p>
                <p class="error-message" v-if="!$v.amount_FROM.required">{{ $t("exchange_step_1_attention_13") }}</p>
                <p class="error-message" v-if="!$v.amount_FROM.amount_validator">{{ $t("exchange_step_1_attention_14") }}</p>
                <p class="error-message" v-if="!$v.amount_FROM.between">{{ $t("exchange_step_1_attention_15") }} {{ full_pair.minFROM }} {{ $t("exchange_step_1_attention_16") }} {{ full_pair.maxFROM }}</p> 
                <!-- <p class="error-message" v-if="!$v.amount_FROM.minValue">Min amount!</p> -->
                <!-- <p class="error-message" v-if="!$v.amount_FROM.maxValue">Max amount!</p> -->
              </div>
              <div class="min_max">
                <h6>Min. {{ full_pair.minFROM }} {{ full_pair.from_pair }}</h6>
                <h6>Max. {{ full_pair.maxFROM }} {{ full_pair.from_pair }}</h6>
              </div>
            </div>
            <div class="typeFromTo icons mb2" v-if="full_pair.to_pair">
              <h5>{{ $t("exchange_step_1_attention_17") }}</h5>
              <div class="it_input">
                <!-- DYNAMIC IMG https://stackoverflow.com/questions/40491506/vue-js-dynamic-images-not-working -->
                <!-- <img :src="require(`@/assets/currency_icons/png/${full_pair.to_pair}.png`)" alt="" /> -->
                <img :src="require(`@/assets/currency_icons/png/cash.png`)" alt="" />
                <input placeholder="0.004" name="" id="" v-model="$v.amount_TO.$model" @keyup="calcInput_2(amount_TO)"
                  @input="$v.amount_TO.$touch()" />
                <p class="error-message" v-if="amountToValidationError">{{ amountToValidationError }}</p>
                <p class="error-message" v-if="!$v.amount_TO.required">{{ $t("exchange_step_1_attention_18") }}</p>
                <p class="error-message" v-if="!$v.amount_TO.amount_validator">{{ $t("exchange_step_1_attention_19") }}</p>
                <p class="error-message" v-if="!$v.amount_TO.between">{{ $t("exchange_step_1_attention_20") }} {{ full_pair.minTO }} {{ $t("exchange_step_1_attention_21") }} {{ full_pair.maxTO }}</p> 
                <!-- <p class="error-message" v-if="!$v.amount_TO.min_amount_TO">Min amount!</p> -->
                <!-- <p class="error-message" v-if="!$v.amount_TO.max_amount_TO">Max amount!</p> -->
              </div>
              <div class="min_max">
                <h6>Min. {{ full_pair.minTO }} {{ full_pair.to_pair }}</h6>
                <h6>Max. {{ full_pair.maxTO }} {{ full_pair.to_pair }}</h6>
              </div>
            </div>
            <div class="typeFromTo mb2">
              <h5>{{ $t("exchange_step_1_address") }}</h5>
              <input placeholder="Address of Wallet" name="" id="" v-model="$v.address_wallet.$model"
                @input="$v.address_wallet.$touch()" />
              <p class="error-message" v-if="!$v.address_wallet.required">{{ $t("exchange_step_1_wallet") }}</p>
              <p class="error-message" v-if="!$v.address_wallet.address_validator">{{ $t("exchange_step_1_wallet_invalid") }}</p>
            </div>
            <div class="typeFromTo mb2">
              <h5>MEMO
                <input
                    type="checkbox"
                    v-model="memo_to"
                    @change="onMemoSwitch(memo_to)"
                    class="memo-check-box"
                />
              </h5>
              <input v-if="memo_to" placeholder="MEMO of Wallet" name="" id="" v-model="$v.memo_client.$model"
                @input="$v.memo_client.$touch()" />
              <p class="error-message" v-if="!$v.memo_client.required">{{ $t("exchange_step_1_MEMO_required") }}</p>
            </div>

            <div class="typeFromTo mb2" v-if="is_fiat">
              <h5>{{ $t("exchange_step_1_last_name") }}</h5>
              <input placeholder="Last Name" name="" id="" v-model="$v.client_last_name.$model"
                @input="$v.client_last_name.$touch()" />
              <p class="error-message" v-if="!$v.client_last_name.required">{{ $t("exchange_step_1_last_name_required") }}</p>
            </div>
            <div class="typeFromTo mb2" v-if="is_fiat">
              <h5>{{ $t("exchange_step_1_first_name") }}</h5>
              <input placeholder="First Name" name="" id="" v-model="$v.client_first_name.$model"
                @input="$v.client_first_name.$touch()" />
              <p class="error-message" v-if="!$v.client_first_name.required">{{ $t("exchange_step_1_first_name_required") }}</p>
            </div>
            <div class="typeFromTo mb2" v-if="is_fiat">
              <h5>{{ $t("exchange_step_1_phone") }}</h5>
              <input placeholder="Phone Number" name="" id="" v-model="$v.client_phone.$model"
                @input="$v.client_phone.$touch()" />
              <p class="error-message" v-if="!$v.client_phone.required">{{ $t("exchange_step_1_phone_required") }}</p>
            </div>
            
            <div class="typeFromTo mb4">
              <h5>{{ $t("exchange_step_1_email") }}</h5>
              <input placeholder="test@gmail.com" type="email" name="" id="" v-model="$v.email.$model"
                @input="$v.email.$touch()" />
              <p class="error-message" v-if="!$v.email.required">{{ $t("exchange_step_1_email_required") }}</p>
              <p class="error-message" v-if="!$v.email.email_validator">{{ $t("exchange_step_1_email_invalid") }}</p>
            </div>

            <button class="btn_step1 mb5" @click="next">{{ $t("exchange_step_1_btn_next") }}</button>
            <p class="btn_step1 mb6 error-message" v-if="validationError">
              {{ validationError_message }}
            </p>
            <br />
            <br />
            <div class="rules">
              <h4 class="weight900 mb3">
                {{ $t("exchange_step_1_exchange_eth_title") }}
              </h4>
              <p class="mb1">
                {{ $t("exchange_step_1_exchange_eth_description_1") }}
              </p>
              <ul class="mb2">
                <li>
                  {{ $t("exchange_step_1_exchange_eth_description_1_1") }}
                </li>
                <li>
                  {{ $t("exchange_step_1_exchange_eth_description_1_2") }}
                </li>
                <li>
                  {{ $t("exchange_step_1_exchange_eth_description_1_3") }}
                </li>
                <li>
                  {{ $t("exchange_step_1_exchange_eth_description_1_4") }}
                </li>
              </ul>
              <p class="mb3 warm_p">
                <i class="fas fa-exclamation"></i> {{ $t("exchange_step_1_exchange_eth_description_2_1") }}
              </p>
              <p class="warm_p">
                <i class="fas fa-exclamation"></i> {{ $t("exchange_step_1_exchange_eth_description_2_2") }}
              </p>
            </div>
          </div>
          <div class="mh300 enteringValues mb5" v-if="tradesEnabled == false">
            <div class="attention attention_tradesEnabled">
              <i class="fas fa-exclamation"></i>
              <p class="tradesEnabled">
                <span class="weight900">{{ $t("exchange_step_1_values") }} </span>
                <br />
                <br />
                <span class="weight900">{{ $t("exchange_step_1_later") }}</span>
              </p>
            </div>
          </div>
          <div class="mh300 enteringValues mb5" v-if="errorKYC == true">
            <div class="attention attention_tradesEnabled">
              <i class="fas fa-exclamation"></i>
              <div v-if="errorKYC_massege">
                <p class="tradesEnabled">
                  <span class="weight900">{{ $t("exchange_step_1_kyc_missing") }}</span>
                  <br />
                  <span class="weight900">{{ errorKYC_massege }}</span>
                </p>
              </div>
            </div>
          </div>
          <div class="mh300 enteringValues mb5" v-if="validationError == true">
            <div class="attention attention_tradesEnabled">
              <i class="fas fa-exclamation"></i>
              <div v-if="validationError_message">
                <p class="tradesEnabled">
                  <span class="weight900">{{ $t("exchange_step_1_error") }}</span>
                  <br />
                  <span class="weight900">{{ validationError_message }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-alert-modal" v-if="errorType1">
      <div class="alert-modal">
        <div class="modal-kyc">
          <!-- <div class="header">
            <span>&times;</span>
          </div> -->
          <div class="body">
            <h2>{{ $t("exchange_step_1_alert") }}</h2>
            <h2>{{ $t("exchange_step_1_alert_authorized") }}</h2>
            <p class="title-explain">{{ $t("exchange_step_1_alert_authorized_proceed") }}</p>
            <p>- {{ $t("exchange_step_1_alert_authorized_1_1") }}</p>
            <p>- {{ $t("exchange_step_1_alert_authorized_1_2") }}</p>
            <p>-  {{ $t("exchange_step_1_alert_authorized_1_3") }}</p>
            <p>- {{ $t("exchange_step_1_alert_authorized_1_4") }}</p>
          </div>
          <div class="btn-modal">
            <button @click="errorType1 = !errorType1">{{ $t("exchange_step_1_alert_authorized_1_5") }}</button>
            <!-- Увійти -->
            <button @click="goAuth">{{ $t("exchange_step_1_alert_authorized_1_6") }}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="main-alert-modal" v-if="errorType2">
      <div class="alert-modal">
        <div class="modal-kyc">
          <!-- <div class="header">
            <span>&times;</span>
          </div> -->
          <div class="body">
            <h2>{{ $t("exchange_step_1_alert_kyc") }}</h2>
            <h2>{{ $t("exchange_step_1_alert_kyc_proceed") }}</h2>
            <p class="title-explain">{{ $t("exchange_step_1_alert_kyc_proceed_1_1") }}</p>
            <p>- {{ $t("exchange_step_1_alert_kyc_proceed_1_2") }}</p>
            <p>- {{ $t("exchange_step_1_alert_kyc_proceed_1_3") }}</p>
            <p>- {{ $t("exchange_step_1_alert_kyc_proceed_1_4") }}</p>
          </div>
          <div class="btn-modal">
            <button @click="errorType2 = !errorType2">{{ $t("exchange_step_1_alert_kyc_proceed_1_5") }}</button>
            <!-- Увійти -->
            <button @click="goVerif">{{ $t("exchange_step_1_alert_kyc_proceed_1_6") }}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="main-alert-modal" v-if="errorType3">
      <div class="alert-modal">
        <div class="modal-kyc">
          <!-- <div class="header">
            <span>&times;</span>
          </div> -->
          <div class="body">
            <h2>{{ $t("exchange_step_1_alert_card") }}</h2>
            <h2>{{ $t("exchange_step_1_alert_card_1_1") }}</h2>
            <p class="title-explain">{{ $t("exchange_step_1_alert_card_1_2") }}</p>
            <p>- {{ $t("exchange_step_1_alert_card_1_3") }}</p>
            <p>- {{ $t("exchange_step_1_alert_card_1_4") }}</p>
          </div>
          <div class="btn-modal">
            <button @click="errorType3 = !errorType3">{{ $t("exchange_step_1_alert_card_1_5") }}</button>
            <!-- Увійти -->
            <button @click="goVerif">{{ $t("exchange_step_1_alert_card_1_6") }}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="main-alert-modal" v-if="errorType4">
      <div class="alert-modal">
        <div class="modal-kyc">
          <!-- <div class="header">
            <span>&times;</span>
          </div> -->
          <div class="body">
            <h2>{{ $t("exchange_step_1_alert_card_confirm") }}</h2>
            <h2>{{ $t("exchange_step_1_alert_card_confirm_1_1") }}</h2>
            <p class="title-explain">{{ $t("exchange_step_1_alert_card_confirm_1_2") }}</p>
            <p>- {{ $t("exchange_step_1_alert_card_confirm_1_3") }}</p>
            <p>- {{ $t("exchange_step_1_alert_card_confirm_1_4") }}</p>
          </div>
          <div class="btn-modal">
            <button @click="errorType4 = !errorType4">{{ $t("exchange_step_1_alert_card_confirm_1_5") }}</button>
            <!-- Увійти -->
            <button @click="goVerif">{{ $t("exchange_step_1_alert_card_confirm_1_6") }}</button>
          </div>
        </div>
      </div>
    </div>
    <!-- errorType5 -->
    <div class="main-alert-modal" v-if="errorType5">
      <div class="alert-modal">
        <div class="modal-kyc">
          <!-- <div class="header">
            <span>&times;</span>
          </div> -->
          <div class="body">
            <h2>{{ $t("exchange_step_1_sorry") }}</h2>
            <h2>{{ $t("exchange_step_1_error_connecting") }}</h2>
            <h2>{{ $t("exchange_step_1_error_try_later") }}</h2>
          </div>
          <div class="btn-modal">
            <button @click="errorType1 = !errorType1">{{ $t("exchange_step_1_error_close") }}</button>
            <!-- Увійти -->
            <!-- <button @click="goAuth">Log in</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import axios from '../../axios'
import { validationMixin } from 'vuelidate'
import {
  required,
  helpers,
  between,
  requiredIf
  // maxValue
  // minValue
} from 'vuelidate/lib/validators'
const amount_validator = helpers.regex('address_validator', /^\d+(\.\d+)?$/);
const address_validator = helpers.regex('address_validator', /^[a-zA-Z0-9_-]{16,}$/);
// const address_validator = helpers.regex('address_validator', /^[a-zA-Z0-9]{25,}$/);
const email_validator = helpers.regex('email_validator', /^(|(([A-Za-z0-9]+_+)|([A-Za-z0-9]+-+)|([A-Za-z0-9]+\.+)|([A-Za-z0-9]+\++))*[A-Za-z0-9]+@((\w+-+)|(\w+\.))*\w{1,63}\.[a-zA-Z]{2,6})$/);
export default {
  name: "step-one",
  data() {
    return {
      errorType1:false,
      errorType2:false,
      errorType3:false,
      errorType4:false,
      errorType5:false,
      major_error: false,
      full_pair: [],
      currentPrice: null,
      firstInputSelected: true,
      // ______models form
      amount_FROM: 0,
      amountFromValidationError: null,
      amount_TO: 0,
      amountToValidationError: null,
      address_wallet: "",
      addressValidationError: null,
      memo_to:false,
      memo_client:"",
      email: "",
      emailValidationError: null,

      is_fiat:false,
      client_last_name: "",
      client_last_nameValidationError: null,
      client_first_name: "",
      client_first_nameValidationError: null,
      client_phone: "",
      client_phoneValidationError: null,
      
      tradesEnabled: null,
      validationError: null,
      validationError_message: "",
      errorKYC: null,
      errorKYC_massege: "",
      // Info by pair
      selectedPAIR: {
        full_pair: null,
        from_pair: null,
        to_pair: null,
      },
    };
  },
  mixins: [validationMixin],
  computed: {
    stepExchange: {
      set: (payload) => store.commit("setStepExchange", payload),
      get: () => store.getters.getStepExchange,
    },
    start_ex: {
      set: (payload) => store.commit("setManualCancel", payload),
      get: () => store.getters.getManualCancel,
    },
    isAuth: {
      set: (payload) => store.commit("setIsAuth", payload),
      get: () => store.getters.getIsAuth,
    },
    isUsername: {
      set: (payload) => store.commit("setIsUsername", payload),
      get: () => store.getters.getIsUsername,
    },

  },
  validations () {
    return {
      amount_FROM: {
        required,
        amount_validator,
        
        between: between(this.full_pair.minFROM,this.full_pair.maxFROM),
      },
      amount_TO: {
        required,
        amount_validator,
        between: between(this.full_pair.minTO,this.full_pair.maxTO),
      },
      address_wallet: {
        required,
        address_validator
      },
      memo_client:{
        required: requiredIf(function() { return this.memo_to === true;}),
      },
      client_last_name:{
        required: requiredIf(function() { return this.is_fiat === true;}),
      },
      client_first_name:{
        required: requiredIf(function() { return this.is_fiat === true;}),
      },
      client_phone:{
        required: requiredIf(function() { return this.is_fiat === true;}),
      },
      

      email: {
        required,
        email_validator
      },
    }
  },
  mounted() {

    this.stepExchange = 1
    // Здесь забрать пару из УРЛ, перезаписать и отправить на фронт
    // Или же в () хуярнуть УРЛ
    if (this.$route.params.pair) {
      this.pair = this.$route.params.pair
      this.selectedPAIR.full_pair = this.pair
      let ListPair = this.pair.split("_")
      this.selectedPAIR.from_pair = ListPair[0]
      this.selectedPAIR.to_pair = ListPair[1]

      this.post_receiveInfoPair()
      this.currentPrice_pair()
    } else {
      this.start_ex = false;
      this.stepExchange = 1
    }

  },
  watch: {
    tradesEnabled: function (newValue) {
      if (newValue == false) {
        this.major_error = true
      }
    }
  },
  methods: {
    onMemoSwitch(newMemo) {
      if (newMemo) {
        this.memo_client = ""
      } else {
        this.memo_client = "12345"
      }
    },
    goVerif() {
			router.push('/dashboard');
		},
		goAuth() {
			router.push('/auth');
		},
    manualCloseExchange() {
      this.$router.push({ name: "main", path: "/" })
      this.start_ex = false;
      this.stepExchange = 1
    },
    next() {
      if (localStorage.getItem("username")) {
        this.isUsername = localStorage.getItem("username");
      } else {
        this.isUsername = 'Anonim'
      }
      this.$v.$touch();
      if (this.$v.$anyError) {
          return
      }
      let self = this;
      // Проверять
      let data = {
        "isAuth": this.isAuth, // Попозже передавать ІД юзера и будет пушка
        "isUsername": this.isUsername,
        "amount_from": this.amount_FROM,
        "amount_to": this.amount_TO,
        "address": this.address_wallet,
        "email": this.email,
        "memo_client":this.memo_client,
        "client_last_name": this.client_last_name,
        "client_first_name": this.client_first_name,
        "client_phone": this.client_phone,
        "full_pair": this.selectedPAIR.full_pair, // ТУТ НюАНС!!!!!!!
      }
      // DEV > test-exchange/
      // PROD > exchange/
      axios.post("/v1/private/exchange/", data)
        .then(function (resp) {
          if (resp.data.error) {
            if (resp.data.error.type == 3) {
              self.errorKYC_massege = resp.data.error.message
              self.errorKYC = true
              self.major_error = false
            } else if (resp.data.error.type == 2) {
              if (resp.data.error.field == 'amount_from') {
                self.amountFromValidationError = resp.data.error.message
              } else if (resp.data.error.field == 'amount_to') {
                self.amountToValidationError = resp.data.error.message
              }
              // чтобы дополнительно выводить ошибку валидации под формой
              // self.validationError_message = resp.data.error.message
              // self.validationError = true
            }
          } else {
            // ТУТ РЕАЛИЗОВАТЬ ПЕРЕХОД с id_request
            self.$router.push(
                {
                  name: "step-2",
                  path: `/order/${resp.data.id_request}`,
                  params: {
                    'id_request': resp.data.id_request,
                  }
                })
          }
        }).catch(resp => {
          if (resp.data.code == 1){
            self.errorType1 = true
          }
          if (resp.data.code == 2){
            self.errorType2 = true
          }
          if (resp.data.code == 3){
            self.errorType3 = true
          }
          if (resp.data.code == 4){
            self.errorType4 = true
          }
          if (resp.data.code == 2 & resp.data.type=="merchant"){
            self.errorType5 = true
          }
          // self.major_error = true
          self.ValidationError = resp.data['Exception']
        })
    },
    calcInput_1: function (value) {
      const pattern = /^[\d]+([.,][\d]+)?$/;
      if (!pattern.test(value)) {
        this.amountFromValidationError =
          "Invalid amount format! It should contain at least 1 number.";
      } else {
        this.amountFromValidationError = null;
      }
      this.firstInputSelected = true;
      this.calculate(value);
    },
    calcInput_2: function (value) {
      const pattern = /^[\d]+([.,][\d]+)?$/;
      if (!pattern.test(value)) {
        this.amountToValidationError =
          "Invalid amount format! It should contain at least 1 number.";
      } else {
        this.amountToValidationError = null;
      }
      this.firstInputSelected = false;
      this.calculate(value);
    },
    calculate: function (value) {
      if (isNaN(value)) {
        this.amount_FROM = 0;
        this.amount_TO = 0;
        return;
      }

      if (this.firstInputSelected) {
        this.amount_FROM = value;
        this.amount_TO = (value * this.currentPrice).toFixed(6);
      } else {
        this.amount_TO = value;
        this.amount_FROM = (value / this.currentPrice).toFixed(6);
      }
    },
    // REQUESTS
    post_receiveInfoPair() {
      let self = this;
      axios.post("/v1/coinlist/", this.selectedPAIR)
        .then(function (resp) {
          self.full_pair = resp.data
          self.min_amount_FROM=resp.data.minFROM
          self.memo_to=resp.data.aboutTO.is_memo
          self.is_fiat = resp.data.is_fiat
        })
    },
    currentPrice_pair() {
      let self = this;
      let full_pair = self.selectedPAIR.full_pair

      axios.get("/v1/coinlist/", {
        params: {
          type_pair: 2,
          full_pair: full_pair
        }
      })
        .then(function (resp) {
          self.tradesEnabled = resp.data.tradesEnabled
          self.currentPrice = resp.data.last_price;
        })
    },
    validateAddress() {
      const pattern = /^[a-zA-Z0-9]{25,}$/;
      if (!pattern.test(this.address_wallet)) {
        this.addressValidationError =
          "Invalid address format! It should contain at least 25 alphanumeric characters.";
      } else {
        this.addressValidationError = null;
      }
    },
    validateEmail() {
      const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!pattern.test(this.email)) {
        this.emailValidationError =
          "Invalid email address format!";
      } else {
        this.emailValidationError = null;
      }
    },
  },
};
</script>

<style>
.error-message {
  margin-top: 5px;
  color: #ea0a0a;
  font-size: 18px;
}

.tradesEnabled {
  text-transform: uppercase;
  text-align: center;
  font-size: 32px;
  line-height: 42px;
}

.attention_tradesEnabled {
  border-top: 1px solid #fff;
  padding-top: 25px;
}

.enteringValues button {
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #ffffff;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #2a3587;
  padding: 0 50px;
  height: 40px;
  margin: 0 auto;
}

.enteringValues button:hover {
  background: #2a3587;
  border: 1px solid #ffffff;
  color: #fff;
}

.close_button_of_form {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.typeFromTo {
  width: 100%;
}


.typeFromTo h5 {
  margin: 10px 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
}

.typeFromTo input {
  background-size: 30px;
  /* background-position: 98% center; */
  height: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  padding: 0 0 0 2%;
  color: #fff !important;
  border: none;
  background-color: rgba(127, 179, 238, 0.5);
  box-shadow: inset -1.98073px -1.98073px 1.98073px rgba(255, 255, 255, 0.5),
    inset 1.98073px 1.98073px 1.98073px rgba(52, 65, 166, 0.5);
  border-radius: 0.990366px;
}

.it_input {
  position: relative;
}

.it_input img {
  width: 30px;
  position: absolute;
  right: 0;
  top: 5px;
}

.typeFromTo input::placeholder {
  color: #fff;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
}

.typeFromTo input:focus {
  outline: none;
}

.typeFromTo input {
  width: 100%;
}

.min_max {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.min_max h6 {
  margin: 5px 0;
}

.memo-check-box {
  display: inline-block;
  width: 1rem !important;
  height: 1rem !important;
}

/* Attention */
.attention {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 25px 0;
  padding-bottom: 25px;
  border-bottom: 1px solid #fff;
}

.attention i {
  font-size: 60px;
  margin-right: 35px;
}

/* rules */
.rules {
  margin: 25px 0;
  padding-top: 25px;
  border-top: 1px solid #fff;
  display: flex;
  flex-direction: column;
}

.rules ul {
  list-style-type: disc;
  padding-left: 20px;
}

.rules ul li {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 10px;
}

.warm_p i {
  margin-right: 15px;
}

.warm_p {
  font-size: 14px;
  line-height: 18px;
}

@media (min-width: 1200px) and (max-width: 1440px) {}

@media (min-width: 992px) and (max-width: 1199px) {}

@media (min-width: 768px) and (max-width: 991px) {}

@media (min-width: 480px) and (max-width: 767px) {}

@media (max-width: 479px) {
  .error-message {
    font-size: 14px;
}
}
</style>