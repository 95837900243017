export const itemsList = [
    {
        sectionNumber: 1,
        sectionTitle: 'General Provisions',
        items: [
            {   
                title: 1.1,
                number: "1_1"
            },
            {   
                title: 1.2,
                number: "1_2"
            },
            {
                title: 1.3,
                number: "1_3"

            },
            {
                title: 1.4,
                number: "1_4"
            },
            {
                title: 1.5,
                number: "1_5"
            },
            {
                title: 1.6,
                number: "1_6"
            }
        ]
    },
    {
        sectionNumber: 2,
        sectionTitle: "Using of Service",
        items: [
            {
                title: 2.1,
                number: "2_1"
            },
            {
                title: 2.2,
                number: "2_2"
            },
            {
                title: 2.3,
                number: "2_3"
            },
            {
                title: 2.4,
                number: "2_4"
            },
            {
                title: 2.5,
                number: "2_5",
                children: [
                    {
                        title: "●",
                        number: "2_5_1"
                    },
                    {
                        title: "●",
                        number: "2_5_2"
                    },
                    {
                        title: "●",
                        number: "2_5_3"
                    }
                ],
                secondPart: {
                    title: "",
                    number: "2_5_4"
                }
            },
            {
                title: 2.6,
                number: "2_6"
            },
            {
                title: 2.7,
                number: "2_7"
            },
            {
                title: 2.8,
                number: "2_8"
            },
            {
                title: 2.9,
                number: "2_9"
            },
            {
                title: "2.10",
                number: "2_10"
            },
            {
                title: 2.11,
                number: "2_11"
            },
            {
                title: 2.12,
                number: "2_12"
            },
            {
                title: 2.13,
                number: "2_13"
            },
            {
                title: 2.14,
                number: "2_14"
            },
            {
                title: 2.15,
                number: "2_15"
            },
            {
                title: 2.16,
                number: "2_16"
            },
            {
                title: 2.17,
                number: "2_17"
            },
            {
                title: 2.18,
                number: "2_18"
            },
            {
                title: 2.19,
                number: "2_19"
            },
            {
                title: "2.20",
                number: "2_20"
            },
            {
                title: 2.21,
                number: "2_21"
            },
            {
                title: 2.22,
                number: "2_22"
            },
            {
                title: 2.23,
                number: "2_23"
            },
            {
                title: 2.24,
                number: "2_24"
            },
            {
                title: 2.25,
                number: "2_25"
            },
            {
                title: 2.26,
                number: "2_26"
            }
        ]
    },
    {
        sectionNumber: 3,
        sectionTitle: "Prices and Exchange Rates",
        items: [
            {
                title: 3.1,
                number: "3_1"
            },
            {
                title: 3.2,
                number: "3_2"
            },
            {
                title: 3.3,
                number: "3_3"
            },
            {
                title: 3.4,
                number: "3_4"
            }
        ]
    },
    {
        sectionNumber: 4,
        sectionTitle: "Processing Orders in Violation of the Exchange rules",
        items: [
            {
                title: 4.1,
                number: "4_1"
            },
            {
                title: 4.2,
                number: "4_2",
                secondPart: {
                    title: "",
                    number: "4_2_1"
                }
            },
            {
                title: 4.3,
                number: "4_3",
                secondPart: {
                    title: "",
                    number: "4_3_1"
                }
            },
            {
                title: 4.4,
                number: "4_4",
                secondPart: {
                    title: "",
                    number: "4_4_1"
                }
            },
            {
                title: 4.5,
                number: "4_5",
                secondPart: {
                    title: "",
                    number: "4_5_1"
                }
            },
            {
                title: 4.6,
                number: "4_6",
                secondPart: {
                    title: "",
                    number: "4_6_1"
                }
            },
            {
                title: 4.7,
                number: "4_7",
                secondPart: {
                    title: "",
                    number: "4_7_1"
                }
            },
            {
                title: 4.8,
                number: "4_8",
                children: [
                    {
                        title: "4.8.1",
                        number: "4_8_1"
                    },
                    {
                        title: "4.8.2",
                        number: "4_8_2"
                    }
                ]
            },
            {
                title: 4.9,
                number: "4_9",
                children: [
                    {
                        title: "4.9.1",
                        number: "4_9_1"
                    },
                    {
                        title: "4.9.2",
                        number: "4_9_2"
                    },
                    {
                        title: "4.9.3",
                        number: "4_9_3"
                    },
                    {
                        title: "4.9.4",
                        number: "4_9_4"
                    },
                    {
                        title: "4.9.5",
                        number: "4_9_5"
                    }
                ]
            },
            {
                title: 4.10,
                number: "4_10",
                secondPart: {
                    title: "",
                    number: "4_10_1"
                }
            },
            {
                title: 4.11,
                number: "4_11",
                children: [
                    {
                        title: "4.11.1",
                        number: "4_11_1"
                    },
                    {
                        title: "4.11.2",
                        number: "4_11_2"
                    }
                ]
            }
        ]
    },
    {
        sectionNumber: 5,
        sectionTitle: "Prohibited Jurisdictions",
        items: [
            {
                title: 5.1,
                number: "5_1"
            },
            {
                title: 5.2,
                number: "5_2"
            },
            {
                title: 5.3,
                number: "5_3"
            },
            {
                title: 5.4,
                number: "5_4"
            }
        ]
    },
    {
        sectionNumber: 6,
        sectionTitle: "Prohibited Uses",
        items: [
            {
                title: 6.1,
                number: "6_1",
                children: [
                    {
                        title: "●",
                        number: "6_1_1"
                    },
                    {
                        title: "●",
                        number: "6_1_2"
                    },
                    {
                        title: "●",
                        number: "6_1_3"
                    },
                    {
                        title: "●",
                        number: "6_1_4"
                    },
                    {
                        title: "●",
                        number: "6_1_5"
                    },
                    {
                        title: "●",
                        number: "6_1_6"
                    },
                    {
                        title: "●",
                        number: "6_1_7"
                    },
                    {
                        title: "●",
                        number: "6_1_8"
                    },
                    {
                        title: "●",
                        number: "6_1_9"
                    },
                    {
                        title: "●",
                        number: "6_1_10"
                    },
                    {
                        title: "●",
                        number: "6_1_11"
                    },
                ]
            },
            {
                title: 6.2,
                number: "6_2"
            },
            {
                title: 6.3,
                number: "6_3"
            },
            {
                title: 6.4,
                number: "6_4"
            }
        ]
    },
    {
        sectionNumber: 7,
        sectionTitle: "AML Procedure",
        items: [
            {
                title: 7.1,
                number: "7_1"
            },
            {
                title: 7.2,
                number: "7_2"
            },
            {
                title: 7.3,
                number: "7_3"
            },
            {
                title: 7.4,
                number: "7_4"
            },
            {
                title: 7.5,
                number: "7_5"
            },
            {
                title: 7.6,
                number: "7_6"
            },
            {
                title: 7.7,
                number: "7_7"
            },
            {
                title: 7.8,
                number: "7_8"
            },
            {
                title: 7.9,
                number: "7_9"
            },
        ]
    },
    {
        sectionNumber: 8,
        sectionTitle: "Personal Data",
        items: [
            {
                title: 8.1,
                number: "8_1"
            },
            {
                title: 8.2,
                number: "8_2"
            },
            {
                title: 8.3,
                number: "8_3"
            },
            {
                title: 8.4,
                number: "8_4"
            },
            {
                title: 8.5,
                number: "8_5"
            }
        ]
    },
    {
        sectionNumber: 9,
        sectionTitle: "Accuracy, Completeness and Timeliness of Information",
        items: [
            {
                title: 9.1,
                number: "9_1"
            },
            {
                title: 9.2,
                number: "9_2"
            }
        ]
    },
    {
        sectionNumber: 10,
        sectionTitle: "User Comments, Feedback and Other Submissions",
        items: [
            {
                title: 10.1,
                number: "10_1"
            }
        ]
    },
    {
        sectionNumber: 11,
        sectionTitle: "Disclaimer Of Warranties",
        items: [
            {
                title: 11.1,
                number: "11_1"
            },
            {
                title: 11.2,
                number: "11_2"
            },
            {
                title: 11.3,
                number: "11_3"
            },
            {
                title: 11.4,
                number: "11_4"
            },
            {
                title: 11.5,
                number: "11_5"
            }
        ]
    },
    {
        sectionNumber: 12,
        sectionTitle: "Limitation Of Liability",
        items: [
            {
                title: 12.1,
                number: "12_1"
            },
            {
                title: 12.2,
                number: "12_2"
            },
            {
                title: 12.3,
                number: "12_3",
                children: [
                    {
                        title: "●",
                        number: "12_3_1"
                    },
                    {
                        title: "●",
                        number: "12_3_2"
                    },
                    {
                        title: "●",
                        number: "12_3_3"
                    },
                    {
                        title: "●",
                        number: "12_3_4"
                    }
                ]
            },
            {
                title: 12.4,
                number: "12_4"
            },
            {
                title: 12.5,
                number: "12_5"
            }
        ]
    },
    {
        sectionNumber: 13,
        sectionTitle: "Entire Agreement",
        items: [
            {
                title: 13.1,
                number: "13_1"
            },
            {
                title: 13.2,
                number: "13_2"
            },
            {
                title: 13.3,
                number: "13_3"
            }
        ]
    },
    {
        sectionNumber: 14,
        sectionTitle: "Tax",
        items: [
            {
                title: 14.1,
                number: "14_1"
            },
            {
                title: 14.2,
                number: "14_2"
            },
            {
                title: 14.3,
                number: "14_3"
            },
            {
                title: 14.4,
                number: "14_4"
            }
        ]
    },
    {
        sectionNumber: 15,
        sectionTitle: "Changes to Terms of Service",
        items: [
            {
                title: 15.1,
                number: "15_1"
            },
            {
                title: 15.2,
                number: "15_2"
            }
        ]
    },
    {
        sectionNumber: 16,
        sectionTitle: "Risk",
        items: [
            {
                title: 16.1,
                number: "16_1"
            },
            {
                title: 16.2,
                number: "16_2"
            },
            {
                title: 16.3,
                number: "16_3"
            }
        ]
    },
    {
        sectionNumber: 17,
        sectionTitle: "Governing Law",
        items: [
            {
                title: 17.1,
                number: "17_1"
            },
            {
                title: 17.2,
                number: "17_2"
            },
            {
                title: 17.3,
                number: "17_3"
            },
            {
                title: 17.4,
                number: "17_4"
            },
            {
                title: 17.5,
                number: "17_5"
            }
        ]
    },
    {
        sectionNumber: 18,
        sectionTitle: "Changes To Terms Of Service",
        items: [
            {
                title: 18.1,
                number: "18_1",
                children: [
                    {
                        title: "●",
                        number: "18_1_1"
                    },
                    {
                        title: "●",
                        number: "18_1_2"
                    },
                    {
                        title: "●",
                        number: "18_1_3"
                    },
                    {
                        title: "●",
                        number: "18_1_4"
                    }
                ]
            }
        ]
    },
    {
        sectionNumber: 19,
        sectionTitle: "Contact Information",
        items: [
            {
                title: 19.1,
                number: "19_1"
            },
            {
                title: 19.2,
                number: "19_2"
            },
            {
                title: 19.3,
                number: "19_3"
            },
            {
                title: 19.4,
                number: "19_4"
            }
        ]
    }
]