<script setup>
import {ref, defineExpose, reactive, toRefs} from 'vue';
import supportIcon from "@/assets/support-icon.png";

const opened = ref(false);
const pending = ref(false);
const sent = ref(false);

const form = reactive({
  exchangeId: '',
  username: '',
  message: '',
  error: ''
});


function open() {
  opened.value = true;
}

function close() {
  opened.value = false;
}

function toggle() {
  opened.value = !opened.value;
}

const admins = [176583087]
const botToken = "7322283630:AAFqv7UEcTI3ffejFOD6jVIf3lOpt9GuYIU"

function formMessage (exchangeId, username, message) {
  return `Exchange ID: ${exchangeId}\nUsername: ${username}\nMessage: ${message}`
}

async function send () {
  try {
    checkFormValidity()
  } catch (e) {
    form.error = e.message;
    throw e;
  }

  pending.value = true;
  for (const admin of admins) {
    try {
      const response = await sendMessage2Bot(admin, botToken, formMessage(form.exchangeId, form.username, form.message));
      if (!response.ok) throw new Error();
    } catch (e) {
      console.error(e);
      form.error = "An error occurred during message sending";
    }
    finally {
      pending.value = false;
    }
  }

  form.exchangeId = "";
  form.username = "";
  form.message = "";

  sent.value = true;
  close();
}

async function sendMessage2Bot(chatId, botToken, message) {
  const url = `https://api.telegram.org/bot${botToken}/sendMessage`;
  const params = new URLSearchParams({
    chat_id: chatId,
    text: message
  });
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: params.toString()
  });
}

function submitDeliveryResult () {
  sent.value = false;
}

function onFormChange () {
  if (form.error) form.error = ""
}

function checkFormValidity () {
  if (!form.exchangeId) throw new Error('Exchange ID is required');
  if (!form.username) throw new Error("Username is required");
  if (!form.message) throw new Error("Message is required");
}

defineExpose({
  ...toRefs(form), supportIcon, open, close, toggle, send, onFormChange, submitDeliveryResult, pending
})
</script>

<template>
  <div class="support-wrapper" v-if="!pending">
    <form v-if="opened" class="support-form" @input="onFormChange" @submit.prevent="send">
      <div class="support-form__header">
        Contact us
        <p class="support-form__close" @click="close">-</p>
      </div>
      <div class="support-form__body">
        <label for="exchangeId">Exchange</label>
        <input
            type="text"
            id="exchangeId"
            v-model="exchangeId"
            placeholder="Enter exchange id"
            class="support-form__input base-input"
        />

        <label for="telegramUsername">Telegram Username</label>
        <input
            type="text"
            id="telegramUsername"
            v-model="username"
            placeholder="Enter your contact username"
            class="support-form__input base-input"
        />

        <label for="message">Message</label>
        <textarea
            type="text"
            id="message"
            v-model="message"
            placeholder="Describe your problem"
            class="support-form__input support-form__input_message base-input"
        />
        <p v-if="form.error" v-text="form.error" class="support-form__error"></p>
      </div>
      <div class="support-form_footer">
        <button type="submit" class="support-btn support-btn_submit">Send</button>
      </div>
    </form>
    <div v-else-if="sent" class="support-form">
      <div class="support-form__header">Message was successfully sent</div>
      <button @click="submitDeliveryResult"  type="button" class="support-btn support-btn_submit support-btn_submit-delivery">OK</button>
    </div>
    <button v-else @disabled="form.error" @click="open" class="support-btn support-btn_open">
      <i :style="{ backgroundImage: `url(${supportIcon})` }" class="support-icon"></i>
    </button>
  </div>
</template>

<style scoped>
  .support-wrapper {
    position: fixed;
    bottom: 5rem;
    right: 5rem;
  }

  .support-form {
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    padding: 2rem;
    box-sizing: border-box;

    label {
      color: white;
      font-size: 1.5rem;
      display: block;
      margin-bottom: 0.5rem;
    }

    input + label {
      margin-top: 2rem;
    }
  }

  .support-form__close {
    cursor: pointer;
  }

  .support-form__header {
    position: relative;
    font-size: 2rem;
    font-weight: 700;
    color: white;
    text-align: left;
    display: flex;
    justify-content: space-between;
  }

  .support-form__input_message {
    min-height: 100px;
    padding: 1rem 0.3rem;
    width: 300px;
    resize: none;
  }

  .support-form__body {
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .support-btn {
    border: solid 1px #fff;
    color: #2a3587;
    background: #fff;
    opacity: 0.6;
    font-size: 2rem;
    cursor: pointer;
    padding: 0.2rem 1rem;
  }

  .support-btn_submit {
    display: block;
    margin-left: auto;

    &[disabled] {
      cursor: initial;
      opacity: 0.4;
    }
  }

  .support-btn_open {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .support-icon {
    display: block;
    width: 2rem;
    height: 2rem;
    background-size: 100% 100%;
  }

  .support-form__error {
    color: red;
    padding: 1rem 0 0;
  }

  .support-btn_submit-delivery {
    margin-top: 1.5rem;
  }

</style>
