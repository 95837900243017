import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);
// синтаксис в компоненте - {{ $t("logIn") }}
const messages = {
	en: {
		// Header
		logIn: "Log in",
		logOut: "Log out",
		Profile: "Profile",
		Close: "Close",
		Menu: "Menu",
		Main: "Main",
		Terms: "Terms",
		News: "News",
		Reviews: "Reviews",
		Contacts: "Contacts",
		// Титульна сторінка
		main_page_header:"Hasty crypto exchange",
		main_page_exchange_button:"Exchange",
		main_page_send_section_header:"SEND",
		main_page_receive_section_header:"RECEIVE",
		main_page_input_placeholder:"Enter coin name",
		main_page_modal_kyc_exchange:"Exchange",
		main_page_modal_kyc_alert:"Alert!",
		main_page_modal_kyc_to_proceed_condition:"To proceed with fiat currency exchange:",
		main_page_modal_kyc_to_proceed_condition_1:"Complete KYC (Know Your Customer)",
		main_page_modal_kyc_to_proceed_condition_2:"Verify bank card",
		main_page_modal_kyc_to_proceed_condition_3:"You can complete KYC and track its status in your personal account.",
		main_page_modal_kyc_button_cancel:"Cancel",
		main_page_modal_kyc_button_kyc_pass:"Pass KYC",
		main_page_modal_fiat_alert:"Alert!",
		main_page_modal_fiat_to_proceed_condition:"To proceed with fiat currency exchange:",
		main_page_modal_fiat_to_proceed_condition_1:"Complete KYC (Know Your Customer)",
		main_page_modal_fiat_button_cancel:"Cancel",
		main_page_modal_fiat_button_login:"Log in",
		main_page_modal_error:"Alert!",
		main_page_modal_error_try_later:"Try again later or choose other ticker!",
		main_page_modal_error_close:"Close",
		main_page_modal_startEx_exchange:"Exchange",
		main_page_modal_startEx_to:"to",
		main_page_modal_startEx_data:"Data input",
		main_page_modal_startEx_payment:"Payment",
		main_page_modal_startEx_status:"Status",
		main_page_modal_startEx_completion:"Completion",
		main_page_modal_startEx_true_exchange:"Exchange",
		main_page_modal_startEx_true_to:"to",
		main_page_modal_startEx_true_data:"Data input",
		main_page_modal_startEx_true_payment:"Payment",

		// Форма обмена
		form_header_Exchange:"Exchange",
		form_header_to:"to",
		form_header_DateInput:"Data input",
		form_header_Payment:"Payment",
		form_header_Completion:"Completion",
		form_header_Date:"Date",
		form_header_Copy:"Copy",
		form_header_RemainingTime:"Remaining time",
		form_body_STATUS:"STATUS",
		form_body_STEP:"STEP",
		form_body_MESSAGE:"MESSAGE",
		form_footer_updated:"The page is updated automatically every second.",
		form_rule_1_title:"Attention!",
		form_rule_1_body_1:"This address only accepts ",
		form_rule_1_body_2:". Please do not send any other currency to this address. They will not be credited.",
		form_rule_2_title:"A form for exchanging cryptocurrencies",
		form_rule_2_body:"In the cryptocurrency exchange form, you can select the desired cryptocurrency pair and provide the necessary details of the transaction. Simply enter the amount of cryptocurrency you want to exchange and receive the corresponding amount of another currency in return.",
		form_rule_3_title:"Current exchange rates.",
		form_rule_3_body:"Here you will also find current exchange rates for various cryptocurrency pairs. Our rates are updated in real time so that you can always make the best possible deal.",
		form_rule_4_title:"Instructions on the exchange procedure.",
		form_rule_4_body:"To help you complete a successful exchange, we provide detailed instructions on the exchange procedure. You will be able to familiarize yourself with all the steps of the operation step by step and confidently complete your exchange without any problems.",
		form_rule_5_body:'Exchange your cryptocurrency assets now and take advantage of our advantages to get the best conditions on the cryptocurrency exchange market!',
		// Этапы обмена
		txt_stage_0:"Creating requisites",
		txt_stage_1:"Deposit pending",
		txt_stage_2:"Payment processing",
		txt_stage_3:"Exchange initiated",
		txt_stage_4:"Exchange completed",
		txt_stage_5:"Payment formation",
		txt_stage_6:"Payout",
		// kyc_dashboard
		kyc_dashboard_title_h3:"KYC Verification",
		kyc_dashboard_status_title:"KYC verification status:",
		kyc_dashboard_status_1:"On inspection!",
		kyc_dashboard_status_2:"Confirmed!",
		kyc_dashboard_status_3:"Refused!",
		kyc_dashboard_status_1_explain1:"Wait for confirmation. The process takes from 30 minutes to 4 hours.",
		kyc_dashboard_status_2_explain2:"Your data has been checked and confirmed, now you can use the exchange for fiat funds!",
		kyc_dashboard_status_3_explain3:"Unfortunately, you have been refused! Please contact us at ",
		kyc_dashboard_rule_h3:"Mandatory for exchange  with fiat currencies!",
		kyc_dashboard_rule_h5:"You need to provide:",
		kyc_dashboard_rule_li1_1:"Photo of one of the identity documents:",
		kyc_dashboard_rule_li1_2:"- Passport (first page)",
		kyc_dashboard_rule_li1_3:"- ID card (front side)",
		kyc_dashboard_rule_li1_4:"- Identity document (front side)",
		kyc_dashboard_rule_li2_1:"A photo (selfie) of a person with an identity document.",
		kyc_dashboard_upload_1:"Upload a photo of one of the identity documents",
		kyc_dashboard_upload_2:"Upload a photo (selfie) with an identity document",
		kyc_dashboard_selected_file:"Select file",
		kyc_dashboard_required:"Required!",
		kyc_dashboard_agree_1:"I agree to the processing",
		kyc_dashboard_agree_2:"my personal data.",
		kyc_dashboard_btn_submit:"Submit",
		kyc_dashboard_btn_close: "Close",
		kyc_dashboard_btn_add:"Add",
		// CARD_dashboard
		card_dashboard_title_h3:"Card Verification",
		card_dashboard_title_h3_2:"Mandatory for exchange with fiat currencies!",
		card_dashboard_title_rules:"You need to provide:",
		card_dashboard_li_1_1:"To verify a physical card, you need:",
		card_dashboard_li_1_2:"- Enter the card number in the field",
		card_dashboard_li_1_3:"- Take a picture of the card (front side), the first and last 4 numbers should be visible on the card (see example)",
		card_dashboard_li_2_1:"To verify the virtual card, you need:",
		card_dashboard_li_2_2:"- Enter the card number in the field",
		card_dashboard_li_2_3:"- Screenshot of online banking, where the card is visible (See example)",
		card_dashboard_li_2_4:"- Photo with full name, date, signature and open online banking where the card is visible (See example)",
		card_dashboard_physic_number:"Card number",
		card_dashboard_physic_err_1_1:"Wallet address is required!",
		card_dashboard_physic_err_1_2:"Invalid wallet address format!",
		card_dashboard_physic_title_1:"Upload a photo of physical card",
		card_dashboard_selected_file:"Select file",
		card_dashboard_required:"Required!",
		card_dashboard_agree_1:"I agree to the processing",
		card_dashboard_agree_2:"my personal data.",
		card_dashboard_btn_submit:"Submit",
		card_dashboard_virtual_title_1:"Upload a screanshot of your bank account",
		card_dashboard_virtual_title_2:"Upload a photo with full name, date, signature and open online banking where the card is visible",
		card_dashboard_list_titles_1:"Number Card",
		card_dashboard_list_titles_2:"Type",
		card_dashboard_list_titles_3:"Status",
		card_dashboard_list_titles_1_fake:"Number Card",
		card_dashboard_list_titles_2_fake:"Status",
		card_dashboard_list_status_1_fake:"Not added Card",
		card_dashboard_list_status_2_fake:"None",
		card_dashboard_list_type_1:"Physical",
		card_dashboard_list_type_2:"Virtual",
		card_dashboard_list_status_1:"On inspection!",
		card_dashboard_list_status_2:"Confirmed!",
		card_dashboard_list_status_3:"Refused!",
		card_dashboard_btn_close:"Close",
		card_dashboard_btn_add_physical_card:"Add Physical Card",
		card_dashboard_btn_add_virtual_card:"Add Virtual Card",
		card_dashboard_btn_add_list_card:"List Card",
		// card_dashboard:"",
		// card_dashboard:"",
		// card_dashboard:"",
		// card_dashboard:"",
		// card_dashboard:"",
		// Terms
		terms_title: "Terms",
		terms_explanations: "What are the terms and conditions?",
		terms_body_main: "These rules are considered to be subject to the conditions of the public offer, which enters into force at the time of submission of an application by the Customer and is one of the main components of this agreement. The information about the conditions of application submission specified by the Contractor, is a Public offer. The main part of a public offer are actions made in the completion of the application submission by the Customer showing his exact intentions to make a transaction on the terms proposed by the Contractor before the end of this application.",
		terms_what_are_main_rules: "What are the main rules?",
		terms_list_title_1: "Your reliable assistant requires the following:",
		terms_list_body_1: "The customer must be of legal age.",
		terms_list_title_2: "Your reliable assistant requires the following:",
		terms_list_body_2: "All exchange operations are made only on our website: You make the request via special form, then our support consider it and write (on the website) the requisites for making payment or request any additional information. One exchange - one request. If someone, from our name, offer you the private exchange via telegram, skype, viber, whatsup etc., be assured - it is a scammer, you just lose your money (we know some such unfortunate incidents). ",
		terms_rules_1: "What are ",
		terms_rules_2: "the main rules?",

		//AnswerUAH
		answer_uah_exchnge_success:"Exchange completed successfully",
		answer_uah_data_input:"Data input",
		answer_uah_payment:"Payment",
		answer_uah_completion:"Completion",
		answer_uah_status:"Exchange registered!",
		answer_uah_guide:"Guide:",
		answer_uah_guide_1:"Our manager will contact you in a telegram to continue the exchange.",
		answer_uah_guide_2:"A <b>PIN</b> code was sent to your mail, inform the manager in personal messages, this is required to confirm the exchange.",
		answer_uah_guide_3:"P.S. If the manager has not answered you within 15 minutes, please contact us directly",

		// FromUAH
		from_uah_exchnge_title1:"Exchange",
		from_uah_exchnge_title2:"to",
		form_uah_data_input:"Data input",
		form_uah_payment:"Payment",
		form_uah_completion:"Completion",
		form_uah_attention: "ATTENTION!",
		form_uah_attention_text1: "This operation takes",
		form_uah_attention_text2: "place automatically 24/7",
		form_uah_attention_text3: "and takes up to",
		form_uah_attention_text4: "3 minutes",
		form_uah_attention_text5: "after receiving",
		form_uah_attention_text6: "the first confirmation",
		form_uah_attention_text7: "of the transaction.",
		form_uah_attention_text8: "Course fixing rules",
		form_uah_attention_text9: "In order to counter the legalization of proceeds from crime and the financing of terrorism, AML checks are carried out in accordance with",
		form_uah_attention_text10: "AML/CTF and KYC Policies",
		form_uah_current_price: "CURRENT PRICE",
		form_uah_current_price1: "I give",
		form_uah_current_price2: "Amount is required!",
		form_uah_current_price3: "Invalid amount format!",
		form_uah_current_price4: "Specify the amount either above",
		form_uah_current_price5: "or below",
		form_uah_current_price6: "I get",
		form_uah_current_price7: "Amount is required!",
		form_uah_current_price8: "Invalid amount format!",
		form_uah_current_price9: "Specify the amount either above",
		form_uah_current_price10: "or below",
		form_uah_current_price11: "Address",
		form_uah_current_address_placeholder: "Address of Wallet",
		form_uah_current_address1: "Wallet address is required!",
		form_uah_current_address2: "Invalid wallet address format!",
		form_uah_memo_placeholder: "MEMO of Wallet",
		form_uah_memo_placeholder_required: "MEMO of wallet is required!",
		form_uah_phone_title: "Phone Number",
		form_uah_phone_placeholder: "Phone Number",
		form_uah_phone_placeholder_required: "Phone number is required!",
		form_uah_telegram_title: "Telegram username",
		form_uah_telegram_placeholder: "@username",
		form_uah_telegram_required: "First Name is required!",
		form_uah_email_title: "Email",
		form_uah_email_placeholder: "exmoney@gmail.com",
		form_uah_email_required: "Email is required!",
		form_uah_email_invalid: "Invalid email format!",
		form_uah_next: "Next",
		form_uah_rules_title: "EXCHANGE ETHEREUM ETH TO ADVANCED CASH USD",
		form_uah_rules_steps: "To make an exchange, you should perform several steps:",
		form_uah_rules_step_1_1: "Fill in all fields of the submitted form. Click Exchange.",
		form_uah_rules_step_1_2: "Read the terms of the Agreement for Provision of Exchange Services; if you accept them, put a tick in the appropriate field and click Create Request.",
		form_uah_rules_step_1_3: "Pay an order. Follow the instructions on our website to transfer the required amount.",
		form_uah_rules_step_1_4: "On completion of specified actions, the system will move you to the Request Status page, where the status of your transfer is displayed.",
		form_uah_rules_step_2: "The exchange rate is fixed in request if the Customer pays it within 30 minutes after creation. If payment is not received within 30 minutes, the request is automatically canceled, and to recover the request, the Customer should contact the website technical support. In this case, the amount of payment on the request may be recalculated.",
		form_uah_rules_step_3: "If the Customer has paid the request but due to circumstances wants to refuse the exchange, the refund is minus 3% of the payment amount + commission of the payment system network and exchange rate difference (if exchange rate has changed by more than 3%).",
		form_uah_trades_enabled: "At the moment, the pair is not available for exchange!!",
		form_uah_trades_tryLater: "Try later!",
		form_uah_error: "Error:",
		
		// Portfolio

		// Services

		// About studio

		// Contacts

		contacts_title: "Contacts",
		contacts_subtitle: "Feel free to contact us any time. we will get back to you as soon as we can!",
		contacts_name: "Name",
		contacts_name_required: "Name required",
		contacts_email: "E-mail",
		contacts_email_required: "Email required",
		contacts_message: "Message",
		contacts_message_required: "Message required",
		contacts_message_length: "Message length must be at least 20 characters",
		contacts_send_btn: "SEND",

		// Footer
		footer_main: "Main",
		footer_terms: "Terms",
		footer_news: "News",
		footer_reviews: "Reviews",
		footer_contacts: "Contacts",
		read_more: "Read more",

		// Authentication
		auth_error_email_required: "Email is required",
		auth_error_password_required: "Password is required",
		auth_error_password_min: "Password must be at least 8 characters",
		auth_password_forgot: "Forgot password",
		auth_button_login: "Log in",
		auth_button_register: "Register",

		// Registration
		reg_title: "Create an account",
		reg_create_account_subtitle: "Create your account, it takes less than a minute. If you already have an account",
		reg_login_link: "Login",
		reg_first_name: "First name",
		reg_last_name: "Last name",
		reg_phone: "Phone",
		reg_email: "Email",
		reg_password: "Password",
		reg_confirm_password: "Confirm password",
		reg_promocode: "Promocode",
		reg_button_register: "Register",
		reg_error_first_name_required: "First name is required",
		reg_error_first_name_alpha: "First name must be alphabetic",
		reg_error_last_name_required: "Last name is required",
		reg_error_last_name_alpha: "Last name must be alphabetic",
		reg_error_phone_required: "Phone is required",
		reg_error_phone_numeric: "Phone must be numeric",
		reg_error_email_required: "Email is required",
		reg_error_email_valid: "Please enter a valid email",
		reg_error_password_required: "Password is required",
		reg_error_password_min: "Password must be at least 8 characters",
		reg_error_confirm_password_required: "Confirm password is required",
		reg_error_confirm_password_same: "Passwords must match",

		//erorr page
		error_page_title_pt_1: "Sorry, the service is unavailable.",
		error_page_title_pt_2: "Engineering works",
		error_page_title_pt_3: "All current exchanges will be completed soon, don't worry!",
		error_page_return_btn: "GO HOME",

		//Not Found Page
		not_found_page_title: "PAGE NOT FOUND",
		not_found_page_return_btn: "GO HOME",

		// Forgot password
		forgot_email_required: "Email is required",
		forgot_email_valid: "Invalid email format",
		forgot_button_send: "Send",
		forgot_confirm_message: "The request to change your password has been successfully sent to your email!",

		//Reset Password
		reset_password_title: "Change password",
		reset_placeholder_password: "Enter your new password",
		reset_placeholder_confirm_password: "Confirm your new password",
		reset_success_message: "Your password has been successfully changed!",
		reset_button_change: "Change",
		reset_button_go_home: "Log In",
		reset_button_try_again: "Try again!",
		reset_password_reset_error: "Password change failure!",
		reset_password_reset_error_message: "Details:",

		// Terms Of Service
		terms_disclaimer: "Please read these terms of service (the 'Terms') carefully before using our Service. By accessing or using any part of the site, you agree to be bound by these Terms. If you don’t agree with these Terms or any of its Clauses, you shall immediately cease to use any Exmoney services.",
		terms_title_1: "General Provisions",
		terms_1_1: "These Terms constitute a legal agreement between us ('Exmoney', 'We' 'Us', 'Ourselves') and users, clients, visitors and others (without limitation), who access the Services (as defined below) ('User', 'You', 'Your').",
		terms_1_2: "The Exmoney services ('Service') refers to the services provided to you by Exmoney, which are offered through the Exmoney.com website ('Website'), widgets and mobile applications.",
		terms_1_3: "Exmoney offers an exchange of cryptocurrencies ('Exchange'), based on an order ('Order') that was created by the User in the Service.",
		terms_1_4: "One of the parameters of the Order is the exchange rate type: fixed ('Fixed rate') or floating ('Float rate') type of exchange rate. Fixed rate involves fixing the exchange rate: the User receives the exact amount that was displayed when creating the Exchange. Float rate implies a change in the exchange rate depending on market conditions. The exchange rate is finally set when the User's transaction receives the required number of blockchain network confirmations.",
		terms_1_5: "The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms.",
		terms_1_6: "If the User does not read and accept the Terms of Service, the User should not use or continue using the Services.",
		//Section 2
		terms_title_2: "Service Usage",
		terms_2_1: "You hereby confirm that you are an individual, legal person or other organization with full capacity for civil rights and civil conduct when you use the Service. By agreeing to these Terms, you represent that you are at least the age of majority in your state or province of residence or you have consent from your legal representative or guardian to use the Service.",
		terms_2_2: "You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the Website through which the service is provided, without express written permission by us.",
		terms_2_3: "To make an Exchange, the User must create an Order with a choice of direction, amount, type of exchange and an indication of the User's address, to which the funds should be received during the Exchange, and send funds to a specially generated address (or a combination of an address and a MEMO/Destination tag) on the Order page.",
		terms_2_4: "Only one transaction is allowed per address",
		terms_2_5: "By choosing Fixed rate, you acknowledge and agree that Exmoney will execute Exchange and send the exact amount that was displayed on the order page when it was created, if the following conditions are met:",
		terms_2_5_1: "You sent funds and the transaction got into the mempool or block of the blockchain network within 10 minutes from the moment the order was created.",
		terms_2_5_2: "You sent the exact amount that was displayed on the Order page, which does not include network fees and withdrawal fees.",
		terms_2_5_3: "The market rate from the moment the Order was created to the appearance of the transaction in the mempool of the blockchain network was not changed by more than 1.2%.",
		terms_2_5_4: "Otherwise, the execution of the order with the payment of the exact amount that was displayed on the page of the created order before payment will be impossible. You will be able to make a choice on the order page: continue the order at the new market fixed rate at the time of the choice (when possible) or make a refund minus the network commission.",
		terms_2_6: "By choosing the Float rate, you acknowledge and agree that the exchange rates for the Float rate type are approximate and change over time. The service fee and the network fee for consolidating an incoming transaction, as well as for sending a transaction, are already included in the final displayed rate. The exchange rate is finally set within 10 minutes after your transaction receives the required number of confirmations in the blockchain network.",
		terms_2_7: 'If the User`s transaction does not arrive at the Service address after 30 minutes for an Order with a Float rate and after 10 minutes for an Order with a Fixed rate, the Order automatically switches to the "Order expired" status. If funds are received before 24 hours have passed since the creation of the Order, the User`s transaction is automatically displayed in the Order. The User can choose on the Order page: continue the Order at the current market rate and or make a refund minus the network commission.',
		terms_2_8: "Sending a transaction on an Order after 24 hours has passed since the creation of the Order is a violation of the Exchange rules.",
		terms_2_9: 'The minimum and maximum allowable actual amount for Exchange is always displayed on the main page of the site in the "Send" and "Receive" fields. Sending a transaction on an Order, the amount of which is less than the minimum limit, is prohibited and is a violation of the Exchange rules.<br>When sending a transaction for an Order, the amount of which exceeds the maximum limit of the Service, a form will be available on the Order page for the User to automatically return a part of the amount that exceeds the original amount of the created Order, while the other part of the amount will be exchanged.',
		terms_2_10: "When creating an Order, the User is fully responsible for the correctness of the chosen direction of the Exchange, the specified receiving address, the type of exchange rate and amount, as well as for being aware of the features of the selected currencies and networks. Exmoney is not responsible if the transaction was sent to incorrectly specified details when creating an order. To make sure in the chosen direction and the details entered when creating the Order, the User can specify his email address to receive notifications about the status of the Order, containing all information about the Order, before the direct payment of the Order.",
		terms_2_11: "Automatic refund through the form on the Order page to the addresses of the Service, including the addresses of other Orders, is prohibited and is a violation of the Exchange rules.",
		terms_2_12: "You acknowledge and agree that you must send funds in a single transaction for the Order you have created in the same currency and blockchain network that was selected when creating the Order.",
		terms_2_13: "Sending funds to the address specified in the Order in a currency and/or blockchain network other than that specified in the Order is a violation of the Exchange rules.",
		terms_2_14: "You acknowledge and agree that Orders are executed fully automatically and when the User contacts technical support with a request to suspend the Order, change the Order data or refund the funds that were sent by the User for the Order, Exmoney has the right to refuse this request without giving reasons.",
		terms_2_15: "Suspension of the User's Order in order to change the receiving address or return the funds that were sent by the User under the Order is possible only if, when creating the Order, the User indicated his email address to receive notifications about the status of the order or if the User created the exchange from his registered account. The user must send an email from their email address with information about the required changes. The term for consideration of the request is 24 hours after the Order is suspended and the Exmoney email is received from the user.",
		terms_2_16: "Sending funds after 24 hours after the creation of the Order is a violation of the Exchange rules.",
		terms_2_17: "Creating an Order specifying the address of the Service from another Order is a violation of the Exchange rules.",
		terms_2_18: "Sending funds on the Order in more than one transaction is a violation of the Exchange rules.",
		terms_2_19: "Sending transactions to Exmoney addresses via third-party smart contracts is prohibited and is a violation of the Exchange rules, unless it is expressly allowed on the Order page.",
		terms_2_20: "The possibility of exchanging or refunding funds in case of violation of the Exchange rules is described in section 4.",
		terms_2_21: "An order is accessed via a URL link containing the Order ID and data about the receiving address that was entered when the order was created. If the link to the Order or the Order ID is lost, the User can contact technical support to search for the Order. In this case, the User must provide complete data about his order, namely: the direction of the exchange being made; the transaction by which the funds were sent to the address of the Service; the receiving address that the User entered when creating the Order. If all the necessary data is not provided, the search for the Order will be refused.",
		terms_2_22: "If the User has created an Order and sent a transaction in accordance with the Terms of Use of the Service, but Exmoney cannot perform an Exchange or return for its own technical reasons, the User is offered a solution based on the technical capabilities at the time of the request, which takes no more than 24 hours to complete.",
		terms_2_23: "If the User has created an Order and sent a transaction in accordance with the Terms of Use of the Service, but during the process of processing the Order, unforeseen and independent of Exmoney factors occur that contribute to the non-fulfillment of the Order, the Order completion time in this case is not regulated and depends solely on the elimination of the relevant factors.",
		terms_2_24: "The Service provides non-custodial services, which means that we do not store your funds on deposits and balances.",
		terms_2_25: "The User is solely responsible for the accuracy of the amount of funds sent to the address. If the User sends an amount that is less than the amount specified in the Order, the Exchange will not be executed.",
		terms_2_26: "The User is solely responsible for the accuracy of the amount of funds sent to the address. If the User sends an amount that is greater than the amount specified in the Order, the Exchange will not be executed.",
		terms_title_3: "Prices and Exchange Rates",
		terms_3_1: "Prices for our services are subject to change without notice.",
		terms_3_2: "We shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of the service.",
		terms_3_3: "The Exmoney commissions, as well as all the fees spent by the Service for sending transactions and consolidating the funds received from the User, are already included in the final exchange rate displayed when the exchange is created.",
		terms_3_4: "The number of transaction confirmations required for a transaction to be considered accepted for an Order is different for each of the cryptocurrencies. The required number of transaction confirmations is subject to change at the discretion of Exmoney without notice.",
		terms_title_4: "Processing Orders in Violation of the Exchange rules",
		terms_4_1: "Violation of the Exchange rules makes it impossible to automatically process and fulfill the Order. For each case of violation of the Exchange rules, the User must contact the technical support on the site and register a request for manual processing by technical specialists.",
		terms_4_2: "The processing time for an Order in violation of the Exchange rules is up to 24 hours.",
		terms_4_2_1: "When sending funds for the Order after 24 hours after the creation of the Order, the transaction will be attached to the Order within 24 hours after the registration of the User's request. The User will be able to choose whether to continue the order at the market rate, at the time of attaching the transaction, or to make a refund.",
		terms_4_3: "Sending two or more transactions for one Order",
		terms_4_3_1: "For one Order, only one transaction is processed and double payment for the Order is prohibited. If two or more transactions arrive at the Order address, the Order is still executed only with the first transaction. The exchange of funds for other transactions at the current market rate at the time of manual processing of the Order or the return of funds minus the network commission is carried out within 24 hours after the registration of the User's request.",
		terms_4_4: "Attempt to make a double exchange",
		terms_4_4_1: "If, when creating an Order or when automatically refunding funds, the User indicated the address of the Service from another Order, then when contacting technical support, the User must provide his address in order to receive funds for exchange or return. Sending funds to the User's address is carried out within 24 hours after registration of the User's request. The total amount to be sent will be recalculated taking into account the additional expenses of the Service for the miner fee for sending the transaction and consolidating funds.",
		terms_4_5: "Sending or returning funds by the User to the address of our Service",
		terms_4_5_1: "If the User sent funds or the service returned funds to the Exmoney address from which they were sent earlier, he must inform technical support about the transaction as a result of which the funds were sent and his address for the return of funds. Technical specialists have the right to demand proof that this transaction was sent on behalf of the User, if this fact is not obvious. Sending a refund to the User's address is carried out within fourteen (14) days after providing all evidence that the transaction was sent or initiated by the User.",
		terms_4_6: "Sending funds below the limit",
		terms_4_6_1: "If the User has sent a transaction, the amount of which is less than the amount of the Order and less than the minimum limit, a form will be available on the Order page for issuing an automatic refund to the User. Exmoney reserves the right to refuse a refund.",
		terms_4_7: "Sending funds above the limit",
		terms_4_7_1: "The service does not automatically process transactions sent through third-party smart contracts. When sending such a transaction, the User must inform technical support about it. The transaction will be attached to the order within seven (7) days after the registration of the User's request.",
		terms_4_8: "Sending funds to a blockchain network other than the one specified in the Order",
		terms_4_8_1: "If for the Order the currency was sent to the address of the Service in a network different from the one that was selected when creating the Order, and this network is supported by the Service, then the request will be processed within seven (7) days after the registration of the request. The user can choose to continue the order at the rate at the time of attaching the transaction, or make a refund.",
		terms_4_8_2: "If for the Order the currency was sent to the address of the Service in a network different from the one that was selected when creating the Order, and this network is NOT supported by the Service, then the request will be processed within thirty (30) days after the registration of the request. The User can only request a refund if the amount sent is equivalent to more than twenty (20) USD. The commission for the restoration of funds and their return depends on the complexity of the work to restore them and is set individually for each case, but cannot be less than ten (10) USD. Exmoney has the right to refuse to refund these funds without giving reasons.",
		terms_4_9: "Sending funds in a currency other than that specified in the Order",
		terms_4_9_1: "If a currency other than that specified in the Order is sent to the address of the Service in the same network as the selected currency in the Order, and this currency is also supported by the Service, then the request will be processed within seven (7) days after the request is registered. The user can choose whether to continue the order at the rate at the time of attaching the transaction or to make a refund.",
		terms_4_9_2: "If a currency other than that specified in the Order is sent to the Service's address in a network other than the one selected when creating the Order, and this currency and network is supported by the Service, then the request will be processed within seven (7) days after the request was registered. The user can choose whether to continue the order at the rate at the time of attaching the transaction or to make a refund.",
		terms_4_9_3: "If a currency other than that specified in the Order is sent to the Service's address in the same network that was selected when creating the Order, but this currency is NOT supported by the Service, then the request will be processed within thirty (30) days after the request was registered. The user can only request a refund if the amount sent is equivalent to more than twenty (20) USD. To return funds, the User is obliged to pay a commission for sending a return (miner fee), as well as a service commission in the amount of ten (10) USD for making a return of sent funds.",
		terms_4_9_4: "If a currency other than that specified in the Order is sent to the address of the Service in a network different from the one that was selected when creating the Order, and this currency is NOT supported by the Service, but the network is supported by the Service, then the request will be processed within thirty (30) days after the registration of the request. The user can only request a refund if the amount sent is equivalent to more than twenty (20) USD. To return funds, the User is obliged to pay a commission for sending a return (miner fee), as well as a service commission in the amount of ten (10) USD for making a return of sent funds.",
		terms_4_9_5: "If a currency other than that specified in the Order is sent to the Service's address in a network other than the one selected when creating the Order, and the network is NOT supported by the Service, then the request will be processed within thirty (30) days after the request was registered. The user can only request a refund if the amount sent is equivalent to more than twenty (20) USD. The commission for the restoration of funds and their return depends on the complexity of the work to restore them and is set individually for each case, but cannot be less than ten (10) USD. Exmoney has the right to refuse to refund these funds without giving reasons.",
		terms_4_10: "Sending funds for an Order as a transaction with incorrect parameters",
		terms_4_10_1: "Sending funds for an Order as a transaction with incorrect parameters. If the Order specifies the need to specify additional parameters when sending a transaction (such as MEMO, Destination tag, Comment and others), then sending a transaction on the Order without the specified parameters or with incorrect parameters may lead to an irretrievable loss of the User's funds. If the transaction was sent without specifying the required parameter, the User must report this to the technical support service, as well as provide evidence that this transaction was sent directly or initiated by the User. The evidence is a letter received to our mail from the official mail of the Service from which the transaction was sent. The email must contain confirmation that this particular User initiated the transaction. The commission for the restoration of funds and their return is 10% of the amount, but cannot be less than ten (10) US dollars. The term for consideration of the request is 14 days after the provision of all evidence. If the User and/or the Service from which the transaction was sent does not provide proof within 2 weeks, We will refund the funds to the address from which the transaction was received, without additional parameters (such as MEMO, Destination tag, Comment and others). Exmoney has the right to refuse to return these funds without giving reasons. The user can only request a refund if the amount sent is equivalent to more than ten (10) US dollars.",
		terms_4_11: "The term for processing an Order in violation of the Exchange Rules",
		terms_4_11_1: "In case of high workload, We reserve the right to increase the processing time for Orders in violation of the Exchange Rules.",
		terms_4_11_2: "In case of repeated violation of the Exchange Rules, We reserve the right to increase the processing time of the Order up to 2 months, as well as to refuse the User further service.",
		terms_title_5: "Prohibited Jurisdictions",
		terms_5_1: "Exmoney reserves the right to restrict or deny its services in certain countries.",
		terms_5_2: "The use of Service is prohibited to citizens and residents of the United States of America, as well as individuals and entities located in countries on the United Nations Sanctions List. Additionally, any individual or entity that is located in these countries, regardless of their nationality or citizenship, is prohibited from using this service. Any attempt to use this service from a prohibited location is strictly prohibited.",
		terms_5_3: "Use of the Service is prohibited in countries where usage of cryptocurrency or usage of Service is forbidden by applicable law.",
		terms_5_4: "Exmoney reserves the right to use various methods to prevent the use of the Service by Users from prohibited jurisdictions. You are to comply this rule even if the methods to prevent the use of the Service from prohibited jurisdictions are ineffective or can be bypassed.",
		terms_title_6: "Prohibited Uses",
		terms_6_1: "In addition to other prohibitions as set forth in these Terms, you are prohibited from using the Website or its content:",
		terms_6_1_1: "for any unlawful purpose;",
		terms_6_1_2: "to solicit others to perform or participate in any unlawful acts;",
		terms_6_1_3: "to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances;",
		terms_6_1_4: "to infringe upon or violate our intellectual property rights or the intellectual property rights of others;",
		terms_6_1_5: "to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability;",
		terms_6_1_6: "to submit false or misleading information;",
		terms_6_1_7: "to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related Website, other Websites, or the Internet;",
		terms_6_1_8: "to collect or track the personal information of others;",
		terms_6_1_9: "for sending spam, phishing, using parsers to collect data from the site;",
		terms_6_1_10: "for any obscene or immoral purpose;",
		terms_6_1_11: "to interfere with or circumvent the security features of the Service or any related Website, other Websites, or the Internet.",
		terms_6_2: "It is forbidden to use the service for laundering funds obtained by criminal means. If criminal funds are sent to our addresses, the funds may be frozen until information about the source and legality of the funds is received. The necessary information that must be provided by the User when freezing funds that are clearly related to criminal activity is described in section 7.",
		terms_6_3: "It is prohibited to send funds directly related to addresses or contracts that have been sanctioned by any country to the addresses of our service. The User is obliged to ensure that any funds sent to our addresses are not linked to addresses or contracts under sanctions. In case of discovery of such funds, we reserve the right to suspend the provision of services and freeze the funds received. Exmoney independently determines which sanctions regimes to follow by analyzing and monitoring the legislative requirements established by the following jurisdictions: the United Nations, the EU, USA.",
		terms_6_4: "We reserve the right to terminate your use of the Service or any related Website for violating any of the prohibited uses.",
		terms_title_7: "AML Procedure",
		terms_7_1: "To make an Exchange in the Service, the User does not have to register or log in to the Service system and provide personal data. However, when the User sends funds that are clearly related to criminal activity, the User's Order may be suspended in order to request details about the origin of the funds sent until the requested information is provided.",
		terms_7_2: "Exmoney may determine a direct link to criminal activity based on information from business partners, public sources, victim complaints against Exmoney, and law enforcement requests.",
		terms_7_3: "Exmoney reserves the right not to disclose the source of information about the connection of funds sent by the User with criminal activity.",
		terms_7_4: "By accepting these Terms, you acknowledge and agree that the transaction you sent can be verified using the security system of the Service.",
		terms_7_5: "By accepting these Terms, you acknowledge and agree to provide full details of the origin of the funds sent by you and confirm the honesty and legality of the receipt of these funds upon suspension of the Order and the corresponding request by Exmoney.",
		terms_7_6: "By accepting these Terms, you agree and warrant that true, accurate, current and complete information about the origin of funds sent is provided and you take full responsibility for its accuracy, completeness and reliability.",
		terms_7_7: "The data must be provided directly by the sender of funds under the Order.",
		terms_7_8: "If you refuse to provide data about the origin of the funds sent or provide false data, and if the data you provide confirms your connection to criminal activity, Exmoney has the right to freeze the funds for the subsequent return of funds to the victims with the assistance of law enforcement agencies.",
		terms_7_9: "In some cases, when for objective reasons the User cannot provide sufficient evidence of the source of the funds received, as well as in the case of a personal acquaintance of the sender with the alleged criminal who sent the funds to the User, as an exception, the User may be asked to undergo identity verification.",
		terms_title_8: "Personal Data",
		terms_8_1: "By accepting these Terms, you expressly allow Exmoney to process your personal data, export your personal data outside of the jurisdiction in which you reside or are located.",
		terms_8_2: "By accepting these Terms, you expressly allow Exmoney to process and store your personal data.",
		terma_8_3: "We are entitled to transfer some User’s data (including IP-addresses) to our business partners or governmental bodies at their request to facilitate the prevention and disclosure of prohibited or illegal actions. By accepting these Terms, you acknowledge and agree to your personal data may be transferred in this way.",
		terma_8_4: "By accepting these Terms, you authorize the transfer of your personal data to our business partners and government authorities at their request in order to prevent and disclose prohibited or illegal activities.",
		terma_8_5: "Certain personal information provided to us is protected according to the Privacy Policy. By accepting these Terms, you agree to the terms of the Privacy Policy.",
		terms_title_9: "Accuracy, Completeness and Timeliness of Information",
		terms_9_1: "We are not responsible if information made available on this site is not accurate, complete or current. The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the material on this site is at your own risk.",
		terms_9_2: "This site may contain certain historical information. Historical information, necessarily, is not current and is provided for your reference only. We reserve the right to modify the contents of this site at any time, but we have no obligation to update any information on our site. You agree that it is your responsibility to monitor changes to our site.",
		terms_title_10: "User Comments, Feedback and Other Submissions",
		terms_10_1: "If, at our request, you send certain specific submissions (for example contest entries) or without a request from us you send creative ideas, suggestions, proposals, plans, or other materials, whether online, by email, by postal mail, or otherwise (collectively, 'comments'), you agree that we may, at any time, without restriction, edit, copy, publish, distribute, translate and otherwise use in any medium any comments that you forward to us. We are and shall be under no obligation (1) to maintain any comments in confidence; (2) to pay compensation for any comments; or (3) to respond to any comments.",
		terms_title_11: "Disclaimer Of Warranties",
		terms_11_1: "We do not guarantee, represent or warrant that your use of our service will be uninterrupted, timely, secure or error-free.",
		terms_11_2: "We do not warrant that the results that may be obtained from the use of the service will be accurate or reliable.",
		terms_11_3: "You agree that from time to time we may remove the service for indefinite periods of time or cancel the service at any time, without notice to you.",
		terms_11_4: "You expressly agree that your use of, or inability to use, the service is at your sole risk. The service and all products and services delivered to you through the service are (except as expressly stated by us) provided 'as is' and 'as available' for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement.",
		terms_11_5: "We do not gurantee continuous, uninterrupted or secure access to our services, and operation of the Website may be interfered with by numerous factors outside of our control.",
		terms_title_12: "Limitation of Liability",
		terms_12_1: "If applicable law does not allow the limitation of liability to apply to you, the limitations and exclusions of liability in this section will not apply to you only to the extent permitted by applicable laws. You understand and agree that it is your obligation to ensure compliance with any legislation relevant to your country of domicile concerning use of the Website, and that you should not accept any liability for any illegal or unathorized use of the website. You agree to be solely responsible for any applicable taxes imposed under your  tax residency regulations.",
		terms_12_2: "In no event shall we, our directors, members, employees or agents be liable for any loss resulting from hacking, tampering, virus transmission or other unauthorized access or use of the services, your account, or any information contained therein; for products not being available for use; for improper functionality, technical faults and downtime of the technical infrastructure.",
		terms_12_3: "You hereby agree to indemnify the Exmoney, any of its officers, directors, employees and agents and its affiliated and related entities from and against any claims, costs, losses, liabilities, damages, expenses and judgments of any and every kind arising out of, relating to, or incurred in connection with any claim, complaint, audit, inquiry, or other proceeding, that arises or relates to:",
		terms_12_3_1: "any actual or alleged breach of your representations, warranties, or obligations set forth in these Terms;",
		terms_12_3_2: "your wrongful or improper use of the Website;",
		terms_12_3_4: "any other party’s access or use of the services with your data;",
		terms_12_3_5: "arising out of a breach of any warranty, representation, or obligation hereunder.",
		terms_12_4: "You shall not have any claim of any nature whatsoever against us for any failure to carry out any of our obligations under these Terms as a result of a force majeure - causes beyond our control, including but not limited to any strike, lockout, labor shortage or means, in obtaining any permission, consent or approval required by us in order to provide the Services, riot, political or civil disturbances, the elements, by an act of state or government including regulatory action imposed or any other authority or any other cause whatsoever beyond our absolute and direct control.",
		terms_12_5: "Exmoney is not responsible for phishing sites and chatbots using the name and/or design of the Service.",
		terms_title_13: "Entire Agreement",
		terms_13_1: "The failure of us to exercise or enforce any right or provision of these Terms shall not constitute a waiver of such right or provision.",
		terms_13_2: "These Terms and any policies or operating rules posted by us on the Website or in respect of the Service constitutes the entire agreement and understanding between you and us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of these Terms).",
		terms_13_3: "Any ambiguities in the interpretation of these Terms shall not be construed against the drafting party.",
		terms_title_14: "Tax",
		terms_14_1: "The taxation of digital assets has been limited to the most jurisdictions. You may be subject to certain tax implications when buying or selling digital assets.",
		terms_14_2: "You agree that Exmoney is not responsible for determining whether taxes apply to your Exchanges or for collecting, reporting, withholding or remitting any taxes arising from any transactions.",
		terms_14_3: "You agree that Exmoney is not responsible for notifying Users of the need to file tax reports and pay taxes as a result of any transactions, if such are provided in Your jurisdiction.",
		terms_14_4: "The User himself is responsible for compliance with the laws of the country in which he is located.",
		terms_title_15: "Changes to Terms of Service",
		terms_15_1: "You can review the most current version of the Terms at any time at this page.",
		terms_15_2: "We reserve the right, at our sole discretion, to update, change or replace any part of these Terms by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms constitutes acceptance of those changes.",
		terms_title_16: "Risk",
		terms_16_1: "You acknowledge and agree that you are aware of the risks associated with transactions with digital currencies and their derivatives.",
		terms_16_2: "You acknowledge and agree that your use of the Service is at your own risk.",
		terms_16_3: "You assume all risks associated with transactions with digital currencies and their derivatives. Exmoney is not responsible for any such risks or adverse results.",
		terms_title_17: "Intellectual Property Rights",
		terms_17_1: 'The Exmoney exclusively owns all rights, title, and interest in the "Exmoney" name, "Exmoney" logo, the names of individual services and their logos, patents, copyrights (including rights in derivative works), trademarks or service marks, logos and designs, trade secrets, and other intellectual property embodied by, or contained on the Website and relating webpages, documentation etc.',
		terms_17_2: "You shall never use any Exmoney logos, marks or other intellectual property for commercial and public use without our express permission, unless otherwise explicitly indicated by the Exmoney.",
		terms_17_3: "You shall not copy, reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, transmit or otherwise use any of the Exmoney logos, marks or intellectual property.",
		terms_17_4: "You also shall not make any public statement or issue any press release related to us or our services without our express permission.",
		terms_17_5: "Under these Terms you are entitled to use any logos or marks for your personal, non-commercial purposes exclusively.",
		terms_title_18: "Restricted Activities",
		terms_18_1: "In connection with your use of the Service, or in the course of your interactions with the Exmoney, you shall not:",
		terms_18_1_1: "breach these Terms or any other agreement between you and the Exmoney;",
		terms_18_1_2: "violate any law, statute, ordinance, or regulation;",
		terms_18_1_3: "infringe the Exmoney or any third party's copyright, patent, trademark, exchange secret or other intellectual property rights, or rights of publicity or privacy;",
		terms_18_1_4: "act in a manner that is defamatory, trade libellous, threatening or harassing to our employees, agents or other users;",
		terms_18_1_5: "provide false, inaccurate or misleading Information;",
		terms_18_1_6: "engage in potentially fraudulent or suspicious activity and/or transactions.",
		terms_title_19: "Disputes Resolution",
		terms_19_1: "All disputes and disagreements that might arise from these Terms shall be resolved by means of negotiations.",
		terms_19_2: "You agree that for the purposes of the settlement of disputes between you and us, an e-mail correspondence with the authorized persons of the Exmoney at: exmoney@gmail.com shall be the effective and binding method of communication.",
		terms_19_3: "If the parties cannot agree on the subject of the dispute within thirty (30) days, the dispute shall be shall be referred to and finally resolved by the relevant court.",
		terms_19_4: "To the extent allowed by the applicable law, you agree that you will bring any claim arising from or connected with these Terms of use within one (1) year from the date of which such claim arose. Otherwise such claims will be irrevocably waived.",

		// faq List
		faq_subtitle: "Feel free to contact us any time. we will get back to you as soon as we can!",
		faq_can_not_find_answer_1: "Didn’t find the answer?",
		faq_can_not_find_answer_2: "You can ask your question here",
		faq_btn_title_1: "About Exmoney",
		faq_question_list_title_1_1: "What is Exmoney?",
		faq_question_list_content_1_1: "Exmoney is a fully automated service for exchanging cryptocurrencies and tokens on favorable terms. FixedFloat is not custodial. The exchange takes place immediately after receiving the coins and the required number of network confirmations.",
		faq_question_list_title_1_2: "How does Exmoney work?",
		faq_question_list_content_1_2: "Exmoney was launched in 2025 by a team of blockchain specialists with extensive experience in developing rich web applications. In an effort to provide a convenient platform for cryptocurrency exchanges, FixedFloat is constantly actively developing and improving for its customers.",
		faq_question_list_title_1_3: "How can I trust Exmoney?",
		faq_question_list_content_1_3: "Exmoney is a fully automated service for exchanging cryptocurrencies and tokens on favorable terms. FixedFloat is not custodial. The exchange takes place immediately after receiving the coins and the required number of network confirmations.",
		faq_question_list_title_1_4: "How can I trust Exmoney?",
		faq_question_list_content_1_4: "Exmoney is a fully automated service for exchanging cryptocurrencies and tokens on favorable terms. FixedFloat is not custodial. The exchange takes place immediately after receiving the coins and the required number of network confirmations.",
		faq_btn_title_2: "About Exchange",
		faq_question_list_title_2_1: "How does the exchange work?",
		faq_question_list_content_2_1: "To make an exchange, you need to follow these steps:",
		faq_question_list_content_2_1_1: "1. Select the currency you want to exchange in the left column.",
		faq_question_list_content_2_1_2: "2. Select the currency you want to receive in the right column.",
		faq_question_list_content_2_1_3: "3. Enter the amount you want to exchange.",
		faq_question_list_content_2_1_4: "4. Click the “Exchange” button.",
		faq_question_list_content_2_1_5: "5. Enter the recipient's address.",
		faq_question_list_content_2_1_6: "6. Click the “Next” button.",
		faq_question_list_content_2_1_7: "7. Check the data and click the “Confirm” button.",
		faq_question_list_content_2_1_8: "8. Send the specified amount to the address provided.",
		faq_question_list_content_2_1_9: "9. Wait for the exchange to complete.",
		faq_question_list_title_2_2: "How long does the exchange take?",
		faq_question_list_content_2_2: "The exchange usually takes 5-30 minutes. The exchange time depends on the network load and the number of confirmations required.",
		faq_question_list_title_2_3: "What is the exchange rate?",
		faq_question_list_content_2_3: "The exchange rate is fixed at the time of the exchange. The rate may vary depending on the market situation.",
		faq_question_list_title_2_4: "What is the commission for the exchange?",
		faq_question_list_content_2_4: "The commission is fixed and is already included in the exchange rate.",

		//Profile:
		// KYC Verification
		verify_kyc_title: "KYC Verification",
		verify_kyc_instructions: "Instructions!",
		verify_kyc_instructions_1: "Photo of one of the identity documents: Passport, ID Card or driver license.",
		verify_kyc_instructions_2: "A photo (selfie) of a person with an identity document.",

		//2FA
		verify_2fa_title: "2FA Authenticate",
		verify_2fa_instructions: "Instructions!",
		verify_2fa_authenticator_1_1: "Download",
		verify_2fa_authenticator_1_2: "on your mobile.",
		verify_2fa_authenticator_2: "Scan the QR-code or enter secret-key in the form.",
		verify_2fa_authenticator_3: "Save the secret key to restore access to two-factor authentication.",
		verify_2fa_authenticator_4: "Сonfirm the addition of two-factor authentication by entering a one-time password.",
		verify_2fa_already_connected_1: "2FA is already connected",
		verify_2fa_already_connected_2: "To reset, write to the administrator @ Email @",
		verify_2fa_btn_close: "Close",
		verify_2fa_btn_add: "Add",
		verify_2fa_btn_approve: "Approve",
		verify_2fa_btn_copy: "Copy",
		verify_2fa_error: "invalid OTP",

		//User Dashboard
		dashboard_profile: "Profile",
		dashboard_settings: "Settings",
		dashboard_logout: "Logout",

		//Referals
		referrals_title: "Referrals",
		referrals_text_1: "Invite your friends by promocode",
		referrals_text_2: "Share the referral code",
		referrals_text_3: "You can also share your referral link by copying and sending or sharing it on your social media",
		referrals_btn_copy: "Copy code",
		referrals_counter: "Invitations counter",
		referrals_invited: "Invited",

		//Transactions Dashboard
		transactions_title: "Resent transactions",

		//Super Admin
		super_admin_title: "Super Admin",
		super_admin_data_loading: "Data is loading. Wait please.",

		//Statistics
		statistics_title: "Statistics",
		statistics_exchange_number: "A total of exchanges were registered:",
		statistics_deposites: "Total deposits were accepted:",
		statistics_total_spend: "Total funds were withdrawn:",
		statistics_data_missing: "Data could not be loaded / No entries in the database!",
		statistics_ps: "Additional data as needed",

		//Partnership
		partnership_title: "Partnership program",
		partnership_subtitle: "Exmoney invites owners and administrators of web-sites toc join our partnership program. You will need to:",
		partnership_register_1: "Register as a user on the website",
		partnership_register_2: "Invite your friends to follow personal partnership link available in client’s backoffice.",
		partnership_register_3: "After sharing a partnership link with your friend, each client taken to the web-site Exmoney by clicking the banner and performing transactions through our service is considered to be a client invited by you as a partner of the service. A partner receives profit in percentage from the amount of the profit of the service from each transaction performed by attracted client on web-site flashobmen.com. The interest amount which a partner receives depends on the partner’s level. The higher the level is the bigger share a partner receives. The amount of discount for personal exchanges is also calculated depending on the level of a partner. Partner’s level is defined by total amount of transactions (performed personally or by invited clients).",
		partnership_register_4: "The formula is the following: Total amount=Personal amount + (client’s amount) / 3",
		partnership_partners_level: "Partner’s level",
		partnership_total_volume: "Total volume of transactions USD",
		partnership_discount: "Personal discount (in % from Services profit)",
		partnership_profit: "Profit from customers’ exchange (in % from Service profit)",
		
		//Log history
		login_history_title: "Login history",

		//faq_dashboard
		faq_dashboard_title: "FAQ",
		faq_dashboard_no_answer_1: "Didn’t find the answer?",
		faq_dashboard_no_answer_2: "You can ask your question here",
		faq_dashboard_btn_send: "SEND",

		//Exchange pt 1
		exchange_step_1_titale: "Exchange",
		exchange_step_1_title_to: "to",
		exchange_step_1_data_input: "Data input",
		exchange_step_1_payment: "Payment",
		exchange_step_1_completion: "Completion",
		exchange_step_1_attention: "ATTENTION!",
		exchange_step_1_attention_1: "This operation takes",
		exchange_step_1_attention_2: "place automatically 24/7",
		exchange_step_1_attention_3: "and takes up to",
		exchange_step_1_attention_4: "3 minutes",
		exchange_step_1_attention_5: "after receiving",
		exchange_step_1_attention_6: "the first confirmation",
		exchange_step_1_attention_7: "of the transaction.",
		exchange_step_1_attention_8: "Course fixing rules",
		exchange_step_1_attention_9: "In order to counter the legalization of proceeds from crime and the financing of terrorism, AML checks are carried out in accordance with",
		exchange_step_1_attention_10: "AML/CTF and KYC Policies",
		exchange_step_1_attention_11: "CURRENT PRICE",
		exchange_step_1_attention_12: "I give",
		exchange_step_1_attention_13: "Amount is required!",
		exchange_step_1_attention_14: "Invalid amount format!",
		exchange_step_1_attention_15: "Specify the amount either above",
		exchange_step_1_attention_16: "or below",
		exchange_step_1_attention_17: "I get",
		exchange_step_1_attention_18: "Amount is required!",
		exchange_step_1_attention_19: "Invalid amount format!",
		exchange_step_1_attention_20: "Specify the amount either above",
		exchange_step_1_attention_21: "or below",
		exchange_step_1_address: "Address",
		exchange_step_1_wallet: "Wallet address is required!",
		exchange_step_1_wallet_invalid: "Invalid wallet address format!",
		exchange_step_1_MEMO_required: "MEMO of wallet is required!",
		exchange_step_1_last_name: "Last Name",
		exchange_step_1_last_name_required: "Last Name is required!",
		exchange_step_1_first_name: "First Name",
		exchange_step_1_first_name_required: "First Name is required!",
		exchange_step_1_phone: "Phone Number",
		exchange_step_1_phone_required: "Phone Number is required!",
		exchange_step_1_email: "Email",
		exchange_step_1_email_required: "Email is required!",
		exchange_step_1_email_invalid: "Invalid email format!",
		exchange_step_1_btn_next: "Next",
		exchange_step_1_btn_cancel: "Cancel",
		exchange_step_1_exchange_eth_title: "EXCHANGE ETHEREUM ETH TO ADVANCED CASH USD",
		exchange_step_1_exchange_eth_description_1: "To make an exchange, you should perform several steps:",
		exchange_step_1_exchange_eth_description_1_1: "Fill in all fields of the submitted form. Click Exchange.",
		exchange_step_1_exchange_eth_description_1_2: "Read the terms of the Agreement for Provision of Exchange Services; if you accept them, put a tick in the appropriate field and click Create Request.",
		exchange_step_1_exchange_eth_description_1_3: "Pay an order. Follow the instructions on our website to transfer the required amount.",
		exchange_step_1_exchange_eth_description_1_4: "On completion of specified actions, the system will move you to the Request Status page, where the status of your transfer is displayed.",
		exchange_step_1_exchange_eth_description_2_1: "The exchange rate is fixed in request if the Customer pays it within 30 minutes after creation. If payment is not received within 30 minutes, the request is automatically canceled, and to recover the request, the Customer should contact the website technical support. In this case, the amount of payment on the request may be recalculated.",
		exchange_step_1_exchange_eth_description_2_2: "If the Customer has paid the request but due to circumstances wants to refuse the exchange, the refund is minus 3% of the payment amount + commission of the payment system network and exchange rate difference (if exchange rate has changed by more than 3%).",
		exchange_step_1_values: "At the moment, the pair is not available for exchange!!",
		exchange_step_1_later: "Try later!",
		exchange_step_1_kyc_missing: "You failed KYC, here is your error:",
		exchange_step_1_error: "Error:",
		exchange_step_1_alert: "Alert!",
		exchange_step_1_alert_authorized: "You are not authorized!",
		exchange_step_1_alert_authorized_proceed: "To proceed with fiat currency exchange:",
		exchange_step_1_alert_authorized_1_1: "Log in",
		exchange_step_1_alert_authorized_1_2: "Complete KYC (Know Your Customer)",
		exchange_step_1_alert_authorized_1_3: "Verify bank card",
		exchange_step_1_alert_authorized_1_4: "You can complete KYC and track its status in your personal account.",
		exchange_step_1_alert_authorized_1_5: "Cancel",
		exchange_step_1_alert_authorized_1_6: "Log in",
		exchange_step_1_alert_kyc: "Alert!",
		exchange_step_1_alert_kyc_proceed: "You have not passed the KYC",
		exchange_step_1_alert_kyc_proceed_1_1: "To proceed with fiat currency exchange:",
		exchange_step_1_alert_kyc_proceed_1_2: "Complete KYC (Know Your Customer)",
		exchange_step_1_alert_kyc_proceed_1_3: "Verify bank card",
		exchange_step_1_alert_kyc_proceed_1_4: "You can complete KYC and track its status in your personal account.",
		exchange_step_1_alert_kyc_proceed_1_5: "Cancel",
		exchange_step_1_alert_kyc_proceed_1_6: "Pass KYC",
		exchange_step_1_alert_card: "Alert!",
		exchange_step_1_alert_card_1_1: "You didn`t submit your card for verification",
		exchange_step_1_alert_card_1_2: "To proceed with fiat currency exchange:",
		exchange_step_1_alert_card_1_3: "Verify bank card",
		exchange_step_1_alert_card_1_4: "You can complete KYC and track its status in your personal account.",
		exchange_step_1_alert_card_1_5: "Cancel",
		exchange_step_1_alert_card_1_6: "Verify card",
		exchange_step_1_alert_card_confirm: "Alert!",
		exchange_step_1_alert_card_confirm_1_1: "Your card is not confirmed!",
		exchange_step_1_alert_card_confirm_1_2: "To proceed with fiat currency exchange:",
		exchange_step_1_alert_card_confirm_1_3: "Verify bank card",
		exchange_step_1_alert_card_confirm_1_4: "You can check status in your personal account.",
		exchange_step_1_alert_card_confirm_1_5: "Cancel",
		exchange_step_1_alert_card_confirm_1_6: "Dashboard",
		exchange_step_1_sorry: "Sorry!",
		exchange_step_1_error_connecting: "Error connecting to merchant!",
		exchange_step_1_error_try_later: "Try later!",
		exchange_step_1_error_close: "Cancel",

		//Exchange pt 3
		exchange_step_3_titale: "STATUS",

		//Exchange pt 4
		exchange_step_4_titale: "Exchange completed successfully",
		exchange_step_4_data_input: "Data input",
		exchange_step_4_payment: "Payment",
		exchange_step_4_completion: "Completion",
		exchange_step_4_thx: "Thank you for using our service",
		exchange_step_4_review: "You can leave a review here",
		exchange_step_4_review_click: "click",
		exchange_step_4_discount: "For registered users there is a discount on the commission and many other goodies.",
		exchange_step_4_forms: "Forms for reviews",

		//Time Out
		time_out_title: "TimeOut! Your TX cancelled",
		time_out_data_input: "Data input",
		time_out_payment: "Payment",
		time_out_completion: "Completion",
		time_out_try_again: "Try again!",

	},
	ua: {
		// Header
		logIn: "Вхід",
		logOut: "Вихід",
		Profile: "Профіль",
		Close: "Закрити",
		Menu: "Меню",
		Main: "Головна",
		Terms: "Умови",
		News: "Новини",
		Reviews: "Відгуки",
		Contacts: "Контакти",
		// Титульна сторінка
		main_page_header:"Миттєвий обмін криптовалют",
		main_page_exchange_button:"Обмін",
		main_page_send_section_header:"НАДІСЛАТИ",
		main_page_receive_section_header:"ОТРИМАТИ",
		main_page_input_placeholder:"Введіть назву монети",
		main_page_modal_kyc_exchange:"Обмін",
		main_page_modal_kyc_alert:"Увага!",
		main_page_modal_kyc_to_proceed_condition:"Щоб продовжити обмін фіатної валюти:",
		main_page_modal_kyc_to_proceed_condition_1:"Пройти KYC (знай свого клієнта)",
		main_page_modal_kyc_to_proceed_condition_2:"Підтвердити банківську картку",
		main_page_modal_kyc_to_proceed_condition_3:"Пройти KYC та відстежувати його статус можна в особистому кабінеті.",
		main_page_modal_kyc_button_cancel:"Закрити",
		main_page_modal_kyc_button_kyc_pass:"Передати KYC",
		main_page_modal_fiat_alert:"Увага!",
		main_page_modal_fiat_to_proceed_condition:"Щоб продовжити обмін фіатної валюти:",
		main_page_modal_fiat_to_proceed_condition_1:"Пройти KYC (знай свого клієнта)",
		main_page_modal_fiat_button_cancel:"Закрити",
		main_page_modal_fiat_button_login:"Log in",
		main_page_modal_error:"Увага!",
		main_page_modal_error_try_later:"Try again later or choose other ticker!",
		main_page_modal_error_close:"Закрити",
		main_page_modal_startEx_exchange:"Обмін",
		main_page_modal_startEx_to:"на",
		main_page_modal_startEx_data:"Ввід даних",
		main_page_modal_startEx_payment:"Оплата",
		main_page_modal_startEx_status:"Статус",
		main_page_modal_startEx_completion:"Завершення",
		main_page_modal_startEx_true_exchange:"Обмін",
		main_page_modal_startEx_true_to:"на",
		main_page_modal_startEx_true_data:"Ввід даних",
		main_page_modal_startEx_true_payment:"Оплата",
		// Форма обмена
		form_header_Exchange:"Обмін",
		form_header_to:"на",
		form_header_DateInput:"Ввід даних",
		form_header_Payment:"Оплата",
		form_header_Completion:"Завершення",
		form_header_Date:"Дата",
		form_header_Copy:"Копіювати",
		form_header_RemainingTime:"Залишилось часу",
		form_body_STATUS:"СТАТУС",
		form_body_STEP:"КРОК",
		form_body_MESSAGE:"Повідомлення",
		form_footer_updated:"Сторінка оновлюється автоматично щосекунди.",
		form_rule_1_title:"Увага!",
		form_rule_1_body_1:"Ця адреса приймає лише ",
		form_rule_1_body_2:". Будь ласка, не надсилайте іншу валюту на цю адресу. Вони не будуть зараховані.",
		form_rule_2_title:"Форма для обміну криптовалют",
		form_rule_2_body:"У формі для обміну криптовалют ви можете вибрати потрібну криптовалютну пару та вказати необхідні деталі операції. Просто введіть суму криптовалюти, яку ви хочете обміняти, та отримайте відповідний обсяг іншої валюти натомість.",
		form_rule_3_title:"Поточні курси обміну",
		form_rule_3_body:"Тут ви також знайдете поточні курси обміну для різних криптовалютних пар. Наші курси оновлюються в реальному часі, щоб ви завжди могли укласти найвигіднішу для себе угоду.",
		form_rule_4_title:"Інструкції щодо процедури обміну",
		form_rule_4_body:"Щоб допомогти вам здійснити успішний обмін, ми надаємо докладні інструкції щодо процедури обміну. Ви зможете крок за кроком ознайомитися з усіма етапами операції та впевнено завершити свій обмін без проблем.",
		form_rule_5_body:"Обмінюйте свої криптовалютні активи зараз та скористайтеся нашими перевагами для отримання найкращих умов на ринку обміну криптовалют!",
		// Этапы обмена
		txt_stage_0:"Створення реквізитів",
		txt_stage_1:"Очікування депозиту",
		txt_stage_2:"Обробка платежу",
		txt_stage_3:"Розпочато обмін",
		txt_stage_4:"Завершено обмін",
		txt_stage_5:"Формування виплати",
		txt_stage_6:"Виплата",
		// kyc_dashboard
		kyc_dashboard_title_h3:"Перевірка KYC",
		kyc_dashboard_status_title:"Статус перевірки KYC:",
		kyc_dashboard_status_1:"Перевіряється!",
		kyc_dashboard_status_2:"Підтверджено!",
		kyc_dashboard_status_3:"Відмовлено!",
		kyc_dashboard_status_1_explain1:"Очікуйте підтвердження. Процес займає від 30 хв до 4 годин.",
		kyc_dashboard_status_2_explain2:"Ваші дані перевірено та підтверджено,тепер Ви можете користуватись обміном для фіатних коштів!",
		kyc_dashboard_status_3_explain3:"Нажаль, Вам відмовлено! Для деталей зв'яжіться з нами ",
		kyc_dashboard_rule_h3:"Обов'язковий для обміну на фіатні валюти!",
		kyc_dashboard_rule_h5:"Вам необхідно надати:",
		kyc_dashboard_rule_li1_1:"Фото одного з документів, що посвідчують особу:",
		kyc_dashboard_rule_li1_2:"- Паспорт (перша сторінка)",
		kyc_dashboard_rule_li1_3:"- ID-картка (передній бік)",
		kyc_dashboard_rule_li1_4:"- документ, що засвідчує особу (передня сторона)",
		kyc_dashboard_rule_li2_1:"Фото (селфі) особи з документом, що посвідчує особу.",
		kyc_dashboard_upload_1:"Завантажте фотографію одного з документів, що посвідчують особу",
		kyc_dashboard_upload_2:"Завантажте фото (селфі) з документом, що посвідчує особу",
		kyc_dashboard_selected_file:"Обрати файл",
		kyc_dashboard_required:"Обов'язкове поле",
		kyc_dashboard_agree_1:"Я погоджуюся на обробку ",
		kyc_dashboard_agree_2:"моїх персональних даних.",
		kyc_dashboard_btn_submit:"Надіслати",
		kyc_dashboard_btn_close: "Закрити",
		kyc_dashboard_btn_add:"Додати",
		// CARD_dashboard
		card_dashboard_title_h3:"Верифікація картки",
		card_dashboard_title_h3_2:"Обов'язковий для обміну на фіатні валюти!",
		card_dashboard_title_rules:"Вам необхідно надати:",
		card_dashboard_li_1_1:"Для верефикації фізичної карти потрібно:",
		card_dashboard_li_1_2:"- Ввести номер карти у поле",
		card_dashboard_li_1_3:"- Сфотографувати карту(передній бік), на карті повинно бути видно перші та останні 4 цифри(Див.приклад)",
		card_dashboard_li_2_1:"Для верефикації віртуальної карти потрібно:",
		card_dashboard_li_2_2:"- Ввести номер карти у поле",
		card_dashboard_li_2_3:"- Скріншот онлайн-банкінгу, де видно карту (Див.приклад)",
		card_dashboard_li_2_4:"- Фото з ПІБ, Датою, підписом та відкритим онлайн-банкінгом, де видно карту (Див.приклад)",
		card_dashboard_physic_number:"Номер карти",
		card_dashboard_physic_err_1_1:"Необхідна адреса гаманця!",
		card_dashboard_physic_err_1_2:"Недійсний формат адреси гаманця!",
		card_dashboard_physic_title_1:"Завантажте фотографію фізичної картки",
		card_dashboard_selected_file:"Обрати файл",
		card_dashboard_required:"Обов'язкове поле",
		card_dashboard_agree_1:"Я погоджуюся на обробку ",
		card_dashboard_agree_2:"моїх персональних даних.",
		card_dashboard_btn_submit:"Надіслати",
		card_dashboard_virtual_title_1:"Завантажте скріншот з банківського рахунку",
		card_dashboard_virtual_title_2:"Завантажте фото з ПІБ, датою, підписом та власним онлайн-банкінгом, де видно картку",
		card_dashboard_list_titles_1:"Номер карти",
		card_dashboard_list_titles_2:"Тип",
		card_dashboard_list_titles_3:"Статус",
		card_dashboard_list_titles_1_fake:"Номер карти",
		card_dashboard_list_titles_2_fake:"Статус",
		card_dashboard_list_status_1_fake:"Карта не додана",
		card_dashboard_list_status_2_fake:"Немає",
		card_dashboard_list_type_1:"Фізична",
		card_dashboard_list_type_2:"Віртуальна",
		card_dashboard_list_status_1:"Перевіряється!",
		card_dashboard_list_status_2:"Підтверджено!",
		card_dashboard_list_status_3:"Відмовлено!",
		card_dashboard_btn_close:"Закрити",
		card_dashboard_btn_add_physical_card:"Додати фізичну карту",
		card_dashboard_btn_add_virtual_card:"Додати віртуальну карту",
		card_dashboard_btn_add_list_card:"Додати карту",
		// Terms
		terms_title: "Умови",
		terms_explanations: "Що таке умови та положення?",
		terms_body_main: "На ці правила поширюються умови публічної оферти, яка набирає чинності з моменту подання заявки Замовником і є однією з основних складових цього договору. Інформація про умови подання заявки, визначені Виконавцем, є Публічною офертою. Основною частиною публічної оферти є дії, що здійснюються після завершення подання заявки Замовником, які свідчать про його точні наміри вчинити правочин на умовах, запропонованих Виконавцем до закінчення цієї заявки.",
		terms_what_are_main_rules: "Головні правила?",
		terms_list_title_1: "Ваш надійний помічник потребує наступного:",
		terms_list_body_1: "Клієнт повинен бути повнолітнім.",
		terms_rules_1: "Що таке ",
		terms_rules_2: "основні правила?",
		
		//AnswerUAH
		answer_uah_exchnge_success:"Обмін успішно завершено",
		answer_uah_data_input:"Ввід даних",
		answer_uah_payment:"Оплата",
		answer_uah_completion:"Завершення",
		answer_uah_status:"Обмін зареєстровано!",
		answer_uah_guide:"Інструкція:",
		answer_uah_guide_1:"Наш менеджер зв'яжеться з Вами в телеграмі для продовження обміну.",
		answer_uah_guide_2:"На вашу пошту надійшов <b>ПІН-КОД</b>, повідомте менеджеру в особистих повідомленнях, це потрібно для підтвердження обміну.",
		answer_uah_guide_3:"P.S. Якщо менеджер не відповів вам протягом 15 хвилин, зв'яжіться з нами напряму",

		// FromUAH
		from_uah_exchnge_title1:"Обмін",
		from_uah_exchnge_title2:"на",
		form_uah_data_input:"Ввід даних",
		form_uah_payment:"Оплата",
		form_uah_completion:"Завершення",
		form_uah_attention: "УВАГА!",
		form_uah_attention_text1: "Ця операція виконується",
		form_uah_attention_text2: "автоматично 24/7",
		form_uah_attention_text3: "і займає до",
		form_uah_attention_text4: "3 хвилин",
		form_uah_attention_text5: "від моменту проведення",
		form_uah_attention_text6: "Правила фіксації курсу",
		form_uah_attention_text7: "З метою протидії легалізації доходів, одержаних злочинним шляхом, та фінансуванню тероризму проводяться перевірки ПВК згідно з",
		form_uah_attention_text8: "AML/CTF та KYC Policies",
		form_uah_current_price: "ПОТОЧНИЙ КУРС",
		form_uah_current_price1: "Я даю",
		form_uah_current_price2: "Необхідна сума!",
		form_uah_current_price3: "Недійсний формат суми!",
		form_uah_current_price4: "Вкажіть суму вище",
		form_uah_current_price5: "або нижче",
		form_uah_current_price6: "Я отримую",
		form_uah_current_price7: "Необхідна сума!",
		form_uah_current_price8: "Недійсний формат суми!",
		form_uah_current_price9: "Вкажіть суму вище",
		form_uah_current_price10: "або нижче",
		form_uah_current_price11: "Адреса",
		form_uah_current_address_placeholder: "Адреса гаманця",
		form_uah_current_address1: "Адреса гаманця обов'язкова!",
		form_uah_current_address2: "Недійсний формат адреси гаманця!",
		form_uah_memo_placeholder: "MEMO гаманця",
		form_uah_memo_placeholder_required: "MEMO гаманця обов'язкове!",
		form_uah_phone_title: "Номер телефону",
		form_uah_phone_placeholder: "Номер телефону",
		form_uah_phone_placeholder_required: "Номер телефону обов'язковий!",
		form_uah_telegram_title: "Ім'я користувача Telegram",
		form_uah_telegram_placeholder: "@username",
		form_uah_telegram_required: "Ім'я користувача обов'язкове!",
		form_uah_email_title: "Email",
		form_uah_email_placeholder: "exmoney@gmail.com",
		form_uah_email_required: "Email обов'язковий!",
		form_uah_email_invalid: "Недійсний формат email!",
		form_uah_next: "Далі",
		form_uah_rules_title: "ОБМІН ETHEREUM ETH НА ADVANCED CASH USD",
		form_uah_rules_steps: "Для здійснення обміну вам потрібно виконати кілька кроків:",
		form_uah_rules_step_1_1: "Заповніть всі поля поданої форми. Натисніть Обмін.",
		form_uah_rules_step_1_2: "Ознайомитися з умовами Договору про надання біржових послуг; якщо ви приймаєте їх, поставте галочку у відповідному полі та натисніть Створити запит.",
		form_uah_rules_step_1_3: "Оплатити замовлення. Дотримуйтесь інструкцій на нашому веб-сайті, щоб переказати необхідну суму.",
		form_uah_rules_step_1_4: "Після завершення вказаних дій система перемістить вас на сторінку «Статус запиту», де відображається статус вашого переказу.",
		form_uah_rules_step_2: "Курс обміну фіксується в запиті, якщо клієнт сплачує його протягом 30 хвилин після створення. Якщо оплата не надійшла протягом 30 хвилин, запит автоматично скасовується, а для відновлення запиту Замовнику необхідно звернутися до служби технічної підтримки сайту. У цьому випадку сума платежу за заявкою може бути перерахована.",
		form_uah_rules_step_3: "Якщо Клієнт оплатив заявку, але через обставини бажає відмовитися від обміну, повернення коштів здійснюється за мінусом 3% від суми платежу + комісія мережі платіжної системи та курсова різниця (якщо курс змінився більш ніж на 3%).",
		form_uah_trades_enabled: "На даний момент пара не доступна для обміну!!",
		form_uah_trades_tryLater: "Спробуйте пізніше!",
		form_uah_error: "Помилка:",

		// Portfolio

		// Services

		// About studio

		// Contacts
		contacts_title: "Контакти",
		contacts_subtitle: "Звертайтеся до нас у будь-який час. ми зв'яжемося з вами, як тільки зможемо!",
		contacts_name: "Ім'я",
		contacts_name_required: "Ім'я обов'язкове",
		contacts_email: "E-mail",
		contacts_email_required: "E-mail обов'язковий",
		contacts_message: "Повідомлення",
		contacts_message_required: "Повідомлення обов'язкове",
		contacts_message_length: "Повідомлення повинно містити не менше 20 символів",
		contacts_send_btn: "Надіслати",

		// Footer
		footer_main: "Головна",
		footer_terms: "Умови",
		footer_news: "Новини",
		footer_reviews: "Відгуки",
		footer_contacts: "Контакти",
		read_more: "Читати далі",
		
		// Authentication
		auth_error_email_required: "Електронна пошта обов'язкова",
		auth_error_password_required: "Пароль обов'язковий",
		auth_error_password_min: "Пароль повинен містити не менше 8 символів",
		auth_password_forgot: "Забули пароль",
		auth_button_login: "Вхід",
		auth_button_register: "Реєстрація",

		//Registration
		reg_title: "Створити обліковий запис",
		reg_create_account_subtitle: "Створіть свій обліковий запис, це займе менше хвилини. Якщо у вас вже є обліковий запис",
		reg_login_link: "увійдіть",
		reg_first_name: "Ім'я",
		reg_first_name_alpha: "Ім'я повинно бути буквенним",
		reg_last_name: "Прізвище",
		reg_last_name_alpha: "Прізвище повинно бути буквенним",
		reg_phone: "Телефон",
		reg_phone_numeric: "Телефон повинен бути числовим",
		reg_email: "Електронна пошта",
		reg_password: "Пароль",
		reg_confirm_password: "Підтвердити пароль",
		reg_promocode: "Промокод",
		reg_button_register: "Зареєструватися",
		reg_error_first_name_required: "Ім'я обов'язкове",
		reg_error_last_name_required: "Прізвище обов'язкове",
		reg_error_phone_required: "Телефон обов'язковий",
		reg_error_email_required: "Електронна пошта обов'язкова",
		reg_error_email_valid: "Будь ласка, введіть дійсну електронну пошту",
		reg_error_password_required: "Пароль обов'язковий",
		reg_error_password_min: "Пароль повинен містити не менше 8 символів",
		reg_error_confirm_password_required: "Підтвердження пароля обов'язкове",
		reg_error_confirm_password_same: "Паролі повинні співпадати",

		//erorr page
		error_page_title_pt_1: "Вибачте, сервіс недоступний.",
		error_page_title_pt_2: "Технічні роботи",
		error_page_title_pt_3: "Всі поточні обміни будуть завершені найближчим часом, не хвилюйтесь!",
		error_page_return_btn: "НА ГОЛОВНУ",

		//Not Found Page
		not_found_page_title: "СТОРІНКА НЕ ЗНАЙДЕНА",
		not_found_page_return_btn: "НА ГОЛОВНУ",

		// Forgot password
		forgot_email_required: "Електронна пошта обов'язкова",
		forgot_email_valid: "Недійсний формат електронної пошти",
		forgot_button_send: "Надіслати",
		forgot_confirm_message: "Запит на зміну пароля успішно надіслано на вашу електронну пошту!",

		//Reset Password
		reset_password_title: "Змінити пароль",
		reset_placeholder_password: "Введіть новий пароль",
		reset_placeholder_confirm_password: "Підтвердіть новий пароль",
		reset_success_message: "Ваш пароль успішно змінено!",
		reset_button_change: "Змінити",
		reset_button_go_home: "Увійти",
		reset_button_try_again: "Спробувати ще!",
		reset_password_reset_error: "Помилка зміни пароля!",
		reset_password_reset_error_message: "Деталі:",

		// faq List
		faq_btn_title_1: "Про Exmoney",
		faq_question_list_title_1_1: "Що таке Exmoney?",
		faq_question_list_content_1_1: "Exmoney - це повністю автоматизований сервіс для обміну криптовалют та токенів на вигідних умовах. FixedFloat не є кастодіальним. Обмін відбувається негайно після отримання монет та необхідної кількості підтверджень мережі.",
		faq_question_list_title_1_2: "Як працює Exmoney?",
		faq_question_list_content_1_2: "Exmoney був запущений в 2025 році командою спеціалістів з блокчейну з великим досвідом розробки багатофункціональних веб-додатків. Намагаючись надати зручну платформу для обміну криптовалют, FixedFloat постійно активно розвивається та вдосконалюється для своїх клієнтів.",
		faq_question_list_title_1_3: "Чи можу я довіряти Exmoney?",
		faq_question_list_content_1_3: "Exmoney - це повністю автоматизований сервіс для обміну криптовалют та токенів на вигідних умовах. FixedFloat не є кастодіальним. Обмін відбувається негайно після отримання монет та необхідної кількості підтверджень мережі.",
		faq_question_list_title_1_4: "Чи можу я довіряти Exmoney?",
		faq_question_list_content_1_4: "Exmoney - це повністю автоматизований сервіс для обміну криптовалют та токенів на вигідних умовах. FixedFloat не є кастодіальним. Обмін відбувається негайно після отримання монет та необхідної кількості підтверджень мережі.",
		faq_btn_title_2: "Про обмін",
		faq_question_list_title_2_1: "Як працює обмін?",
		faq_question_list_content_2_1: "Для того, щоб здійснити обмін, вам потрібно виконати такі кроки:",
		faq_question_list_content_2_1_1: "1. У лівій колонці виберіть валюту, яку ви хочете обміняти.",
		faq_question_list_content_2_1_2: "2. У правій колонці виберіть валюту, яку хочете отримати.",
		faq_question_list_content_2_1_3: "3. Введіть суму, яку хочете обміняти.",
		faq_question_list_content_2_1_4: "4. Натисніть кнопку «Обмін».",
		faq_question_list_content_2_1_5: "5. Введіть адресу одержувача.",
		faq_question_list_content_2_1_6: "6. Натисніть кнопку «Далі».",
		faq_question_list_content_2_1_7: "7. Перевірте дані та натисніть кнопку «Підтвердити».",
		faq_question_list_content_2_1_8: "8. Надішліть вказану суму на вказану адресу.",
		faq_question_list_content_2_1_9: "9. Дочекайтеся завершення обміну.",
		faq_question_list_title_2_2: "Скільки часу займає обмін?",
		faq_question_list_content_2_2: "Обмін зазвичай займає від 5 до 30 хвилин, але може зайняти більше часу в залежності від навантаження мережі.",
		faq_question_list_title_2_3: "Який обмінний курс?",
		faq_question_list_content_2_3: "Курс обміну вказаний на сторінці обміну та оновлюється в реальному часі.",
		faq_question_list_title_2_4: "Комісія при обміні.",
		faq_question_list_content_2_4: "Комісія фіксована і вже включена в курс обміну.",

		//Profile:
		// KYC Verification
		verify_kyc_title: "KYC підтвердження",
		verify_kyc_instructions: "Інструкції!",
		verify_kyc_instructions_1: "Фото одного з документів, що посвідчують особу: паспорт, ID-картка або водійське посвідчення.",
		verify_kyc_instructions_2: "Фото (селфі) особи з документом, що посвідчує особу.",

		//2FA
		verify_2fa_title: "2FA автентифікація",
		verify_2fa_instructions: "Інструкції!",
		verify_2fa_authenticator_1_1: "Завантажте",
		verify_2fa_authenticator_1_2: "на ваш смартфон.",
		verify_2fa_authenticator_2: "Скануйте QR-код або введіть секретний ключ.",
		verify_2fa_authenticator_3: "Збережіть секретний ключ, щоб відновити доступ до двофакторної аутентифікації.",
		verify_2fa_authenticator_4: "Підтвердьте додавання двофакторної аутентифікації, ввівши одноразовий пароль.",
		verify_2fa_already_connected_1: "2FA вже підключено",
		verify_2fa_already_connected_2: "Для скидання напишіть адміністратору @ Email @",
		verify_2fa_btn_close: "Закрити",
		verify_2fa_btn_add: "Додати",
		verify_2fa_btn_approve: "Підтвердити",
		verify_2fa_btn_copy: "Копіювати",
		verify_2fa_error: "неправильний код",

		//User Dashboard
		dashboard_profile: "Профіль",
		dashboard_settings: "Налаштування",
		dashboard_logout: "Вийти",

		//Referals
		referrals_title: "Реферали",
		referrals_text_1: "Запрошуйте друзів за промокодом",
		referrals_text_2: "Поділіться реферальним кодом",
		referrals_text_3: "Ви також можете поділитися своїм реферальним посиланням, скопіювавши та надіславши його або поділившись ним у своїх соціальних мережах",
		referrals_btn_copy: "Скопіювати",
		referrals_counter: "Кількість запрошених",
		referrals_invited: "Запрошені",

		//Transactions Dashboard
		transactions_title: "Транзакції",

		//Super Admin
		super_admin_title: "Super Admin",
		super_admin_data_loading: "Data is loading. Wait please.",

		//Statistics
		statistics_title: "Статистика",
		statistics_exchange_number: "Кількість обмінів:",
		statistics_deposites: "Кількість депозитів:",
		statistics_total_spend: "Всього знято коштів:",
		statistics_data_missing: "Не вдалося завантажити дані / Немає записів у базі даних!",
		statistics_ps: "Додаткові дані за потреби",
		
		//Partnership
		partnership_title: "Партнерська програма",
		partnership_subtitle: "Exmoney запрошує власників і адміністраторів веб-сайтів приєднатися до нашої партнерської програми. Вам потрібно буде:",
		partnership_register_1: "Зареєструватися як користувач на сайті",
		partnership_register_2: "Запросіть друзів перейти за особистим партнерським посиланням, доступним у бек-офісі клієнта.",
		partnership_register_3: "Після того, як ви поділитеся партнерським посиланням із вашим другом, кожен клієнт, який переходить на веб-сайт Exmoney шляхом натискання банера та здійснює транзакції через наш сервіс, вважається клієнтом, запрошеним вами як партнером сервісу. Партнер отримує прибуток у відсотках від суми прибутку послуги від кожної транзакції, здійсненої залученим клієнтом на сайті flashobmen.com. Сума відсотків, яку отримує партнер, залежить від рівня партнера. Чим вищий рівень, тим більшу частку отримує партнер. Розмір знижки при персональних обмінах також розраховується в залежності від рівня партнера. Рівень партнера визначається загальною сумою операцій (здійснених особисто або запрошеними клієнтами).",
		partnership_register_4: "Формула така: Загальна сума = Особиста сума + (сума клієнта)",
		partnership_partners_level: "Рівень партнера",
		partnership_total_volume: "Загальний обсяг операцій дол",
		partnership_discount: "Персональна знижка (в % від прибутку Сервісу)",
		partnership_profit: "Прибуток від обміну клієнтів (у % від прибутку від послуги)",

		//Log history
		login_history_title: "Login history",

		//faq_dashboard
		faq_dashboard_title: "FAQ",
		faq_dashboard_no_answer_1: "Не знайшли відповідь?",
		faq_dashboard_no_answer_2: "Напишіть своє питання тут",
		faq_dashboard_btn_send: "Надіслати",

		//Exchange pt 1
		exchange_step_1_titale: "Обмін",
		exchange_step_1_title_to: "на",
		exchange_step_1_data_input: "Ввід даних",
		exchange_step_1_payment: "Оплата",
		exchange_step_1_completion: "Завершення",
		exchange_step_1_attention: "УВАГА!",
		exchange_step_1_attention_1: "Операція виконується",
		exchange_step_1_attention_2: "автоматично 24/7",
		exchange_step_1_attention_3: "і займає до",
		exchange_step_1_attention_4: "3 хвилин",
		exchange_step_1_attention_5: "від моменту проведення",
		exchange_step_1_attention_6: "першого підтвердження",
		exchange_step_1_attention_7: "цієї транзакції",
		exchange_step_1_attention_8: "Правила фіксації курсу",
		exchange_step_1_attention_9: "З метою протидії легалізації доходів, одержаних злочинним шляхом, та фінансуванню тероризму проводяться перевірки ПВК згідно з",
		exchange_step_1_attention_10: "AML/CTF та KYC Правила",
		exchange_step_1_attention_11: "АКТУАЛЬНА ЦІНА",
		exchange_step_1_attention_12: "Я відправляю",
		exchange_step_1_attention_13: "Сума обов'язкова!",
		exchange_step_1_attention_14: "Недійсний формат суми!",
		exchange_step_1_attention_15: "Вкажіть суму вище",
		exchange_step_1_attention_16: "або нижче",
		exchange_step_1_attention_17: "Я отримую",
		exchange_step_1_attention_18: "Сума обов'язкова!",
		exchange_step_1_attention_19: "Недійсний формат суми!",
		exchange_step_1_attention_20: "Вкажіть суму вище",
		exchange_step_1_attention_21: "або нижче",
		exchange_step_1_address: "Адреса",
		exchange_step_1_wallet: "Адреса гаманця обовʼязкова!",
		exchange_step_1_wallet_invalid: "Недійсний формат адреси гаманця!",
		exchange_step_1_MEMO_required: "MEMO гаманця обов'язкове!",
		exchange_step_1_last_name: "Прізвище",
		exchange_step_1_last_name_required: "Прізвище обов'язкове!",
		exchange_step_1_first_name: "Ім'я",
		exchange_step_1_first_name_required: "Ім'я обов'язкове!",
		exchange_step_1_phone: "Телефон",
		exchange_step_1_phone_required: "Телефон обов'язковий!",
		exchange_step_1_email: "Email",
		exchange_step_1_email_required: "Email обов'язковий!",
		exchange_step_1_email_invalid: "Недійсний формат email!",
		exchange_step_1_btn_next: "Далі",
		exchange_step_1_btn_cancel: "Закрити",
		exchange_step_1_exchange_eth_title: "ОБМІЯТИ ETHEREUM ETH НА ADVANCED CASH USD",
		exchange_step_1_exchange_eth_description_1: "Для здійснення обміну необхідно виконати кілька кроків:",
		exchange_step_1_exchange_eth_description_1_1: "Заповніть усі поля поданої форми. Натисніть Обмін.",
		exchange_step_1_exchange_eth_description_1_2: "Ознайомитися з умовами Договору про надання біржових послуг; якщо ви приймаєте їх, поставте галочку у відповідному полі та натисніть Створити запит.",
		exchange_step_1_exchange_eth_description_1_3: "Оплатити замовлення. Дотримуйтесь інструкцій на нашому веб-сайті, щоб переказати необхідну суму.",
		exchange_step_1_exchange_eth_description_1_4: "Після завершення вказаних дій система перемістить вас на сторінку «Статус запиту», де відображається статус вашого переказу.",
		exchange_step_1_exchange_eth_description_2_1: "Курс обміну фіксується в запиті, якщо клієнт сплачує його протягом 30 хвилин після створення. Якщо оплата не надійшла протягом 30 хвилин, запит автоматично скасовується, а для відновлення запиту Замовнику необхідно звернутися до служби технічної підтримки сайту. У цьому випадку сума платежу за заявкою може бути перерахована.",
		exchange_step_1_exchange_eth_description_2_2: "Якщо Клієнт оплатив заявку, але через обставини бажає відмовитися від обміну, повернення коштів здійснюється за мінусом 3% від суми платежу + комісія мережі платіжної системи та курсова різниця (якщо курс змінився більш ніж на 3%). .",
		exchange_step_1_values: "На даний момент пара не доступна для обміну!!",
		exchange_step_1_later: "Спробуйте пізніше!",
		exchange_step_1_kyc_missing: "Вам не вдалося KYC, ось ваша помилка:",
		exchange_step_1_error: "Помилка:",
		exchange_step_1_alert: "Попередження!",
		exchange_step_1_alert_authorized: "Увійти!",
		exchange_step_1_alert_authorized_proceed: "Щоб продовжити обмін фіатної валюти:",
		exchange_step_1_alert_authorized_1_1: "Увійти",
		exchange_step_1_alert_authorized_1_2: "Пройти KYC (знай свого клієнта)",
		exchange_step_1_alert_authorized_1_3: "Підтвердити банківську картку",
		exchange_step_1_alert_authorized_1_4: "Пройти KYC і відстежувати його статус можна в особистому кабінеті.",
		exchange_step_1_alert_authorized_1_5: "Скасувати",
		exchange_step_1_alert_authorized_1_6: "Пройти KYC",
		exchange_step_1_alert_kyc: "Увійти!",
		exchange_step_1_alert_kyc_proceed: "Ви не пройшли KYC",
		exchange_step_1_alert_kyc_proceed_1_1: "Щоб продовжити обмін фіатної валюти:",
		exchange_step_1_alert_kyc_proceed_1_2: "Пройти KYC (знай свого клієнта)",
		exchange_step_1_alert_kyc_proceed_1_3: "Підтвердити банківську картку",
		exchange_step_1_alert_kyc_proceed_1_4: "Пройти KYC і відстежувати його статус можна в особистому кабінеті.",
		exchange_step_1_alert_kyc_proceed_1_5: "Скасувати",
		exchange_step_1_alert_kyc_proceed_1_6: "Пройти KYC",
		exchange_step_1_alert_card: "Увага!",
		exchange_step_1_alert_card_1_1: "Ви не подали свою картку на перевірку",
		exchange_step_1_alert_card_1_2: "Щоб продовжити обмін фіатної валюти:",
		exchange_step_1_alert_card_1_3: "Підтвердити банківську картку",
		exchange_step_1_alert_card_1_4: "Пройти KYC і відстежувати його статус можна в особистому кабінеті.",
		exchange_step_1_alert_card_1_5: "Скасувати",
		exchange_step_1_alert_card_1_6: "Підтвердити картку",
		exchange_step_1_alert_card_confirm: "Увага!",
		exchange_step_1_alert_card_confirm_1_1: "Ваша картка не підтверджена!",
		exchange_step_1_alert_card_confirm_1_2: "Щоб продовжити обмін фіатної валюти:",
		exchange_step_1_alert_card_confirm_1_3: "Підтвердити банківську картку",
		exchange_step_1_alert_card_confirm_1_4: "Перевірити статус можна в особистому кабінеті.",
		exchange_step_1_alert_card_confirm_1_5: "Закрити",
		exchange_step_1_alert_card_confirm_1_6: "Dashboard",
		exchange_step_1_sorry: "Вибачте!",
		exchange_step_1_error_connecting: "Помилка підключення до продавця!",
		exchange_step_1_error_try_later: "Спробуйте пізніше!",
		exchange_step_1_error_close: "Закрити",
		
		//Exchange pt 3
		exchange_step_3_titale: "СТАТУС",
		
		//Exchange pt 4
		exchange_step_4_titale: "Обмін успішно завершено",
		exchange_step_4_data_input: "Ввід даних",
		exchange_step_4_payment: "Оплатa",
		exchange_step_4_completion: "Завершення",
		exchange_step_4_thx: "Дякуємо, що користуєтеся нашим сервісом",
		exchange_step_4_review: "Ви можете залишити відгук тут",
		exchange_step_4_review_click: "клік",
		exchange_step_4_discount: "Для зареєстрованих користувачів діє знижка на комісію та багато інших смаколиків.",
		exchange_step_4_forms: "Бланки для оглядів",
		
		//Time Out
		time_out_title: "Тайм-аут! Ваш TX скасовано",
		time_out_data_input: "Введення даних",
		time_out_payment: "Оплата",
		time_out_completion: "Завершення",
		time_out_try_again: "Спробуйте знову",

	},
};

const i18n = new VueI18n({
	locale: "en", // set locale
	fallbackLocale: "en", // set fallback locale
	messages, // set locale messages
});

export default i18n;
